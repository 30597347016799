import React, { Fragment, useEffect } from "react";
import "../../assets/styles/allPlanList.scss";
import Loader from "../../components/Loader";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import usePaginate from "../../assets/hooks/usePaginate";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCreateUpdateState,
  clearErrors,
  deletePlan,
  getPlanList,
} from "../../redux/action/planAction";
import { toast } from "react-toastify";
import CustomButton from "../../components/CustomButton";
import { PiPlantBold } from "react-icons/pi";
import { MdDelete } from "react-icons/md";
import ButtonLoader from "../../components/ButtonLoader";
import NotFoundCard from "./components/NotFoundCard";

const AllPlanList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // GET PLAN LIST START
  // GET PLAN LIST START
  const { loading, error, plans } = useSelector((state) => state.getPlanList);

  const allPlans = plans?.packages;

  useEffect(() => {
    dispatch(getPlanList());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [error, dispatch]);
  // GET PLAN LIST ENDS
  // GET PLAN LIST ENDS

  //   DELETE PLAN START
  //   DELETE PLAN START
  const {
    loading: deletedPlanLoading,
    plan: deletedPlan,
    error: deletedPlanError,
  } = useSelector((state) => state.deletePlan);

  // DELETE PACKAGE HANDELER
  const handleDeletePlan = (id) => {
    dispatch(deletePlan(id));
  };

  useEffect(() => {
    if (deletedPlanError) {
      toast.error(deletedPlanError);
      dispatch(clearErrors());
    }

    if (deletedPlan?.success) {
      toast.success(deletedPlan?.message);
      setTimeout(() => {
        dispatch(clearCreateUpdateState());
        dispatch(getPlanList()); // GET UPDATED PLAN LIST
      }, 1000);
    }
  }, [deletedPlanError, dispatch, deletedPlan?.success, deletedPlan?.message]);
  //   DELETE PLAN ENDS
  //   DELETE PLAN ENDS

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(allPlans);
  // Pagination Hook Ends

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="allPlans_wrapper">
            <div className="container">
              <div className="buttonFunction">
                <PageTitle goBack={true} title={`All Plans`} />
                <CustomButton
                  title="Create Plan"
                  onClick={() => navigate("/admin/create-plan")}
                  preIcon={<PiPlantBold />}
                  tooltip="Create Plan"
                />
              </div>

              {allPlans?.length > 0 ? (
                <div className="allPlans_content">
                  <div className="table_data custonScroll">
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Thumbnail</th>
                          <th>Title</th>
                          <th>Price</th>
                          <th>Discount</th>
                          <th>Status</th>
                          <th>Created By</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.reverse()?.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <img
                                  src={item?.thumbnail?.url}
                                  alt={item?.title}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "contain",
                                  }}
                                />
                              </td>
                              <td>
                                <p>{item?.title}</p>
                              </td>
                              <td>
                                <p>₹ {item?.price}</p>
                              </td>
                              <td>
                                <p>{item?.discount} %</p>
                              </td>
                              <td
                                style={{
                                  background: item?.isActive ? "green" : "red",
                                }}
                              >
                                <p>
                                  <b>
                                    {item?.isActive ? "Active" : "In-Active"}
                                  </b>
                                </p>
                              </td>
                              <td>
                                <p>{item?.createdBy?.name}</p>
                              </td>
                              <td>
                                <div>
                                  <button
                                    title="View/Edit Plan"
                                    onClick={() =>
                                      navigate(`/admin/plan/${item?._id}`)
                                    }
                                  >
                                    <FaEye />
                                  </button>
                                  <button
                                    title="Delete Plan"
                                    onClick={() => handleDeletePlan(item?._id)}
                                  >
                                    {deletedPlanLoading ? (
                                      <ButtonLoader />
                                    ) : (
                                      <MdDelete />
                                    )}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination_sec">
                    <p className="entriesCount">
                      Showing {itemOffset + 1} to{" "}
                      {endOffset > allPlans?.length
                        ? allPlans?.length
                        : endOffset}{" "}
                      of {allPlans?.length} entries
                    </p>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<RxDoubleArrowRight />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<RxDoubleArrowLeft />}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              ) : (
                <NotFoundCard
                  text="Plan list not found!"
                  showAction={true}
                  actionTitle="Create New Plan"
                  onClick={() => navigate("/admin/create-plan")}
                  actionIcon={<PiPlantBold />}
                />
              )}
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default AllPlanList;
