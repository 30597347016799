import React from "react";
import styled from "styled-components";

const MyInput = styled.div`
  margin-bottom: 25px;
  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
  > label {
    display: block;
    width: 100%;
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #394b71;
    @media screen and (max-width: 575px) {
      font-size: 15px;
    }
  }
  .input_icon {
    position: relative;
    > .icon {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 575px) {
        height: 40px;
        width: 40px;
        font-size: 13px;
      }
      > svg {
        color: #dcca87;
        z-index: 1;
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }
  }
  .input_text {
    display: block;
    width: 100%;
    backdrop-filter: blur(21px);
    border-radius: 4px;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 10px;
    // color: #a9b5bb;
    color: #f2f2f2;
    font-size: 16px;
    background: rgb(57, 75, 113);
    background: linear-gradient(
      257deg,
      rgba(57, 75, 113, 0.4151785714285714) 0%,
      rgba(57, 75, 113, 0.801733193277311) 35%,
      rgba(57, 75, 113, 1) 100%
    );

    @media screen and (max-width: 575px) {
      font-size: 13px;
    }
  }
`;

const CustomTextarea = ({
  icon,
  label,
  rows = "5",
  type,
  onChange,
  placeholder,
  name,
  fullWidth,
  borderColor,
  readOnly,
  value,
}) => {
  return (
    <MyInput className="myInput" style={{ width: fullWidth ? "100%" : "" }}>
      {label && <label>{label}</label>}
      <div className="input_icon">
        {icon && <span className="icon">{icon}</span>}
        <textarea
          className="input_text"
          rows={rows}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          value={value}
          readOnly={readOnly}
          style={{
            paddingLeft: icon && "50px",
            border: borderColor && `1px solid ${borderColor}`,
          }}
        />
      </div>
    </MyInput>
  );
};

export default CustomTextarea;
