import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/activateId.scss";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import { BsCurrencyRupee } from "react-icons/bs";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  clearWalletErrors,
  clearWalletMessage,
  getMyWallet,
} from "../../redux/action/walletAction";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import CustomMultiSelect from "../../components/CustomMultiSelect";
import {
  clearCreateUpdateState,
  clearErrors,
  getPlanList,
  purchasePlan,
} from "../../redux/action/planAction";
import ButtonLoader from "../../components/ButtonLoader";
import { useNavigate } from "react-router-dom";
import NotFoundCard from "../admin/components/NotFoundCard";
import { MdDashboard } from "react-icons/md";
import { userProfile } from "../../redux/action/userAction";

const ActivateId = () => {
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState([]);

  const navigate = useNavigate();
  // USER DETAILS START
  const { userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;
  // USER DETAILS ENDS

  // GET MY WALLET START
  // GET MY WALLET START
  const {
    loading,
    wallet,
    message: walletMessage,
    error,
  } = useSelector((state) => state.myWallet);

  const myWallet = wallet?.wallet;


  useEffect(() => {
    dispatch(getMyWallet());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearWalletErrors());
    }

    if (wallet?.success && walletMessage) {
      toast.success(walletMessage);
      dispatch(clearWalletMessage());
    }
  }, [error, dispatch, wallet?.success, walletMessage]);

  // GET MY WALLET ENDS
  // GET MY WALLET ENDS

  // GET PLAN LIST START
  // GET PLAN LIST START
  const {
    loading: plansLoading,
    error: plansError,
    plans,
  } = useSelector((state) => state.getPlanList);

  const allPlans = plans?.packages;

  // PLAN OPTIONS FOR SELECTION START
  let planOptions = [
    { value: "", label: "Please select a plan", disabled: true },
  ];
  allPlans?.forEach((item) => {
    planOptions.push({
      value: item?._id,
      label: `${item?.title} - price ₹${
        item?.discountPrice > 0 ? item?.discountPrice : item?.price
      }`,
      amount:
        item?.discountPrice > 0
          ? Number(item?.discountPrice)
          : Number(item?.price),
    });
  });
  // PLAN OPTIONS FOR SELECTION ENDS

  useEffect(() => {
    dispatch(getPlanList());
  }, [dispatch]);

  useEffect(() => {
    if (plansError) {
      toast.error(plansError);
      dispatch(clearErrors());
    }
  }, [plansError, dispatch]);
  // GET PLAN LIST ENDS
  // GET PLAN LIST ENDS

  // PURCHASE PLAN START
  // PURCHASE PLAN START
  const {
    loading: purchasePlanLoaing,
    plan: buyPlan,
    error: purchasePlanError,
  } = useSelector((state) => state.purchasePlan);

  // purchase plan handler
  const handlePurchasePlan = () => {
    if (!selectedPlan?.value || !selectedPlan?.amount) {
      toast.warning("Kindly select a plan!");
    } else if (selectedPlan?.amount > myWallet?.walletBalance) {
      toast.warning("Wallet not created or Wallet balance is low!");
      navigate("/user-dashboard/add-fund")
    } else {
      dispatch(purchasePlan(selectedPlan?.value, Number(selectedPlan?.amount)));
    }
  };

  useEffect(() => {
    if (purchasePlanError) {
      toast.error(purchasePlanError);
      dispatch(clearErrors());
    }
    if (buyPlan?.success) {
      toast.success(buyPlan?.message);
      dispatch(clearCreateUpdateState());
      dispatch(userProfile());
    }
  }, [purchasePlanError, dispatch, buyPlan?.success, buyPlan?.message]);
  // PURCHASE PLAN ENDS
  // PURCHASE PLAN ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <DashLayout>
          {user?.paidUser ? (
            <NotFoundCard
              text="You already purchased a plan & your account is activated."
              showAction={true}
              actionTitle="Dashboard"
              onClick={() => navigate("/user-dashboard")}
              actionIcon={<MdDashboard />}
            />
          ) : (
            <div className="activateId_wrapper">
              <DashCard className="activateId" title={"Activate/Upgrade My ID"}>
                <CustomInput
                  label="Activation Wallet Balance"
                  type="number"
                  //   onChange=""
                  icon={<BsCurrencyRupee />}
                  placeholder="Wallet Balance"
                  name="walletBalance"
                  value={myWallet?.walletBalance}
                  impStar=""
                  readOnly={true}
                  borderColor=""
                  bacgroundColor={"grey"}
                />

                <CustomMultiSelect
                  label="Select Package"
                  impStar="*"
                  value={selectedPlan}
                  onChange={(val) => setSelectedPlan(val)}
                  isMulti={false}
                  optionArray={
                    plansLoading
                      ? [{ label: "Loading...", value: "" }]
                      : planOptions
                  }
                  isOptionDisabled={(planOptions) => planOptions.disabled}
                  disabled=""
                  borderColor="#f2f2f2"
                />

                <CustomButton
                  className={`purchagePack_btn 
                  ${
                    !selectedPlan?.amount || !selectedPlan?.value
                      ? "disabled"
                      : ""
                  }
                `}
                  title={purchasePlanLoaing ? <ButtonLoader /> : "ACTIVATE"}
                  onClick={handlePurchasePlan}
                  disabled={
                    !selectedPlan?.amount || !selectedPlan?.value ? true : false
                  }
                  tooltip="Click here to activate your ID"
                />
              </DashCard>
            </div>
          )}
        </DashLayout>
      )}
    </Fragment>
  );
};

export default ActivateId;
