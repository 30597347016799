import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/directTeam.scss";
import "../../assets/styles/tableDataStyle.scss";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import usePaginate from "../../assets/hooks/usePaginate";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { clearTeamErrors, getDirectTeam } from "../../redux/action/teamAction";
import { toast } from "react-toastify";
import NotFoundCard from "../admin/components/NotFoundCard";
import { useNavigate } from "react-router-dom";
import { FaShareSquare, FaWhatsapp } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

const DirectTeam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sharePopup, setsharePopup] = useState(false);

  // USER DETAILS START
  const { userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;
  // USER DETAILS ENDS

  const { loading, team, error } = useSelector((state) => state.directTeam);

  const directTeam = team?.team;
  const directTeam1 = team?.totalDirectTeam;

  let thirdArray = [];
  for (let i = 0; i < directTeam1?.length; i++) {
    const element1 = directTeam[i];
    const element2 = directTeam1[i];
    let obj = {
      ...element1,
      ...element2,
    }
    thirdArray.push(obj)
  }
  console.log("thirdArray",thirdArray);

  // GET  DIRECT TEAM ON PAGE LOAD
  useEffect(() => {
    if (user?.paidUser) {
      dispatch(getDirectTeam());
    }
  }, [dispatch, user?.paidUser]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearTeamErrors());
    }

    if (team?.success) {
      // toast.success(team?.message);
    }
  }, [error, dispatch, team?.success, team?.message]);

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(thirdArray);
  // Pagination Hook Ends

  // LINK COPY TO CLIPBOARD START
  const handleLinkCopy = (link) => {
    if (link) {
      const copiedLink = navigator.clipboard.writeText(link);
      if (copiedLink !== "") {
        toast.success("Link Copied!");
      } else {
        toast.error("Something Went Wrong!");
      }
    } else {
      toast.error("Link not found!");
    }
  };
  // LINK COPY TO CLIPBOARD ENDS

  // LINK SHARE ON WHATSAPP START
  const shareOnWhatsapp = () => {
    const shareTextEng =
      "Hey! I came across this INDIAN BIG BULL excellent earning offer today and instantly thought of sharing it with you. If you want to get a new earning source through this special link behalf of INDIAN BIG BULL, So register now through the link given below. Do not Miss This Offer.";
    const shareUrl = `${process.env.REACT_APP_URL}/register?reffCode=${user?.username}`;
    const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(
      shareTextEng
    )}%20${encodeURIComponent(shareUrl)}`;
    window.location.href = whatsappUrl;
  };
  // LINK SHARE ON WHATSAPP ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <DashLayout>
          {user?.paidUser ? (
            <div className="directTeam_wrapper">
              <DashCard className="direct_team" title={"My Direct Team"}>
                <CustomInput
                  icon={<BsSearch />}
                  type="search"
                  //   onChange=""
                  placeholder="Search By Name..."
                  name="searchString"
                  //   value=""
                  //   impStar=""
                  //   readOnly=""
                  borderColor="#f2f2f2"
                />

                {directTeam?.length > 0 ? (
                  <>
                    <div className="table_data custonScroll">
                      <table className="list_table" cellSpacing="0">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Package</th>
                            <th>Payment Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.length > 0 &&
                            currentItems?.map((item, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>
                                    <p>
                                      {item?.username
                                        ? item?.username
                                        : "Visible after activation"}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      {item?.fullName
                                        ? item?.fullName
                                        : "Visible after activation"}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      {" "}
                                      {item?.buyAmount
                                        ? `Package of Rs ${item?.buyAmount}`
                                        : "---"}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className={
                                        item?.paidUser
                                          ? "green"
                                          : "red"
                                      }
                                    >
                                      <b>
                                        {item?.paidUser
                                          ? "Paid"
                                          : "Not paid"}
                                      </b>
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <div className="pagination_sec">
                      <p className="entriesCount">
                        Showing {itemOffset + 1} to{" "}
                        {endOffset > directTeam?.length
                          ? directTeam?.length
                          : endOffset}{" "}
                        of {directTeam?.length} entries
                      </p>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<RxDoubleArrowRight />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={<RxDoubleArrowLeft />}
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </>
                ) : (
                  <NotFoundCard
                    text="You have not any direct/refferal team."
                    showAction={true}
                    actionTitle="Share Link"
                    onClick={() => setsharePopup(true)}
                    actionIcon={<FaShareSquare />}
                  />
                )}
              </DashCard>
            </div>
          ) : (
            <NotFoundCard
              text="You have not any purchased plan yet, please purchage any plan to see you Direct team list."
              showAction={true}
              actionTitle="Purchage Plan Now"
              onClick={() => navigate("/user-dashboard/activate-my-id")}
              actionIcon=""
            />
          )}

          {/* SHARE POPUP START */}
          {sharePopup && (
            <div className="profileSharePopup_main">
              <div className="shareCard">
                <h2>
                  Share your refferal link, for more earnings...{" "}
                  <span
                    className="closeBtn"
                    onClick={() => setsharePopup(false)}
                  >
                    <IoMdClose />
                  </span>
                </h2>
                <div className="reffLink">
                  <span
                    onClick={() =>
                      handleLinkCopy(
                        `${process.env.REACT_APP_URL}/register?reffCode=${user?.username}`
                      )
                    }
                  >
                    {process.env.REACT_APP_URL}/register?reffCode=
                    {user?.username}
                  </span>
                  <span
                    className="copyBtn"
                    onClick={() =>
                      handleLinkCopy(
                        `${process.env.REACT_APP_URL}/register?reffCode=${user?.username}`
                      )
                    }
                  >
                    Copy Link
                  </span>
                </div>
                <button className="shareWaBtn" onClick={shareOnWhatsapp}>
                  <span className="icon">
                    <FaWhatsapp />
                  </span>{" "}
                  <span>Share On WhatsApp</span>
                </button>
              </div>
            </div>
          )}
          {/* SHARE POPUP ENDS */}
        </DashLayout>
      )}
    </Fragment>
  );
};

export default DirectTeam;
