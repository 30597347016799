import {
  ADD_FUND_FAIL,
  ADD_FUND_REQUEST,
  ADD_FUND_SUCCESS,
  CLEAR_FUND_ERRORS,
  CLEAR_FUND_STATE,
  DEPOSIT_HISTORY_FAIL,
  DEPOSIT_HISTORY_REQUEST,
  DEPOSIT_HISTORY_SUCCESS,
  FUND_ADD_DETAIL_FAIL,
  FUND_ADD_DETAIL_REQUEST,
  FUND_ADD_DETAIL_SUCCESS,
  FUND_ADD_LIST_FAIL,
  FUND_ADD_LIST_REQUEST,
  FUND_ADD_LIST_SUCCESS,
  UPDATE_FUND_ADD_FAIL,
  UPDATE_FUND_ADD_REQUEST,
  UPDATE_FUND_ADD_SUCCESS,
} from "../constant/fundConstant";

// ADD FUND REDUCER
export const addFundReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_FUND_REQUEST:
      return {
        loading: true,
        fund: {},
      };
    case ADD_FUND_SUCCESS:
      return {
        loading: false,
        fund: action.payload,
      };
    case ADD_FUND_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_FUND_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_FUND_STATE:
      return {
        loading: false,
        fund: {},
      };

    default:
      return state;
  }
};

// GET DEPOSIT HISTORY REDUCER
export const getDepositHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPOSIT_HISTORY_REQUEST:
      return {
        loading: true,
        fund: {},
      };
    case DEPOSIT_HISTORY_SUCCESS:
      return {
        loading: false,
        fund: action.payload,
      };
    case DEPOSIT_HISTORY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_FUND_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_FUND_STATE:
      return {
        loading: false,
        fund: {},
      };

    default:
      return state;
  }
};

// GET ALL FUNDS ADD REQUESTES REDUCER - BY ADMIN
export const getAllFundRequestsReducer = (state = {}, action) => {
  switch (action.type) {
    case FUND_ADD_LIST_REQUEST:
      return {
        loading: true,
        funds: {},
      };
    case FUND_ADD_LIST_SUCCESS:
      return {
        loading: false,
        funds: action.payload,
      };
    case FUND_ADD_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_FUND_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_FUND_STATE:
      return {
        loading: false,
        funds: {},
      };

    default:
      return state;
  }
};

// GET FUND DETAILS REQUESTS REDUCER - BY ADMIN
export const getAddFundDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case FUND_ADD_DETAIL_REQUEST:
      return {
        loading: true,
        fund: {},
      };
    case FUND_ADD_DETAIL_SUCCESS:
      return {
        loading: false,
        fund: action.payload,
      };
    case FUND_ADD_DETAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_FUND_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_FUND_STATE:
      return {
        loading: false,
        fund: {},
      };

    default:
      return state;
  }
};

// UPDATE ADD FUND REQUEST REDUCER - BY ADMIN
export const updateAddFundRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FUND_ADD_REQUEST:
      return {
        loading: true,
        updatedFund: {},
      };
    case UPDATE_FUND_ADD_SUCCESS:
      return {
        loading: false,
        updatedFund: action.payload,
      };
    case UPDATE_FUND_ADD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_FUND_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_FUND_STATE:
      return {
        loading: false,
        updatedFund: {},
      };

    default:
      return state;
  }
};
