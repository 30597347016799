import React, { Fragment, useEffect } from "react";
import "../../assets/styles/adminDashboard.scss";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import ServiceCard from "./components/ServiceCard";
import { FaUsers } from "react-icons/fa";
import { MdAddChart, MdNotifications } from "react-icons/md";
import { GiWallet } from "react-icons/gi";
import { ImProfile } from "react-icons/im";
import { BiBarcodeReader, BiMoneyWithdraw } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonLoader from "../../components/ButtonLoader";
import { getAllStats } from "../../redux/action/statsAction";
import Loader from "../../components/Loader";

const AdminDashboard = () => {
  const dispatch = useDispatch();

  // GET ALL STATS START
  // GET ALL STATS START
  const { loading, stats, error } = useSelector((state) => state.allStats);

  useEffect(() => {
    dispatch(getAllStats());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error, dispatch]);

  // GET ALL STATS ENDS
  // GET ALL STATS ENDS

  const cardData = [
    {
      link: "/admin/all-users",
      title: "All Users",
      icon: <FaUsers />,
      numbers: !loading ? `${stats?.users}` : <ButtonLoader />,
      subtitle: "users",
      buttonAction: "/admin/all-users",
      buttonTitle: "See List",
    },
    {
      link: "/admin/fund-add-requests",
      title: "Add Fund Requests",
      icon: <MdAddChart />,
      numbers: loading ? <ButtonLoader /> : `${stats?.totalAddFundReq}`,
      subtitle: "requests",
      buttonAction: "/admin/fund-add-requests",
      buttonTitle: "See Requests",
    },
    {
      link: "/admin/wallets-list",
      title: "User Wallets",
      icon: <GiWallet />,
      numbers: loading ? <ButtonLoader /> : `${stats?.totalWallets}`,
      subtitle: "wallets",
      buttonAction: "/admin/wallets-list",
      buttonTitle: "See Wallets",
    },
    {
      link: "/admin/withdraw-requests",
      title: "Withdraw Requests",
      icon: <BiMoneyWithdraw />,
      numbers: loading ? <ButtonLoader /> : `${stats?.withdrawRequests}`,
      subtitle: "requests",
      buttonAction: "/admin/withdraw-requests",
      buttonTitle: "See List",
    },
    {
      link: "/admin/kyc-list",
      title: "User KYCs",
      icon: <ImProfile />,
      numbers: loading ? <ButtonLoader /> : `${stats?.totalKyc}`,
      subtitle: "approvals pending",
      buttonAction: "/admin/kyc-list",
      buttonTitle: "See List",
    },
    {
      link: "/admin",
      title: "Notifications",
      icon: <MdNotifications />,
      numbers: "21",
      subtitle: "sent",
      buttonAction: "/admin",
      buttonTitle: "See List",
    },
    {
      link: "all-plans",
      title: "All Plans",
      icon: <MdNotifications />,
      numbers: loading ? <ButtonLoader /> : `${stats?.totalPlans}`,
      subtitle: "Plans",
      buttonAction: "create-plan",
      buttonTitle: "Create Plan",
    },
    {
      link: "update-barcode",
      title: "Barcode",
      subtitle: "Payment Barcode",
      icon: <BiBarcodeReader />,
      buttonAction: "update-barcode",
      buttonTitle: "See Barcode",
    },
  ];

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="adminDashboard_main">
            <div className="container">
              <PageTitle goBack={false} title="Admin Dashboard" />
              <div className="admin_cards">
                {cardData.map((item, idx) => (
                  <ServiceCard
                    link={item.link}
                    title={item.title}
                    icon={item.icon}
                    numbers={item.numbers}
                    subtitle={item.subtitle}
                    buttonAction={item.buttonAction}
                    buttonTitle={item.buttonTitle}
                    key={idx}
                  />
                ))}
              </div>
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default AdminDashboard;
