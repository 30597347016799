import {
  CLEAR_KYC_ERRORS,
  CLEAR_KYC_MESSAGE,
  CLEAR_KYC_STATE,
  KYC_DETAILS_FAIL,
  KYC_DETAILS_REQUEST,
  KYC_DETAILS_SUCCESS,
  KYC_LIST_FAIL,
  KYC_LIST_REQUEST,
  KYC_LIST_SUCCESS,
  UPDATE_KYC_ADMIN_FAIL,
  UPDATE_KYC_ADMIN_REQUEST,
  UPDATE_KYC_ADMIN_SUCCESS,
} from "../constant/kycConstant";

// GET ALL USER's KYC LIST - ADMIN
export const getAllKycListReducer = (state = {}, action) => {
  switch (action.type) {
    case KYC_LIST_REQUEST:
      return {
        loading: true,
        kyc: {},
      };
    case KYC_LIST_SUCCESS:
      return {
        loading: false,
        kyc: action.payload,
        message: action.payload.message,
      };
    case KYC_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_KYC_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_KYC_STATE:
      return {
        loading: false,
        kyc: {},
      };
    case CLEAR_KYC_MESSAGE:
      return {
        ...state,
        loading: false,
        message: "",
      };

    default:
      return state;
  }
};

// KYC DETAILS BY ID - ADMIN
export const getKycDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case KYC_DETAILS_REQUEST:
      return {
        loading: true,
        kyc: {},
      };
    case KYC_DETAILS_SUCCESS:
      return {
        loading: false,
        kyc: action.payload,
        message: action.payload.message,
      };
    case KYC_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_KYC_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_KYC_STATE:
      return {
        loading: false,
        kyc: {},
      };
    case CLEAR_KYC_MESSAGE:
      return {
        ...state,
        loading: false,
        message: "",
      };

    default:
      return state;
  }
};

// UPDATE KYC - ADMIN
export const updateKycReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_KYC_ADMIN_REQUEST:
      return {
        loading: true,
        kyc: {},
      };
    case UPDATE_KYC_ADMIN_SUCCESS:
      return {
        loading: false,
        kyc: action.payload,
        message: action.payload.message,
      };
    case UPDATE_KYC_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_KYC_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_KYC_STATE:
      return {
        loading: false,
        kyc: {},
      };
    case CLEAR_KYC_MESSAGE:
      return {
        ...state,
        loading: false,
        message: "",
      };

    default:
      return state;
  }
};
