import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_KYC_ERRORS,
  CLEAR_KYC_MESSAGE,
  CLEAR_KYC_STATE,
  KYC_DETAILS_FAIL,
  KYC_DETAILS_REQUEST,
  KYC_DETAILS_SUCCESS,
  KYC_LIST_FAIL,
  KYC_LIST_REQUEST,
  KYC_LIST_SUCCESS,
  UPDATE_KYC_ADMIN_FAIL,
  UPDATE_KYC_ADMIN_REQUEST,
  UPDATE_KYC_ADMIN_SUCCESS,
} from "../constant/kycConstant";

// GET USER KYC LIST --- ADMIN
export const getKycList = () => async (dispatch) => {
  try {
    dispatch({
      type: KYC_LIST_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/kyc-list`, config);

    dispatch({
      type: KYC_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: KYC_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET KYC DETAILS --- ADMIN
export const getKycDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: KYC_DETAILS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/kyc/${id}`, config);

    dispatch({
      type: KYC_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: KYC_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE USER KYC --- ADMIN
export const updateKycAdmin = (id, isActive, status) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_KYC_ADMIN_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/update-kyc/${id}`,
      { isActive, status },
      config
    );

    dispatch({
      type: UPDATE_KYC_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_KYC_ADMIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearKycErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_KYC_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearKycState = () => async (dispatch) => {
  dispatch({ type: CLEAR_KYC_STATE });
};

// CLEAR MESSAGE ACTION FUNCTION
export const clearKycMessage = () => async (dispatch) => {
  dispatch({ type: CLEAR_KYC_MESSAGE });
};
