import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/profile.scss";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import { defaultPic } from "../../assets/images/images";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { FaExpeditedssl, FaUserEdit } from "react-icons/fa";
import { State, City } from "country-state-city";
import {
  clearErrors,
  clearUpdateState,
  removeAvatar,
  updateProfile,
  userProfile,
} from "../../redux/action/userAction";
import UploadProfilePicForm from "./components/UploadProfilePicForm";
import { AiFillDelete } from "react-icons/ai";
import { BiUpload } from "react-icons/bi";
import { toast } from "react-toastify";
import ButtonLoader from "../../components/ButtonLoader";
import Loader from "../../components/Loader";

const Profile = () => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [uploadProfileModal, setUploadProfileModal] = useState(false);

  const { loading, userInfo } = useSelector((state) => state.profile);

  const user = userInfo?.user;

  const [profileData, setProfileData] = useState({
    fullName: user?.fullName,
    email: user?.email,
    phone: user?.phone,
    gender: user?.gender,
    dob: user?.dob,
    address: user?.address,
    state: user?.state,
    city: user?.city,
    village: user?.village,
    pinCode: user?.pinCode,
  });

  // console.log("profileData", profileData);

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;

    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  // UPDATE USER PROFILE START
  // UPDATE USER PROFILE START
  const {
    userInfo: updateInfo,
    error: updateError,
    loading: updateLoading,
  } = useSelector((state) => state.updateProfile);

  const updateHandler = () => {
    dispatch(
      updateProfile(
        profileData.fullName,
        profileData.email,
        profileData.phone,
        profileData.dob,
        profileData.gender,
        profileData.address,
        profileData.state,
        profileData.city,
        profileData.village,
        profileData.pinCode
      )
    );
  };

  // FOR UPDATE PROFILE
  useEffect(() => {
    if (updateError) {
      toast.error(updateError);
      dispatch(clearErrors());
    }

    if (updateInfo?.success) {
      toast.success(updateInfo?.message);
      setTimeout(() => {
        dispatch(clearUpdateState());
        dispatch(userProfile()); // GET UPDATED USER DETAILS
      }, 3000);
    }
  }, [updateError, updateInfo?.success, updateInfo?.message, dispatch]);
  // UPDATE USER PROFILE ENDS
  // UPDATE USER PROFILE ENDS

  // REMOVE PROFILE PIC START
  // REMOVE PROFILE PIC START
  const {
    loading: avatarInfoLoading,
    avatarInfo,
    error,
  } = useSelector((state) => state.removeAvatar);

  const handleRemoveAvatar = () => {
    dispatch(removeAvatar());
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (avatarInfo?.success) {
      toast.success(avatarInfo?.message);

      setTimeout(() => {
        dispatch(clearUpdateState());
        dispatch(userProfile()); // GET UPDATED USER DETAILS
      }, 3000);
    }
  }, [error, avatarInfo?.success, avatarInfo?.message, dispatch]);

  // REMOVE PROFILE PIC ENDS
  // REMOVE PROFILE PIC ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <DashLayout>
          <div className="profile_content">
            <DashCard
              className="profileData"
              title={`${user?.fullName}'s Profile`}
              subTitle={
                <button
                  title="Click here to edit your profile"
                  style={{
                    backgroundColor: editable && "red",
                  }}
                  onClick={() => setEditable(!editable)}
                >
                  {editable ? (
                    <FaExpeditedssl className="icon" />
                  ) : (
                    <FaUserEdit className="icon" />
                  )}{" "}
                  Edit
                </button>
              }
            >
              <div className="profileForm">
                <h3 className="formTitle">Personal Details</h3>
                <div className="personalDetails">
                  <div className="profilePic">
                    <img
                      src={user?.avatar?.url ? user?.avatar?.url : defaultPic}
                      alt={`indian big bull ${user?.fullName}'s profile`}
                    />

                    {editable && (
                      <div className="buttonsec">
                        <button
                          className="removePic"
                          title="Remove profile picture"
                          onClick={handleRemoveAvatar}
                        >
                          {avatarInfoLoading ? (
                            <ButtonLoader />
                          ) : (
                            <AiFillDelete />
                          )}
                        </button>
                        <button
                          title="Upload profile picture"
                          onClick={() => setUploadProfileModal(true)}
                        >
                          <BiUpload />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="userDetails">
                    <CustomInput
                      label="Full Name"
                      type="text"
                      impStar="*"
                      placeholder="Your full name..."
                      name="fullName"
                      borderColor={editable ? "red" : "#f2f2f2"}
                      readOnly={editable ? false : true}
                      value={editable ? profileData?.fullName : user?.fullName}
                      onChange={handleInputChange}
                    />
                    <CustomInput
                      label="Email"
                      type="email"
                      impStar="*"
                      placeholder="Your email id..."
                      name="email"
                      borderColor={editable ? "red" : "#f2f2f2"}
                      readOnly={editable ? false : true}
                      value={editable ? profileData?.email : user?.email}
                      onChange={handleInputChange}
                    />
                    <CustomInput
                      label="Mobile Number"
                      type="number"
                      impStar="*"
                      placeholder="Your mobile number..."
                      name="phone"
                      borderColor={editable ? "red" : "#f2f2f2"}
                      readOnly={editable ? false : true}
                      value={editable ? profileData?.phone : user?.phone}
                      onChange={handleInputChange}
                    />
                    <CustomSelect
                      label="Gender"
                      impStar="*"
                      name="gender"
                      optionArray={[
                        {
                          id: 1,
                          option: "Male",
                        },
                        {
                          id: 2,
                          option: "Female",
                        },
                        {
                          id: 3,
                          option: "Other",
                        },
                      ]}
                      borderColor={editable ? "red" : "#f2f2f2"}
                      readOnly={editable ? false : true}
                      value={editable ? profileData?.gender : user?.gender}
                      onChange={handleInputChange}
                    />
                    <CustomInput
                      label="Date of birth"
                      type="date"
                      impStar="*"
                      placeholder="Your date of birth..."
                      name="dob"
                      borderColor={editable ? "red" : "#f2f2f2"}
                      readOnly={editable ? false : true}
                      value={editable ? profileData?.dob : user?.dob}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <h3 className="formTitle">Contact Details</h3>
                <div className="contactDetails">
                  <CustomInput
                    label="Full Address"
                    type="text"
                    impStar="*"
                    placeholder="Your full address..."
                    name="address"
                    borderColor={editable ? "red" : "#f2f2f2"}
                    fullWidth={true}
                    readOnly={editable ? false : true}
                    value={editable ? profileData?.address : user?.address}
                    onChange={handleInputChange}
                  />

                  <div className="state_district">
                    <div className="myInput">
                      <label>
                        State <abbr className="impStar">*</abbr>
                      </label>
                      <select
                        name="state"
                        readOnly={editable ? false : true}
                        disabled={editable ? false : true}
                        onChange={handleInputChange}
                        value={editable ? profileData?.state : user?.state}
                        style={{
                          border: `1px solid ${editable ? "red" : "#f2f2f2"}`,
                        }}
                      >
                        <option className="optionClass" value="">
                          Select One Option
                        </option>
                        {State.getStatesOfCountry("IN")?.map((item, idx) => (
                          <option
                            className="optionClass"
                            key={idx}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="myInput">
                      <label>
                        District/City <abbr className="impStar">*</abbr>
                      </label>
                      <select
                        name="city"
                        readOnly={editable && profileData?.state ? false : true}
                        disabled={editable && profileData?.state ? false : true}
                        onChange={handleInputChange}
                        value={editable ? profileData?.city : user?.city}
                        style={{
                          border: `1px solid ${editable ? "red" : "#f2f2f2"}`,
                        }}
                      >
                        <option className="optionClass" value="">
                          Select One Option
                        </option>
                        {City?.getCitiesOfState("IN", profileData.state)?.map(
                          (item, idx) => (
                            <option
                              className="optionClass"
                              key={idx}
                              value={item.name}
                            >
                              {item.name}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <CustomInput
                      label="Village/Colony"
                      type="text"
                      impStar="*"
                      placeholder="Your village/colony..."
                      name="village"
                      borderColor={editable ? "red" : "#f2f2f2"}
                      readOnly={editable ? false : true}
                      value={editable ? profileData?.village : user?.village}
                      onChange={handleInputChange}
                    />
                    <CustomInput
                      label="Pin Code"
                      type="number"
                      impStar="*"
                      placeholder="Your area pin code..."
                      name="pinCode"
                      borderColor={editable ? "red" : "#f2f2f2"}
                      readOnly={editable ? false : true}
                      value={editable ? profileData?.pinCode : user?.pinCode}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {editable && (
                  <CustomButton
                    className="updateProfile_btn"
                    title={updateLoading ? <ButtonLoader /> : "Update"}
                    tooltip="Update Profile"
                    disabled={updateLoading ? true : false}
                    onClick={updateHandler}
                  />
                )}
              </div>
            </DashCard>
          </div>
          {/* UPLOAD PROFILE PICTURE FORM START */}
          {uploadProfileModal && (
            <section className="upload_profile_pic_main">
              <UploadProfilePicForm
                setUploadProfileModal={setUploadProfileModal}
              />
            </section>
          )}

          {/* UPLOAD PROFILE PICTURE FORM ENDS */}
        </DashLayout>
      )}
    </Fragment>
  );
};

export default Profile;
