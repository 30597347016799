import React, { Fragment, useEffect } from "react";
import "../assets/styles/plan.scss";
import Layout from "../components/Layout";
import { slider2 } from "../assets/images/images";
import BreadCrumb from "../components/BreadCrumb";
import Bottom from "../components/Bottom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getPlanList } from "../redux/action/planAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import NotFoundCard from "./admin/components/NotFoundCard";
import CustomHelmet from "../components/Helmet";

const Plans = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // GET PLAN LIST START
  // GET PLAN LIST START
  const { loading, error, plans } = useSelector((state) => state.getPlanList);

  const allPlans = plans?.packages;

  useEffect(() => {
    dispatch(getPlanList());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [error, dispatch]);
  // GET PLAN LIST ENDS
  // GET PLAN LIST ENDS

  return (
    <Fragment>
      <CustomHelmet
        title={`All existing plans - Indian Big Bull`}
        canonical="https://indianbigbull.in/"
      />
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <BreadCrumb img={slider2} title="Our Plans" />
          <section className="plan_page">
            <div className="container">
              <div className="titleMain">
                <p>PRICING PLAN</p>
                <h2>Our Pricing Plan</h2>
                <Bottom center />
              </div>
              {allPlans?.length > 0 ? (
                <>
                  <div className="planContent">
                    {allPlans?.map((item, idx) => (
                      <div className="planCard" key={idx}>
                        <img src={item?.thumbnail?.url} alt="planImg" />
                        <div className="buttonSection">
                          <button>Purchase Plan</button>
                          <button
                            title={`Click here to see ${item?.title} details`}
                            onClick={() => navigate(`/plan/${item?._id}`)}
                          >
                            See Details
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <NotFoundCard
                  txtColor="#2b74c1"
                  text="There is no plans added by management... Please wait for new plans and pricing..."
                />
              )}
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default Plans;
