// ADMIN
export const KYC_LIST_REQUEST = "KYC_LIST_REQUEST";
export const KYC_LIST_SUCCESS = "KYC_LIST_SUCCESS";
export const KYC_LIST_FAIL = "KYC_LIST_FAIL";

export const KYC_DETAILS_REQUEST = "KYC_DETAILS_REQUEST";
export const KYC_DETAILS_SUCCESS = "KYC_DETAILS_SUCCESS";
export const KYC_DETAILS_FAIL = "KYC_DETAILS_FAIL";

export const UPDATE_KYC_ADMIN_REQUEST = "UPDATE_KYC_ADMIN_REQUEST";
export const UPDATE_KYC_ADMIN_SUCCESS = "UPDATE_KYC_ADMIN_SUCCESS";
export const UPDATE_KYC_ADMIN_FAIL = "UPDATE_KYC_ADMIN_FAIL";

export const CLEAR_KYC_ERRORS = "CLEAR_KYC_ERRORS";
export const CLEAR_KYC_STATE = "CLEAR_KYC_STATE";
export const CLEAR_KYC_MESSAGE = "CLEAR_KYC_MESSAGE";
