import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_ERRORS,
  CLEAR_STATE,
  DIRECT_TEAM_FAIL,
  DIRECT_TEAM_REQUEST,
  DIRECT_TEAM_SUCCESS,
  LEVEL_TEAM_FAIL,
  LEVEL_TEAM_REQUEST,
  LEVEL_TEAM_SUCCESS,
} from "../constant/teamConstant";

// GET USER's DIRECT TEAM
export const getDirectTeam = () => async (dispatch) => {
  try {
    dispatch({
      type: DIRECT_TEAM_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/direct-team`, config);

    dispatch({
      type: DIRECT_TEAM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DIRECT_TEAM_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER's LEVEL TEAM
export const getLevelTeam = (id, level) => async (dispatch) => {
  try {
    dispatch({
      type: LEVEL_TEAM_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/user-count/${id}?level=${level}`,
      config
    );

    dispatch({
      type: LEVEL_TEAM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LEVEL_TEAM_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearTeamErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearTeamState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};
