import React, { Fragment, useEffect } from "react";
import "../../assets/styles/allWithdrawRequests.scss";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import { FaEye } from "react-icons/fa";
// import ButtonLoader from "../../components/ButtonLoader";
// import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import usePaginate from "../../assets/hooks/usePaginate";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearWithdrawErrors,
  getAllWithdrawReqList,
} from "../../redux/action/withdrawalReqAction";
import Loader from "../../components/Loader";
import moment from "moment";

const AllWithdrawRequests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // GET ALL WITHDRAW REQUEST LIST START
  // GET ALL WITHDRAW REQUEST LIST START
  const { loading, request, error } = useSelector(
    (state) => state.getAllWithdrawReq
  );

  const allWithdrawList = request?.withdrawReqList;

  useEffect(() => {
    dispatch(getAllWithdrawReqList());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearWithdrawErrors());
    }
  }, [error, dispatch]);
  // GET ALL WITHDRAW REQUEST LIST ENDS
  // GET ALL WITHDRAW REQUEST LIST ENDS

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(allWithdrawList);
  // Pagination Hook Ends
  return (
    <Fragment>
      <AdminLayout>
        {loading ? (
          <Loader />
        ) : (
          <div className="allWithdrawReq_wrapper">
            <div className="container">
              <PageTitle goBack={true} title={`Withdraw Request List`} />

              <div className="allWithdrawReq_content">
                <div className="table_data custonScroll">
                  <table className="list_table" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Request Date</th>
                        <th>Requested By</th>
                        <th>Transaction #ID</th>
                        <th>Requested Amount</th>
                        <th>TDS</th>
                        <th>Admin Charges</th>
                        <th>Total Paid</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.reverse()?.map((item, idx) => {
                        const txnDate = moment(item?.createdAt).format(
                          "MMMM Do YYYY, hh:mm:ss A"
                        );
                        return (
                          <tr key={idx}>
                            <td>
                              <p>{txnDate}</p>
                            </td>
                            <td>
                              <p>{item?.forUser?.fullName}</p>
                            </td>
                            <td>
                              <p>{item?.txnId ? item?.txnId : "---"}</p>
                            </td>
                            <td>
                              <p>₹{item?.amount}</p>
                            </td>
                            <td>
                              <p>{item?.tds ? item?.tds : "---"}</p>
                            </td>
                            <td>
                              <p>
                                {item?.adminCharge ? item?.adminCharge : "---"}
                              </p>
                            </td>
                            <td>
                              <p>
                                {item?.creditedAmount
                                  ? item?.creditedAmount
                                  : "---"}
                              </p>
                            </td>
                            <td>
                              <p
                                className={
                                  item?.status === "Completed"
                                    ? "green"
                                    : item?.status === "Pending"
                                    ? "yellow"
                                    : "red"
                                }
                              >
                                <b>{item?.status}</b>
                              </p>
                            </td>
                            <td>
                              <div>
                                <button
                                  title="View/Edit Withdraw Request"
                                  onClick={() =>
                                    navigate(`/admin/withdrawReq/${item?._id}`)
                                  }
                                >
                                  <FaEye />
                                </button>
                                {/* <button
                                  title="Delete withdraw request"
                                  onClick={() => alert(item?._id)}
                                >
                                  <MdDelete />
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagination_sec">
                  <p className="entriesCount">
                    Showing {itemOffset + 1} to{" "}
                    {endOffset > allWithdrawList?.length
                      ? allWithdrawList?.length
                      : endOffset}{" "}
                    of {allWithdrawList?.length} entries
                  </p>
                  {allWithdrawList?.length > 10 && (
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<RxDoubleArrowRight />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<RxDoubleArrowLeft />}
                      renderOnZeroPageCount={null}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </AdminLayout>
    </Fragment>
  );
};

export default AllWithdrawRequests;
