import React, { Fragment, useEffect } from "react";
import "../../assets/styles/directSponsorIncome.scss";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import usePaginate from "../../assets/hooks/usePaginate";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotFoundCard from "../admin/components/NotFoundCard";
import { clearTeamErrors, getDirectTeam } from "../../redux/action/teamAction";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import moment from "moment";

const DirectSponsorIncome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // USER DETAILS START
  const { userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;
  // USER DETAILS ENDS

  const { loading, team, error } = useSelector((state) => state.directTeam);

  const directTeam = team?.team;

  // GET  DIRECT TEAM ON PAGE LOAD
  useEffect(() => {
    if (user?.paidUser) {
      dispatch(getDirectTeam());
    }
  }, [dispatch, user?.paidUser]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearTeamErrors());
    }
  }, [error, dispatch, team?.success, team?.message]);

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(directTeam);
  // Pagination Hook Ends
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <DashLayout>
          {user?.paidUser ? (
            <div className="directSponsor_income_wrapper">
              <DashCard
                className="directSponsor_income"
                title={"Direct Income"}
              >
                <CustomInput
                  icon={<BsSearch />}
                  type="search"
                  //   onChange=""
                  placeholder="Search..."
                  name="searchString"
                  //   value=""
                  //   impStar=""
                  //   readOnly=""
                  borderColor="#f2f2f2"
                />
                <div className="table_data custonScroll">
                  <table className="list_table" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Referred User</th>
                        <th>Package</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((item, idx) => {
                        const createdDate = moment(item?.createdAt).format(
                          "DD/MM/YYYY [at] hh:mm A"
                        );

                        let refferalIncome = 0;
                        if (item?.buyAmount) {
                          refferalIncome =  (item?.buyAmount * 30) / 100;
                        }

                        return (
                          item && (
                            <tr key={idx}>
                              <td>
                                <p>{createdDate}</p>
                              </td>
                              <td>
                                <p>{item?.forUser?.username}</p>
                              </td>

                              <td>
                                <p>Package of ₹{item?.buyAmount}</p>
                              </td>
                              <td>
                                <p>₹ {refferalIncome}</p>
                              </td>
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagination_sec">
                  <p className="entriesCount">
                    Showing {itemOffset + 1} to{" "}
                    {endOffset > directTeam?.length
                      ? directTeam?.length
                      : endOffset}{" "}
                    of {directTeam?.length} entries
                  </p>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </DashCard>
            </div>
          ) : (
            <NotFoundCard
              text="You have not any purchased plan yet, please purchage any plan to see your Direct/Sponsor income."
              showAction={true}
              actionTitle="Purchage Plan Now"
              onClick={() => navigate("/user-dashboard/activate-my-id")}
              actionIcon=""
            />
          )}
        </DashLayout>
      )}
    </Fragment>
  );
};

export default DirectSponsorIncome;
