import React, { Fragment } from "react";
import "../../assets/styles/levelIncome.scss";
import usePaginate from "../../assets/hooks/usePaginate";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotFoundCard from "../admin/components/NotFoundCard";

const LevelIncome = () => {
  const navigate = useNavigate();
  // USER DETAILS START
  const { userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;
  // USER DETAILS ENDS

  const dummyTeam = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(dummyTeam);
  // Pagination Hook Ends
  return (
    <Fragment>
      <DashLayout>
        {user?.paidUser ? (
          <div className="level_income_wrapper">
            <DashCard className="level_income" title={"Level Income"}>
              <CustomInput
                icon={<BsSearch />}
                type="search"
                //   onChange=""
                placeholder="Search..."
                name="searchString"
                //   value=""
                //   impStar=""
                //   readOnly=""
                borderColor="#f2f2f2"
              />
              <div className="table_data custonScroll">
                <table className="list_table" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Referred User</th>
                      <th>Level No.</th>
                      <th>Package</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <p>01/11/2023</p>
                          </td>
                          <td>
                            <p>IBB38670{idx}</p>
                          </td>
                          <td>
                            <p>Level-2</p>
                          </td>
                          <td>
                            <p>Package of ₹999</p>
                          </td>
                          <td>
                            <p>₹50</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="pagination_sec">
                <p className="entriesCount">
                  Showing {itemOffset + 1} to{" "}
                  {endOffset > dummyTeam?.length
                    ? dummyTeam?.length
                    : endOffset}{" "}
                  of {dummyTeam?.length} entries
                </p>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<RxDoubleArrowRight />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={<RxDoubleArrowLeft />}
                  renderOnZeroPageCount={null}
                />
              </div>
            </DashCard>
          </div>
        ) : (
          <NotFoundCard
            text="You have not any purchased plan yet, please purchage any plan to see your level income."
            showAction={true}
            actionTitle="Purchage Plan Now"
            onClick={() => navigate("/user-dashboard/activate-my-id")}
            actionIcon=""
          />
        )}
      </DashLayout>
    </Fragment>
  );
};

export default LevelIncome;
