import { axiosInstance } from "../../utils/axiosConfig";
import {
  ALL_WALLETS_FAIL,
  ALL_WALLETS_REQUEST,
  ALL_WALLETS_SUCCESS,
  CLEAR_WALLET_ERRORS,
  CLEAR_WALLET_MESSAGE,
  CLEAR_WALLET_STATE,
  MY_WALLET_FAIL,
  MY_WALLET_REQUEST,
  MY_WALLET_SUCCESS,
  UPDATE_WALLET_FAIL,
  UPDATE_WALLET_REQUEST,
  UPDATE_WALLET_SUCCESS,
  WALLET_DETAILS_FAIL,
  WALLET_DETAILS_REQUEST,
  WALLET_DETAILS_SUCCESS,
} from "../constant/walletConstant";

// GET MY WALLET ACTION
export const getMyWallet = () => async (dispatch) => {
  try {
    dispatch({
      type: MY_WALLET_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/my-wallet`, config);

    dispatch({
      type: MY_WALLET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MY_WALLET_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL WALLETS LIST ACTION -- ADMIN
export const getAllWalletsList = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_WALLETS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/wallet-list`, config);

    dispatch({
      type: ALL_WALLETS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_WALLETS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET WALLET DETAILS ACTION -- ADMIN
export const getWalletDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: WALLET_DETAILS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/wallet/${id}`, config);

    dispatch({
      type: WALLET_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALLET_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE WALLET DETAILS ACTION -- ADMIN
export const updateWalletDetails = (id, isActive) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_WALLET_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/update-wallet/${id}`,
      { isActive },
      config
    );

    dispatch({
      type: UPDATE_WALLET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_WALLET_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearWalletErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_WALLET_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearWalletState = () => async (dispatch) => {
  dispatch({ type: CLEAR_WALLET_STATE });
};

// CLEAR MESSAGE ACTION FUNCTION
export const clearWalletMessage = () => async (dispatch) => {
  dispatch({ type: CLEAR_WALLET_MESSAGE });
};
