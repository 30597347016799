import React, { createContext, useContext, useEffect } from "react";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import hiJSON from "../locale/hi.json";
import enJSON from "../locale/en.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enJSON,
    },
    hi: {
      translation: hiJSON,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export const langContext = createContext();

const LocaleProvider = ({ children }) => {
  const { t } = useTranslation();

  const changeLang = (langParam) => {
    // const currentLang = localStorage.getItem("lang")
    //   ? localStorage.getItem("lang")
    //   : "en";
    // if (currentLang === "en") {
    //   i18n.changeLanguage("hi");
    //   localStorage.setItem("lang", "hi");
    // } else if (currentLang === "hi") {
    //   i18n.changeLanguage("en");
    //   localStorage.setItem("lang", "en");
    // }
    i18n.changeLanguage(langParam);
    localStorage.setItem("lang", langParam);
  };

  useEffect(() => {
    const currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  return (
    <langContext.Provider value={[t, changeLang]}>
      {children}
    </langContext.Provider>
  );
};

const useLocale = () => useContext(langContext);

export { useLocale, LocaleProvider };
