import React, { Fragment } from "react";
import "../../assets/styles/autoPoolIncome.scss";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import ReactPaginate from "react-paginate";
import usePaginate from "../../assets/hooks/usePaginate";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";

const AutoPoolIncome = () => {
  const dummyTeam = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(dummyTeam);
  // Pagination Hook Ends
  return (
    <Fragment>
      <DashLayout>
        <div className="autoPool_income_wrapper">
          <DashCard className="autoPool_income" title={"Auto Pool Income"}>
            <CustomInput
              icon={<BsSearch />}
              type="search"
              //   onChange=""
              placeholder="Search..."
              name="searchString"
              //   value=""
              //   impStar=""
              //   readOnly=""
              borderColor="#f2f2f2"
            />
            <div className="table_data custonScroll">
              <table className="list_table" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <p>01/11/2023</p>
                        </td>
                        <td>
                          <p>₹200</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="pagination_sec">
              <p className="entriesCount">
                Showing {itemOffset + 1} to{" "}
                {endOffset > dummyTeam?.length ? dummyTeam?.length : endOffset}{" "}
                of {dummyTeam?.length} entries
              </p>
              <ReactPaginate
                breakLabel="..."
                nextLabel={<RxDoubleArrowRight />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel={<RxDoubleArrowLeft />}
                renderOnZeroPageCount={null}
              />
            </div>
          </DashCard>
        </div>
      </DashLayout>
    </Fragment>
  );
};
export default AutoPoolIncome;
