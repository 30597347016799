import React from "react";
import styled from "styled-components";

const Div = styled.div`
  width: 100%;
  height: 100vh;
  background: #394b71;
  display: flex;
  align-items: center;
  justify-content: center;

  div.loader {
    width: 90px;
    height: 90px;
    border-top: 5px solid #f2f2f2;
    border-right: 5px solid #f2f2f2;
    border-radius: 50%;
    animation: rotateLoader 0.6s linear infinite;
    margin: 0 auto;
  }

  div.title {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #f2f2f2;
    text-align: center;
  }
`;

const Loader = () => {
  return (
    <Div>
      <div>
        <div className="loader"></div>
        <div className="title">Loading...</div>
      </div>
    </Div>
  );
};

export default Loader;
