export const MY_WALLET_REQUEST = "MY_WALLET_REQUEST";
export const MY_WALLET_SUCCESS = "MY_WALLET_SUCCESS";
export const MY_WALLET_FAIL = "MY_WALLET_FAIL";

// ADMIN
export const ALL_WALLETS_REQUEST = "ALL_WALLETS_REQUEST";
export const ALL_WALLETS_SUCCESS = "ALL_WALLETS_SUCCESS";
export const ALL_WALLETS_FAIL = "ALL_WALLETS_FAIL";

export const WALLET_DETAILS_REQUEST = "WALLET_DETAILS_REQUEST";
export const WALLET_DETAILS_SUCCESS = "WALLET_DETAILS_SUCCESS";
export const WALLET_DETAILS_FAIL = "WALLET_DETAILS_FAIL";

export const UPDATE_WALLET_REQUEST = "UPDATE_WALLET_REQUEST";
export const UPDATE_WALLET_SUCCESS = "UPDATE_WALLET_SUCCESS";
export const UPDATE_WALLET_FAIL = "UPDATE_WALLET_FAIL";

export const CLEAR_WALLET_ERRORS = "CLEAR_WALLET_ERRORS";
export const CLEAR_WALLET_STATE = "CLEAR_WALLET_STATE";
export const CLEAR_WALLET_MESSAGE = "CLEAR_WALLET_MESSAGE";
