export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const SPONSOR_REQUEST = "SPONSOR_REQUEST";
export const SPONSOR_SUCCESS = "SPONSOR_SUCCESS";
export const SPONSOR_FAIL = "SPONSOR_FAIL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAIL = "PROFILE_FAIL";

export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL";

export const UPDATE_AVATAR_REQUEST = "UPDATE_AVATAR_REQUEST";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";
export const UPDATE_AVATAR_FAIL = "UPDATE_AVATAR_FAIL";

export const REMOVE_AVATAR_REQUEST = "REMOVE_AVATAR_REQUEST";
export const REMOVE_AVATAR_SUCCESS = "REMOVE_AVATAR_SUCCESS";
export const REMOVE_AVATAR_FAIL = "REMOVE_AVATAR_FAIL";

export const UPDATE_KYC_REQUEST = "UPDATE_KYC_REQUEST";
export const UPDATE_KYC_SUCCESS = "UPDATE_KYC_SUCCESS";
export const UPDATE_KYC_FAIL = "UPDATE_KYC_FAIL";

export const GET_KYC_REQUEST = "GET_KYC_REQUEST";
export const GET_KYC_SUCCESS = "GET_KYC_SUCCESS";
export const GET_KYC_FAIL = "GET_KYC_FAIL";

export const ALL_USERS_REQUEST = "ALL_USERS_REQUEST";
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_FAIL = "ALL_USERS_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_REGISTER_STATE = "CLEAR_REGISTER_STATE";
export const CLEAR_STATE = "CLEAR_STATE";
export const CLEAR_UPDATE_STATE = "CLEAR_UPDATE_STATE";

