import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/kyc.scss";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import {
  clearErrors,
  clearUpdateState,
  getKyc,
  updateKyc,
} from "../../redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonLoader from "../../components/ButtonLoader";
import Loader from "../../components/Loader";
import { clearKycErrors } from "../../redux/action/kycAction";

const Kyc = () => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);

  // GET KYC DETAILS START
  // GET KYC DETAILS START
  const {
    loading: kycLoading,
    kyc: myKyc,
    error: kycError,
  } = useSelector((state) => state.getUserKyc);

  const userKyc = myKyc?.userKyc;

  useEffect(() => {
    dispatch(getKyc());
  }, [dispatch]);

  useEffect(() => {
    if (kycError) {
      dispatch(clearKycErrors());
      toast.error(kycError);
      setEditable(true);
    }

    // if (myKyc?.success) {
    //   toast.success(myKyc?.message);
    // }
  }, [kycError, dispatch]);

  // GET KYC DETAILS ENDS
  // GET KYC DETAILS ENDS

  const [kycDetails, setkycDetails] = useState({
    aadharCard: userKyc?.aadharCard,
    accountHolder: userKyc?.bankPassbook?.accountHolder,
    accountNo: userKyc?.bankPassbook?.accountNo,
    bankName: userKyc?.bankPassbook?.bankName,
    bankBranch: userKyc?.bankPassbook?.bankBranch,
    bankIfsc: userKyc?.bankPassbook?.bankIfsc,
  });

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setkycDetails({
      ...kycDetails,
      [name]: value,
    });
  };

  // UPDATE KYC DETAILS START
  // UPDATE KYC DETAILS START
  const { loading, kyc, error } = useSelector((state) => state.updateUserKyc);

  // KYC UPDATE HANDLER
  const handleUpdateKyc = () => {
    dispatch(
      updateKyc(
        kycDetails.aadharCard,
        kycDetails.accountHolder,
        kycDetails.accountNo,
        kycDetails.bankName,
        kycDetails.bankBranch,
        kycDetails.bankIfsc
      )
    );
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (kyc?.success) {
      toast.success(kyc?.message);

      setTimeout(() => {
        dispatch(clearUpdateState());
        setEditable(false);
        dispatch(getKyc()); // GET UPDATED USER KYC DETAILS
      }, 3000);
    }
  }, [dispatch, error, kyc?.message, kyc?.success]);

  // UPDATE KYC DETAILS ENDS
  // UPDATE KYC DETAILS ENDS

  return (
    <Fragment>
      {kycLoading ? (
        <Loader />
      ) : (
        <DashLayout>
          <div className="kyc_content">
            <div className="kycStatus">
              <p>
                <span>KYC Status:</span>
                <span
                  className={`status ${
                    userKyc?.status === "Approved"
                      ? "green"
                      : userKyc?.status === "Pending"
                      ? "yellow"
                      : "red"
                  }`}
                >
                  {userKyc?.status}
                </span>
              </p>
              <CustomButton
                className={`editBtn ${editable ? "active" : ""}`}
                title="Edit"
                onClick={() => setEditable(!editable)}
                preIcon=""
                tooltip="Edit Kyc Details"
              />
            </div>
            {/* <p className="kycInstruction">
              KYC is not editable. Please fill your kyc details carefully.
            </p> */}
            <DashCard className="update_adhar" title={"Update Aadhar Card"}>
              <CustomInput
                icon=""
                label="Update Aadhar Card"
                type="number"
                placeholder="Your Aadhar Number"
                name="aadharCard"
                impStar="*"
                borderColor={editable ? "red" : "#f2f2f2"}
                value={editable ? kycDetails?.aadharCard : userKyc?.aadharCard}
                onChange={handleInputChange}
                readOnly={editable ? false : true}
              />
            </DashCard>
            {/* <DashCard className="update_pan" title={"Update PAN Card Details"}>
              <CustomInput
                icon=""
                label="PAN Number"
                type="text"
                placeholder="Your PAN Number"
                name="panCard"
                impStar="*"
                borderColor={editable ? "red" : "#f2f2f2"}
                value={editable ? kycDetails.panCard : userKyc?.panCard}
                onChange={handleInputChange}
                readOnly={editable ? false : true}
              />
            </DashCard> */}
            <DashCard
              className="update_bankPassbook"
              title={"Upload Bank Passbook"}
            >
              <CustomInput
                icon=""
                label="Account Holder Name"
                type="text"
                placeholder="Account Holder Name"
                name="accountHolder"
                impStar="*"
                borderColor={editable ? "red" : "#f2f2f2"}
                value={
                  editable
                    ? kycDetails?.accountHolder
                    : userKyc?.bankPassbook?.accountHolder
                }
                onChange={handleInputChange}
                readOnly={editable ? false : true}
              />
              <CustomInput
                icon=""
                label="Account Number"
                type="number"
                placeholder="Account Number"
                name="accountNo"
                impStar="*"
                borderColor={editable ? "red" : "#f2f2f2"}
                value={
                  editable
                    ? kycDetails?.accountNo
                    : userKyc?.bankPassbook?.accountNo
                }
                onChange={handleInputChange}
                readOnly={editable ? false : true}
              />
              <CustomInput
                icon=""
                label="Bank Name"
                type="text"
                placeholder="Bank Name"
                name="bankName"
                impStar="*"
                borderColor={editable ? "red" : "#f2f2f2"}
                value={
                  editable
                    ? kycDetails?.bankName
                    : userKyc?.bankPassbook?.bankName
                }
                onChange={handleInputChange}
                readOnly={editable ? false : true}
              />
              <CustomInput
                icon=""
                label="Bank Branch"
                type="text"
                placeholder="Bank Branch"
                name="bankBranch"
                impStar="*"
                borderColor={editable ? "red" : "#f2f2f2"}
                value={
                  editable
                    ? kycDetails?.bankBranch
                    : userKyc?.bankPassbook?.bankBranch
                }
                onChange={handleInputChange}
                readOnly={editable ? false : true}
              />
              <CustomInput
                icon=""
                label="IFSC Code"
                type="text"
                placeholder="IFSC Code"
                name="bankIfsc"
                impStar="*"
                borderColor={editable ? "red" : "#f2f2f2"}
                value={
                  editable
                    ? kycDetails?.bankIfsc
                    : userKyc?.bankPassbook?.bankIfsc
                }
                onChange={handleInputChange}
                readOnly={editable ? false : true}
              />
            </DashCard>

            {editable && (
              <CustomButton
                className="updateKyc_btn"
                title={loading ? <ButtonLoader /> : "Update KYC"}
                onClick={handleUpdateKyc}
                disabled={loading ? true : false}
                preIcon=""
                tooltip="Update KYC"
              />
            )}
          </div>
        </DashLayout>
      )}
    </Fragment>
  );
};

export default Kyc;
