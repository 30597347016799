import React, { Fragment } from "react";
import "../../assets/styles/companyTurnover.scss";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import usePaginate from "../../assets/hooks/usePaginate";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NotFoundCard from "../admin/components/NotFoundCard";

const CompanyTurnover = () => {
  const navigate = useNavigate();
  // USER DETAILS START
  const { userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;
  // USER DETAILS ENDS
  const dummyTeam = [];

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(dummyTeam);
  // Pagination Hook Ends
  return (
    <Fragment>
      <DashLayout>
        {user?.paidUser ? (
          <>
            {!dummyTeam?.length ? (
              <NotFoundCard
                text="Right now, You are not eligible for Company Turnover Income."
                showAction={true}
                actionTitle="Go to Dashboard"
                onClick={() => navigate("/user-dashboard")}
                actionIcon=""
              />
            ) : (
              <div className="company_turnover_wrapper">
                <DashCard
                  className="company_turnover"
                  title={"Company Turnover Income"}
                >
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    //   onChange=""
                    placeholder="Search..."
                    name="searchString"
                    //   value=""
                    //   impStar=""
                    //   readOnly=""
                    borderColor="#f2f2f2"
                  />
                  <div className="table_data custonScroll">
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <p>01/11/2023</p>
                              </td>

                              <td>
                                <p>₹50</p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination_sec">
                    <p className="entriesCount">
                      Showing {itemOffset + 1} to{" "}
                      {endOffset > dummyTeam?.length
                        ? dummyTeam?.length
                        : endOffset}{" "}
                      of {dummyTeam?.length} entries
                    </p>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<RxDoubleArrowRight />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<RxDoubleArrowLeft />}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </DashCard>
              </div>
            )}
          </>
        ) : (
          <NotFoundCard
            text="You have not any purchased plan yet, please purchage any plan to see your Company Turnover Income."
            showAction={true}
            actionTitle="Purchage Plan Now"
            onClick={() => navigate("/user-dashboard/activate-my-id")}
            actionIcon=""
          />
        )}
      </DashLayout>
    </Fragment>
  );
};

export default CompanyTurnover;
