import React from "react";
import "../assets/styles/footer.scss";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaYoutube,
  FaLinkedinIn,
  FaInstagram,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { logoMain } from "../assets/images/images";
import { useLocale } from "../context/locale";

const Footer = () => {
  const [t, chnageLang] = useLocale();

  return (
    <div className="footer_main">
      <div className="container">
        <div className="footer_content">
          <div className="address_contact">
            <img src={logoMain} alt="footer logo" />
            <p>{t("footerWithdrawText")}</p>
            <p className="location">
              <IoLocationSharp /> New Delhi, India
            </p>
            <Link to={`mailto:${process.env.REACT_APP_EMAIL}`}>
              <FaEnvelope /> {process.env.REACT_APP_EMAIL}
            </Link>
            <Link to={`tel:${process.env.REACT_APP_PHONE}`}>
              <FaPhoneAlt /> {process.env.REACT_APP_PHONE}
            </Link>
          </div>
          <div className="useful_links">
            <h2 className="section_heading">Useful Links</h2>
            <Link to="/about-us">About</Link>
            <Link to="/service">Services</Link>
            <Link to="#!">Faq</Link>
            <Link to="/plans">Plans</Link>
            <Link to="#!">Contact</Link>
          </div>
          <div className="help_links">
            <h2 className="section_heading">Help ?</h2>
            <Link to="/benefits">Benefits</Link>
            <Link to="#!">Faq</Link>
            <Link to="/contact-us">Contact</Link>
            <Link to="#!">Privacy</Link>
          </div>
          <div className="footer_about">
            <h2 className="section_heading">About</h2>
            <p>{t("aboutText")}</p>
            <div className="social_links">
              {/* <Link title="Facebook" to={process.env.REACT_APP_FACEBOOK}>
                <FaFacebookF />
              </Link>
              <Link title="Youtube" to={process.env.REACT_APP_YOUTUBE}>
                <FaYoutube />
              </Link>
              <Link title="Linkedin" to={process.env.REACT_APP_LINKEDIN}>
                <FaLinkedinIn />
              </Link> */}
              <Link title="Instagram" to={process.env.REACT_APP_INSTA}>
                <FaInstagram />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
