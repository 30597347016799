import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_ERRORS,
  CLEAR_STATE,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  SPONSOR_FAIL,
  SPONSOR_REQUEST,
  SPONSOR_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  CLEAR_UPDATE_STATE,
  UPDATE_AVATAR_REQUEST,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_AVATAR_FAIL,
  REMOVE_AVATAR_FAIL,
  REMOVE_AVATAR_SUCCESS,
  REMOVE_AVATAR_REQUEST,
  UPDATE_KYC_REQUEST,
  UPDATE_KYC_SUCCESS,
  UPDATE_KYC_FAIL,
  GET_KYC_REQUEST,
  GET_KYC_SUCCESS,
  GET_KYC_FAIL,
  CLEAR_REGISTER_STATE,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from "../constant/userConstant";

//   FOR USER REGISTRATION
export const userRegisterAction =
  (fullName, email, phone, gender, password, sponsorId, sponsorName) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      const { data } = await axiosInstance.post(
        `/register`,
        {
          fullName,
          email,
          phone,
          gender,
          password,
          sponsorId,
          sponsorName,
        },
        config
      );

      dispatch({
        type: REGISTER_SUCCESS,
        payload: data,
      });

      localStorage.setItem(
        "jwtToken",
        JSON.stringify(getState().register?.userInfo)
      );
    } catch (error) {
      dispatch({
        type: REGISTER_FAIL,
        payload: error.response.data.message,
      });
    }
  };

//   FOR USER REGISTRATION
export const userSponsorAction = (userName) => async (dispatch) => {
  try {
    dispatch({
      type: SPONSOR_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axiosInstance.get(`/sponsor/${userName}`, config);

    dispatch({
      type: SPONSOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SPONSOR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// USER LOGIN ACTION ==--==--==--==--==--==--==--
export const userLogin = (email, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axiosInstance.post(
      `/login`,
      {
        email,
        password,
      },
      config
    );

    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem(
      "jwtToken",
      JSON.stringify(getState().login?.userInfo)
    );
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// USER PROFILE ACTION ==--==--==--==--==--==--==--
export const userProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/profile`, config);

    dispatch({
      type: PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// USER LOGOUT
export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT_REQUEST,
    });

    localStorage.removeItem("jwtToken");

    dispatch({
      type: LOGOUT_SUCCESS,
      payload: null,
    });
  } catch (error) {
    alert(error);
  }
};

// UPDATE USER PROFILE ACTION
export const updateProfile =
  (
    fullName,
    email,
    phone,
    dob,
    gender,
    address,
    state,
    city,
    village,
    pinCode
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PROFILE_UPDATE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("jwtToken")
        ? JSON.parse(localStorage.getItem("jwtToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/update-profile`,
        {
          fullName,
          email,
          phone,
          dob,
          gender,
          address,
          state,
          city,
          village,
          pinCode,
        },
        config
      );

      dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFILE_UPDATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE USER PROFILE PICTURE
export const updateAvatarAction = (avatar) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_AVATAR_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      `/update-profile-pic`,
      { avatar },
      config
    );

    dispatch({
      type: UPDATE_AVATAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_AVATAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// REMOVE USER PROFILE PICTURE
export const removeAvatar = () => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_AVATAR_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/remove-profile-pic`, config);

    dispatch({
      type: REMOVE_AVATAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_AVATAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE USER KYC DETAILS
export const updateKyc =
  (aadharCard, accountHolder, accountNo, bankName, bankBranch, bankIfsc) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_KYC_REQUEST,
      });

      const localStorageToken = localStorage.getItem("jwtToken")
        ? JSON.parse(localStorage.getItem("jwtToken"))
        : "";

      const config = {
        headers: {
          "content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/update-kyc`,
        {
          aadharCard,
          accountHolder,
          accountNo,
          bankName,
          bankBranch,
          bankIfsc,
        },
        config
      );

      dispatch({
        type: UPDATE_KYC_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_KYC_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET USER KYC DETAILS
export const getKyc = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_KYC_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/user-kyc`, config);

    dispatch({
      type: GET_KYC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_KYC_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL USERS
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_USERS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/all-users`, config);

    dispatch({
      type: ALL_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_USERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL USERS
export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/user/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// FORGOT PASSWORD REQUEST
export const forgotPasswordReq = (email) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      `/fotgot-password`,
      { email },
      config
    );

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// FORGOT PASSWORD REQUEST
export const resetPasswordReq = (token, password) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      `/reset-password`,
      { token, password },
      config
    );

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};

// CLEAR REGISTER STATE ACTION FUNCTION
export const clearRegisterState = () => async (dispatch) => {
  dispatch({ type: CLEAR_REGISTER_STATE });
};

// CLEAR UPDATE STATE ACTION FUNCTION
export const clearUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_STATE });
};
