import React from "react";
import { styled } from "styled-components";

const Box = styled.div`
  display: flex;
  margin: 10px 0;
  animation-delay: 0.5s;

  > .box1,
  > .box2,
  > .box3 {
    width: 15px;
    height: 5px;
    border-radius: 15px;
    margin: 0 2px;
    background-color: ${(props) => props.color};
  }

  > .box1 {
    animation: rotater 1.5s 0s infinite linear;
  }
  > .box2 {
    animation: rotater 1.5s 0.5s infinite linear;
  }
  > .box3 {
    animation: rotater 1.5s 1s infinite linear;
  }

  @keyframes rotater {
    0% {
      width: 15px;
    }
    50% {
      width: 35px;
    }
    100% {
      width: 15px;
    }
  }
`;

const Bottom = ({ className, center, color = "#394b71" }) => {
  const centerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box
      className={`headingBottom ${className}`}
      style={center && centerStyle}
      color={color}
    >
      <div className="box1"></div>
      <div className="box2"></div>
      <div className="box3"></div>
    </Box>
  );
};

export default Bottom;
