import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/updatePlan.scss";
import { useParams } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { PiSubtitlesFill } from "react-icons/pi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FaPercent, FaRegFileImage, FaRupeeSign } from "react-icons/fa";
import CustomTextarea from "../../components/CustomTextarea";
import {
  MdCreateNewFolder,
  MdEditSquare,
  MdOutlineFeaturedVideo,
} from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import ButtonLoader from "../../components/ButtonLoader";
import {
  clearCreateUpdateState,
  clearErrors,
  getPlanDetails,
  updatePlan,
} from "../../redux/action/planAction";
import Loader from "../../components/Loader";

const UpdatePlan = () => {
  const { id: planId } = useParams();
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [features, setFeatures] = useState([]);
  const [feature, setFeature] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  // GET PLAN DETAILS START
  // GET PLAN DETAILS START
  const { loading, plan, error } = useSelector((state) => state.planDetails);

  const planDetails = plan?.packageDetail;

  // GET PLAN DETAILS ON PAGE LOAD
  useEffect(() => {
    if (planId) {
      dispatch(getPlanDetails(planId));
    }
  }, [planId, dispatch]);

  // CLEAR ERROR
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (plan?.success) {
      setFeatures(planDetails?.features);
      setThumbnail(planDetails?.thumbnail?.url);
    }
  }, [
    error,
    dispatch,
    planDetails?.features,
    plan?.success,
    planDetails?.thumbnail?.url,
  ]);
  // GET PLAN DETAILS ENDS
  // GET PLAN DETAILS ENDS

  const [planData, setPlanData] = useState({
    title: planDetails?.title,
    description: planDetails?.description,
    price: planDetails?.price,
    discount: planDetails?.discount,
  });

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;

    if (e.target.name === "thumbnail") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setThumbnail(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPlanData({
        ...planData,
        [name]: value,
      });
    }
  };

  // HANDLE FEATURE INPUT
  const handleFeatures = (e) => {
    setFeature(e.target.value);
  };

  // HANDLE ADD FEATURE IN LIST
  const handleAddFeature = () => {
    if (feature) {
      setFeatures([...features, feature]);
      setFeature("");
    } else {
      toast.warning("Please fill the feature!");
    }
  };

  // HANDLE REMOVE FEATURE
  const handleRemoveFeature = (index) => {
    const updatedFeatureList = features.filter((_, i) => i !== index);
    // Update the state with the new array
    setFeatures(updatedFeatureList);
  };

  // MEASURE DISCOUNTED PRICE ON THE BEHALF OF DISCOUNT PERCENTAGE
  const discountPercentage = editable
    ? planData.discount
    : planDetails?.discount;

  let discountedPrice = 0;
  if (planData.discount > 99) {
    toast.error("Can not add discount more the 99%");
    setPlanData({
      ...planData,
      discount: "",
    });
  } else if (discountPercentage > 0) {
    discountedPrice =
      planData.price - (planData.price * discountPercentage) / 100;
  }

  // Store all features in a variable
  const planFeatures = editable ? features : planDetails?.features;

  //   UPDATE PLAN START
  //   UPDATE PLAN START
  const {
    loading: updatedPlanLoading,
    plan: updatedPlan,
    error: updatedPlanError,
  } = useSelector((state) => state.updatePlan);

  // UPDATE PACKAGE HANDELER
  const handleUpdatePlan = () => {
    dispatch(
      updatePlan(
        planId,
        planData?.title,
        planData?.description,
        planData?.price,
        planData?.discount,
        features,
        thumbnail
      )
    );
  };

  useEffect(() => {
    if (updatedPlanError) {
      toast.error(updatedPlanError);
      dispatch(clearErrors());
    }

    if (updatedPlan?.success) {
      toast.success(updatedPlan?.message);
      setEditable(false);
      setTimeout(() => {
        dispatch(clearCreateUpdateState());
        dispatch(getPlanDetails(planId)); // GET UPDATED PLAN DETAILS
      }, 1000);
    }
  }, [
    updatedPlanError,
    dispatch,
    updatedPlan?.success,
    updatedPlan?.message,
    planId,
  ]);

  //   UPDATE PLAN ENDS
  //   UPDATE PLAN ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="createPlan_wrapper updatePlan_main">
            <div className="container">
              <div className="buttonFunction">
                <PageTitle goBack={true} title={`All Plans`} />
                <button
                  onClick={() => setEditable(!editable)}
                  title="Edit"
                  style={{
                    backgroundColor: editable ? "red" : "#dcca87",
                    color: editable ? "#dcca87" : "#394b71",
                  }}
                >
                  <MdEditSquare /> Edit
                </button>
              </div>

              {editable && (
                <p className="editTextSec">Now! You can edit this plan.</p>
              )}

              <div className="createPlan_content">
                <div className="inputSections">
                  <div className="first_sec">
                    <CustomInput
                      icon={<PiSubtitlesFill />}
                      label="Title"
                      type="text"
                      onChange={handleInputChange}
                      placeholder="Plan Title"
                      name="title"
                      value={editable ? planData.title : planDetails?.title}
                      impStar="*"
                      readOnly={!editable ? true : false}
                      borderColor={editable ? "#f2f2f2" : ""}
                    />
                    <CustomInput
                      icon={<FaRupeeSign />}
                      label="Price"
                      type="number"
                      onChange={handleInputChange}
                      placeholder="Plan Price"
                      name="price"
                      value={editable ? planData.price : planDetails?.price}
                      impStar="*"
                      readOnly={!editable ? true : false}
                      borderColor={editable ? "#f2f2f2" : ""}
                    />
                    <CustomInput
                      icon={<FaPercent />}
                      label="Discount Percentage (default discount will be zero)"
                      type="number"
                      onChange={handleInputChange}
                      placeholder="Discount Percentage"
                      name="discount"
                      value={
                        editable ? planData.discount : planDetails?.discount
                      }
                      impStar=""
                      readOnly={!editable ? true : false}
                      borderColor={editable ? "#f2f2f2" : ""}
                    />
                    {planDetails?.discount && (
                      <CustomInput
                        icon={<FaRupeeSign />}
                        label="Discounted Price"
                        type="number"
                        value={
                          editable
                            ? discountedPrice
                            : planDetails?.discountPrice
                        }
                        impStar=""
                        readOnly={true}
                        bacgroundColor={"grey"}
                      />
                    )}

                    <CustomTextarea
                      label="Description *"
                      rows="7"
                      type="text"
                      onChange={handleInputChange}
                      value={
                        editable
                          ? planData?.description
                          : planDetails?.description
                      }
                      placeholder="Plan Description"
                      name="description"
                      readOnly={!editable ? true : false}
                      borderColor={editable ? "#f2f2f2" : ""}
                    />
                  </div>
                  <div className="second_sec">
                    {editable && (
                      <div className="featureBox">
                        <CustomInput
                          icon={<MdOutlineFeaturedVideo />}
                          label="Features"
                          type="text"
                          placeholder="Add Plan Feature"
                          value={feature}
                          onChange={handleFeatures}
                          impStar="*"
                          readOnly={!editable ? true : false}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <button
                          className="addFeature"
                          onClick={handleAddFeature}
                        >
                          Add
                        </button>
                      </div>
                    )}
                    {planFeatures?.length > 0 && (
                      <div className="featureList">
                        <p className="title">Feature List</p>
                        <ol>
                          {planFeatures?.map((item, idx) => (
                            <li key={idx}>
                              {item}{" "}
                              {editable && (
                                <span
                                  className="removefromList"
                                  title="Remove feature from list"
                                  onClick={() => handleRemoveFeature(idx)}
                                >
                                  <IoMdClose />
                                </span>
                              )}
                            </li>
                          ))}
                        </ol>
                      </div>
                    )}

                    {editable && (
                      <CustomInput
                        icon={<FaRegFileImage />}
                        label="Upload Thumbnail"
                        type="file"
                        onChange={handleInputChange}
                        placeholder="Upload Thumbnail"
                        name="thumbnail"
                        impStar="*"
                        readOnly={!editable ? true : false}
                        borderColor={editable ? "#f2f2f2" : ""}
                        accept="image/*"
                      />
                    )}

                    {thumbnail && (
                      <div className="thumbnailSection">
                        <img src={thumbnail} alt="thumbnail" />
                      </div>
                    )}
                  </div>
                </div>
                {editable && (
                  <CustomButton
                    className={`createPlanBtn ${
                      updatedPlanLoading ? "disabled" : ""
                    }`}
                    title="Update"
                    onClick={handleUpdatePlan}
                    disabled={updatedPlanLoading ? true : false}
                    preIcon={
                      updatedPlanLoading ? (
                        <ButtonLoader />
                      ) : (
                        <MdCreateNewFolder />
                      )
                    }
                    tooltip="Update Plan"
                  />
                )}
              </div>
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default UpdatePlan;
