import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/createPlan.scss";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import { PiSubtitlesFill } from "react-icons/pi";
import { FaPercent, FaRegFileImage, FaRupeeSign } from "react-icons/fa";
import CustomTextarea from "../../components/CustomTextarea";
import { MdCreateNewFolder, MdOutlineFeaturedVideo } from "react-icons/md";
import ButtonLoader from "../../components/ButtonLoader";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCreateUpdateState,
  clearErrors,
  createPlan,
} from "../../redux/action/planAction";
import { useNavigate } from "react-router-dom";

const CreatePlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [features, setFeatures] = useState([]);
  const [feature, setFeature] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [planData, setPlanData] = useState({
    title: "",
    description: "",
    price: "",
    discount: "",
  });

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;

    if (e.target.name === "thumbnail") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setThumbnail(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPlanData({
        ...planData,
        [name]: value,
      });
    }
  };

  // HANDLE FEATURE INPUT
  const handleFeatures = (e) => {
    setFeature(e.target.value);
  };

  // HANDLE ADD FEATURE IN LIST
  const handleAddFeature = () => {
    if (feature) {
      setFeatures([...features, feature]);
      setFeature("");
    } else {
      toast.warning("Please fill the feature!");
    }
  };

  // HANDLE REMOVE FEATURE
  const handleRemoveFeature = (index) => {
    const updatedFeatureList = features.filter((_, i) => i !== index);
    // Update the state with the new array
    setFeatures(updatedFeatureList);
  };

  // MEASURE DISCOUNTED PRICE ON THE BEHALF OF DISCOUNT PERCENTAGE
  let discountedPrice = 0;
  if (planData.discount > 99) {
    toast.error("Can not add discount more the 99%");
    setPlanData({
      ...planData,
      discount: "",
    });
  } else if (planData.discount > 0) {
    discountedPrice =
      planData.price - (planData.price * planData.discount) / 100;
  }

  // CREATE PLAN START
  // CREATE PLAN START
  const { loading, plan, error } = useSelector((state) => state.createPlan);

  const handleCreatePlan = () => {
    if (
      !planData.title ||
      !planData.description ||
      !planData.price ||
      !features.length ||
      !thumbnail
    ) {
      toast.error("Please fill all details carefully!");
    } else {
      dispatch(
        createPlan(
          planData.title,
          planData.description,
          planData.price,
          planData.discount,
          features,
          thumbnail
        )
      );
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (plan?.success) {
      toast.success(plan?.message);
      dispatch(clearCreateUpdateState());
      navigate("/admin/all-plans");
    }
  }, [error, dispatch, plan?.success, plan?.message, navigate]);

  // CREATE PLAN ENDS
  // CREATE PLAN ENDS

  return (
    <Fragment>
      <AdminLayout>
        <div className="createPlan_wrapper">
          <div className="container">
            <PageTitle goBack={true} title={`Create Plans`} />

            <div className="createPlan_content">
              <div className="inputSections">
                <div className="first_sec">
                  <CustomInput
                    icon={<PiSubtitlesFill />}
                    label="Title"
                    type="text"
                    onChange={handleInputChange}
                    placeholder="Plan Title"
                    name="title"
                    value={planData.title}
                    impStar="*"
                    readOnly={false}
                    borderColor="#f2f2f2"
                  />
                  <CustomInput
                    icon={<FaRupeeSign />}
                    label="Price"
                    type="number"
                    onChange={handleInputChange}
                    placeholder="Plan Price"
                    name="price"
                    value={planData.price}
                    impStar="*"
                    readOnly={false}
                    borderColor="#f2f2f2"
                  />
                  <CustomInput
                    icon={<FaPercent />}
                    label="Discount Percentage (default discount will be zero)"
                    type="number"
                    onChange={handleInputChange}
                    placeholder="Discount Percentage"
                    name="discount"
                    value={planData.discount}
                    impStar=""
                    readOnly={false}
                    borderColor="#f2f2f2"
                  />
                  {planData.discount && (
                    <CustomInput
                      icon={<FaRupeeSign />}
                      label="Discounted Price"
                      type="number"
                      value={discountedPrice}
                      impStar=""
                      readOnly={true}
                      bacgroundColor={"grey"}
                    />
                  )}

                  <CustomTextarea
                    icon=""
                    label="Description *"
                    rows="7"
                    type="text"
                    onChange={handleInputChange}
                    placeholder="Plan Description"
                    name="description"
                    borderColor="#f2f2f2"
                  />
                </div>
                <div className="second_sec">
                  <div className="featureBox">
                    <CustomInput
                      icon={<MdOutlineFeaturedVideo />}
                      label="Features"
                      type="text"
                      placeholder="Add Plan Feature"
                      value={feature}
                      onChange={handleFeatures}
                      impStar="*"
                      readOnly={false}
                      borderColor="#f2f2f2"
                    />
                    <button className="addFeature" onClick={handleAddFeature}>
                      Add
                    </button>
                  </div>
                  {features.length > 0 && (
                    <div className="featureList">
                      <p className="title">Feature List</p>
                      <ol>
                        {features?.map((item, idx) => (
                          <li key={idx}>
                            {item}{" "}
                            <span
                              className="removefromList"
                              title="Remove feature from list"
                              onClick={() => handleRemoveFeature(idx)}
                            >
                              <IoMdClose />
                            </span>
                          </li>
                        ))}
                      </ol>
                    </div>
                  )}

                  <CustomInput
                    icon={<FaRegFileImage />}
                    label="Upload Thumbnail"
                    type="file"
                    onChange={handleInputChange}
                    placeholder="Upload Thumbnail"
                    name="thumbnail"
                    impStar="*"
                    readOnly={false}
                    borderColor="#f2f2f2"
                    accept="image/*"
                  />

                  {thumbnail && (
                    <div className="thumbnailSection">
                      <img src={thumbnail} alt="thumbnail" />
                    </div>
                  )}
                </div>
              </div>
              <CustomButton
                className={`createPlanBtn ${loading ? "disabled" : ""}`}
                title="Create"
                onClick={handleCreatePlan}
                disabled={loading ? true : false}
                preIcon={loading ? <ButtonLoader /> : <MdCreateNewFolder />}
                tooltip="Create Plan"
              />
            </div>
          </div>
        </div>
      </AdminLayout>
    </Fragment>
  );
};

export default CreatePlan;
