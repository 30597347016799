import React, { Fragment } from "react";
import Layout from "../components/Layout";
import AboutComponent from "../components/AboutComponent";
import BreadCrumb from "../components/BreadCrumb";
import { slider1 } from "../assets/images/images";
import CustomHelmet from "../components/Helmet";

const About = () => {
  return (
    <Fragment>
      <CustomHelmet
        title="About Us - Indian Big Bull"
        canonical="https://indianbigbull.in/"
      />
      <Layout>
        <BreadCrumb img={slider1} title="About Us" />
        <AboutComponent />
      </Layout>
    </Fragment>
  );
};

export default About;
