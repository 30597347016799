import React from "react";
import "../assets/styles/langChangeModal.scss";
import { AiOutlineClose } from "react-icons/ai";

const LangChangeModal = ({
  setLangModalOpener,
  langChanger,
  localStorageLang,
}) => {
    
  // CHANGE LANGUAGE BY PARAMS -- FUNCTION
  const changeLangByParam = (param) => {
    langChanger(param);
    setLangModalOpener(false);
  };

  return (
    <div className="langModal_wrapper">
      <div className="modal_content">
        <div className="headingSec">
          <h3>Choose a language</h3>
          <button
            title="Close Language Menu"
            onClick={() => setLangModalOpener(false)}
          >
            <AiOutlineClose />
          </button>
        </div>

        <div className="contentSec">
          <div
            className={`lanButton ${localStorageLang === "en" ? "active" : ""}`}
            onClick={() => changeLangByParam("en")}
          >
            English
          </div>
          <div
            className={`lanButton ${localStorageLang === "hi" ? "active" : ""}`}
            onClick={() => changeLangByParam("hi")}
          >
            हिंदी
          </div>
        </div>
      </div>
    </div>
  );
};

export default LangChangeModal;
