export const SEND_WITHDRAW_REQ_REQUEST = "SEND_WITHDRAW_REQ_REQUEST";
export const SEND_WITHDRAW_REQ_SUCCESS = "SEND_WITHDRAW_REQ_SUCCESS";
export const SEND_WITHDRAW_REQ_FAIL = "SEND_WITHDRAW_REQ_FAIL";

export const WITHDRAW_REQ_LIST_REQUEST = "WITHDRAW_REQ_LIST_REQUEST";
export const WITHDRAW_REQ_LIST_SUCCESS = "WITHDRAW_REQ_LIST_SUCCESS";
export const WITHDRAW_REQ_LIST_FAIL = "WITHDRAW_REQ_LIST_FAIL";

export const MY_WITHDRAW_REQ_LIST_REQUEST = "MY_WITHDRAW_REQ_LIST_REQUEST";
export const MY_WITHDRAW_REQ_LIST_SUCCESS = "MY_WITHDRAW_REQ_LIST_SUCCESS";
export const MY_WITHDRAW_REQ_LIST_FAIL = "MY_WITHDRAW_REQ_LIST_FAIL";

export const WITHDRAW_REQ_DETAILS_REQUEST = "WITHDRAW_REQ_DETAILS_REQUEST";
export const WITHDRAW_REQ_DETAILS_SUCCESS = "WITHDRAW_REQ_DETAILS_SUCCESS";
export const WITHDRAW_REQ_DETAILS_FAIL = "WITHDRAW_REQ_DETAILS_FAIL";

export const ACCEPT_WITHDRAW_REQ_REQUEST = "ACCEPT_WITHDRAW_REQ_REQUEST";
export const ACCEPT_WITHDRAW_REQ_SUCCESS = "ACCEPT_WITHDRAW_REQ_SUCCESS";
export const ACCEPT_WITHDRAW_REQ_FAIL = "ACCEPT_WITHDRAW_REQ_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_WITHDRAW_STATE = "CLEAR_WITHDRAW_STATE";
