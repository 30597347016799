import React, { Fragment } from "react";
import Layout from "../components/Layout";
import "../assets/styles/home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { slider1, slider2 } from "../assets/images/images";
import AboutComponent from "../components/AboutComponent";
import WhyUs from "../components/WhyUs";
import { FaBriefcase } from "react-icons/fa";
import { RiFocus2Line, Ri24HoursFill } from "react-icons/ri";
import { MdShoppingBasket } from "react-icons/md";
import ClientSays from "../components/ClientSays";
import OurStars from "../components/OurStars";
import LatestNews from "../components/LatestNews";
import SocialActivity from "../components/SocialActivity";
import { useLocale } from "../context/locale";
import CustomHelmet from "../components/Helmet";

const Home = () => {
  const [t, chnageLang] = useLocale();

  const heroSectionContent = [
    {
      id: 1,
      img: slider1,
      txt: t("welcomeText"),
      heading: t("growBusiness"),
    },
    {
      id: 2,
      img: slider2,
      txt: t("welcomeText"),
      heading: t("togetherAchieve"),
    },
  ];

  const whyChooseUs = [
    {
      id: 1,
      icon: <FaBriefcase />,
      text: "BELIEVE IN YOURSELF",
      heading: "BELIEVE IN YOURSELF",
    },
    {
      id: 2,
      icon: <RiFocus2Line />,
      text: "Shoot for the moon. Even if you miss, you'll land among the stars.",
      heading: "FOCUS",
    },
    {
      id: 3,
      icon: <MdShoppingBasket />,
      text: "DREAM ENORMOUSLY BIG",
      heading: "DREAM ENORMOUSLY BIG",
    },
    {
      id: 4,
      icon: <Ri24HoursFill />,
      text: "ACHIEVE WHAT THEY THINK THEY CAN DO",
      heading: "ACHIEVE",
    },
  ];

  return (
    <Fragment>
      <CustomHelmet canonical="https://indianbigbull.in/" />
      <Layout>
        {/* HOME HERO SLIDER START */}
        <div className="hero_slider_main">
          <Swiper
            className="mySwiper"
            navigation={true}
            effect={"fade"}
            modules={[Navigation, EffectFade, Autoplay]}
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
          >
            {heroSectionContent?.map((item, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div
                    className="hero_img_sec"
                    style={{ backgroundImage: `url(${item?.img})` }}
                  >
                    <div className="container">
                      <div className="content">
                        <p>{item?.txt}</p>
                        <h1>{item?.heading}</h1>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        {/* HOME HERO SLIDER ENDS */}

        {/* HOME ABOUT COMPONENT START */}
        <AboutComponent />
        {/* HOME ABOUT COMPONENT ENDS */}

        {/* WHY US COMPONENT START */}
        <WhyUs content={whyChooseUs} />
        {/* WHY US COMPONENT ENDS */}

        {/* CLIENT SAYS COMPONENT START */}
        <ClientSays />
        {/* CLIENT SAYS COMPONENT ENDS */}

        {/* OUR STAR COMPONENT START */}
        {/* <OurStars /> */}
        {/* OUR STAR COMPONENT ENDS */}

        {/* SOCIAL ACTIVITIES COMPONENT START */}
        {/* <SocialActivity /> */}
        {/* SOCIAL ACTIVITIES COMPONENT ENDS */}

        {/* LATEST NEWS COMPONENT START */}
        {/* <LatestNews /> */}
        {/* LATEST NEWS COMPONENT ENDS */}
      </Layout>
    </Fragment>
  );
};

export default Home;
