import React, { Fragment, useEffect, useState } from "react";
import "../assets/styles/login.scss";
import { logoMain } from "../assets/images/images";
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import { FiLogIn } from "react-icons/fi";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, userLogin } from "../redux/action/userAction";
import { toast } from "react-toastify";
import ButtonLoader from "../components/ButtonLoader";
import { useAuth } from "../context/userContext";
import CustomHelmet from "../components/Helmet";

const Login = () => {
  const [showPassword, setshowPassword] = useState(false);
  const dispatch = useDispatch();
  const [auth] = useAuth();

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  // DESTRUCTURE DATA
  const { email, password } = loginData;

  // const location = useLocation();
  const navigate = useNavigate();

  // const redirect = location.search
  //   ? location.search.split("=")[1]
  //   : "/student/profile";
  // let redirect = location.state ? location.state : "/user-dashboard";

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  // LOGIN PARAMS
  const { loading, userInfo, error } = useSelector((state) => state.login);

  // LOGIN HANDLER FUNCTION
  const handleLogin = () => {
    if (!email || !password) {
      toast.warning("Please fill Email & Password carefully!");
    } else {
      dispatch(userLogin(email, password));
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (userInfo?.success) {
      toast.success(userInfo?.message);
      navigate("/user-dashboard");
    }
  }, [
    error,
    dispatch,
    userInfo?.success,
    userInfo?.message,
    navigate,
    auth?.user?.role,
  ]);

  return (
    <Fragment>
      <CustomHelmet
        title="Login - Indian Big Bull"
        canonical="https://indianbigbull.in/"
      />
    <div className="loginPage_main">
      <div className="container">
        <PageTitle title="Login" />
        <div className="loginContent">
          <div className="logoSec">
            <Link to="/" title="Click here to go to Homepage">
              <img src={logoMain} alt="logoMain" />
            </Link>
          </div>
          <div className="formSec">
            <p className="loginInst">
              Please Enter your Username and Password to Sign in.
            </p>
            <CustomInput
              icon=""
              type="email"
              placeholder="Enter Mail Id or Uniqie Id"
              name="email"
              borderColor="#aaa"
              value={email}
              onChange={handleInputChange}
            />
            <CustomInput
              icon=""
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              name="password"
              eyeIcon={showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              setshowPassword={setshowPassword}
              borderColor="#aaa"
              value={password}
              onChange={handleInputChange}
            />
            <CustomButton
              // className={`loginBtn ${loading ? "disabled" : ""}`}
              title={loading ? <ButtonLoader /> : "Login"}
              onClick={handleLogin}
              icon={<FiLogIn />}
              // disabled={loading ? true : false}
              tooltip="Click here to login"
            />
            <div className="registerContent">
              <Link to="/forgot-password">Forgot Password</Link>
              <p className="register">
                Don’t have an account? <Link to="/register">Register</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Fragment>

  );
};

export default Login;
