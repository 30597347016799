import {
  CLEAR_ERRORS,
  CLEAR_STATE,
  DIRECT_TEAM_FAIL,
  DIRECT_TEAM_REQUEST,
  DIRECT_TEAM_SUCCESS,
  LEVEL_TEAM_FAIL,
  LEVEL_TEAM_REQUEST,
  LEVEL_TEAM_SUCCESS,
} from "../constant/teamConstant";

// GET DIRECT TEAM
export const directTeamReducer = (state = {}, action) => {
  switch (action.type) {
    case DIRECT_TEAM_REQUEST:
      return {
        loading: true,
        team: {},
      };
    case DIRECT_TEAM_SUCCESS:
      return {
        loading: false,
        team: action.payload,
      };
    case DIRECT_TEAM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        team: {},
      };

    default:
      return state;
  }
};

// GET LEVEL TEAM
export const levelTeamReducer = (state = {}, action) => {
  switch (action.type) {
    case LEVEL_TEAM_REQUEST:
      return {
        loading: true,
        team: {},
      };
    case LEVEL_TEAM_SUCCESS:
      return {
        loading: false,
        team: action.payload,
      };
    case LEVEL_TEAM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        team: {},
      };

    default:
      return state;
  }
};
