import {
  ALL_WALLETS_FAIL,
  ALL_WALLETS_REQUEST,
  ALL_WALLETS_SUCCESS,
  CLEAR_WALLET_ERRORS,
  CLEAR_WALLET_MESSAGE,
  CLEAR_WALLET_STATE,
  MY_WALLET_FAIL,
  MY_WALLET_REQUEST,
  MY_WALLET_SUCCESS,
  UPDATE_WALLET_FAIL,
  UPDATE_WALLET_REQUEST,
  UPDATE_WALLET_SUCCESS,
  WALLET_DETAILS_FAIL,
  WALLET_DETAILS_REQUEST,
  WALLET_DETAILS_SUCCESS,
} from "../constant/walletConstant";

// GET MY WALLET DETAILS -
export const getMyWalletReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_WALLET_REQUEST:
      return {
        loading: true,
        wallet: {},
      };
    case MY_WALLET_SUCCESS:
      return {
        loading: false,
        wallet: action.payload,
        message: action.payload.message,
      };
    case MY_WALLET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_WALLET_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_WALLET_STATE:
      return {
        loading: false,
        wallet: {},
      };
    case CLEAR_WALLET_MESSAGE:
      return {
        ...state,
        loading: false,
        message: "",
      };

    default:
      return state;
  }
};

// GET ALL WALLETS LIST - ADMIN
export const getAllWalletsReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_WALLETS_REQUEST:
      return {
        loading: true,
        wallets: {},
      };
    case ALL_WALLETS_SUCCESS:
      return {
        loading: false,
        wallets: action.payload,
        message: action.payload.message,
      };
    case ALL_WALLETS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_WALLET_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_WALLET_STATE:
      return {
        loading: false,
        wallets: {},
      };
    case CLEAR_WALLET_MESSAGE:
      return {
        ...state,
        loading: false,
        message: "",
      };

    default:
      return state;
  }
};

// GET WALLET DEAILS - ADMIN
export const getWalletDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case WALLET_DETAILS_REQUEST:
      return {
        loading: true,
        wallet: {},
      };
    case WALLET_DETAILS_SUCCESS:
      return {
        loading: false,
        wallet: action.payload,
        message: action.payload.message,
      };
    case WALLET_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_WALLET_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_WALLET_STATE:
      return {
        loading: false,
        wallet: {},
      };
    case CLEAR_WALLET_MESSAGE:
      return {
        ...state,
        loading: false,
        message: "",
      };

    default:
      return state;
  }
};

// UPDATE WALLET DEAIL - ADMIN
export const updateWalletReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_WALLET_REQUEST:
      return {
        loading: true,
        wallet: {},
      };
    case UPDATE_WALLET_SUCCESS:
      return {
        loading: false,
        wallet: action.payload,
        message: action.payload.message,
      };
    case UPDATE_WALLET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_WALLET_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_WALLET_STATE:
      return {
        loading: false,
        wallet: {},
      };
    case CLEAR_WALLET_MESSAGE:
      return {
        ...state,
        loading: false,
        message: "",
      };

    default:
      return state;
  }
};
