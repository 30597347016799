import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer,
  userRegister,
  userSponsorReducer,
  userProfileReducer,
  updateProfileReducer,
  updateProfilePicReducer,
  removeProfilePic,
  updateUserKycReducer,
  getUserKycReducer,
  getAllUsersReducer,
  getUserDetailsReducer,
  forgotPasswordRequestReducer,
  resetPasswordRequestReducer,
} from "./reducer/userReducer";
import { directTeamReducer, levelTeamReducer } from "./reducer/teamReducer";
import {
  addFundReducer,
  getAddFundDetailsReducer,
  getAllFundRequestsReducer,
  getDepositHistoryReducer,
  updateAddFundRequestReducer,
} from "./reducer/fundReducer";
import {
  getAllWalletsReducer,
  getMyWalletReducer,
  getWalletDetailsReducer,
  updateWalletReducer,
} from "./reducer/walletReducer";
import {
  getAllKycListReducer,
  getKycDetailsReducer,
  updateKycReducer,
} from "./reducer/kycReducer";
import {
  createPlanReducer,
  deletePlanReducer,
  getPlanListReducer,
  myPurchasedPlanReducer,
  planDetailsReducer,
  purchasePlanReducer,
  purchasedPlanListReducer,
  updatePlanReducer,
} from "./reducer/planReducer";
import { allStatsReducer } from "./reducer/statsReducer";
import {
  acceptWithdrawReqReducer,
  getAllWithdrawReqReducer,
  getMyWithdrawReqReducer,
  getWithdrawReqDetailsReducer,
  sendWithdrawReqReducer,
} from "./reducer/withdrawalReqReducer";
import {
  getBarcodeReducer,
  updateBarcodeReducer,
} from "./reducer/barcodeReducer";

// get user from localStorage
const userFromLocalStorage = localStorage.getItem("jwtToken")
  ? JSON.parse(localStorage.getItem("jwtToken"))
  : {};

// COMBINE ALL REDUCERS
const reducer = combineReducers({
  register: userRegister,
  sponsorUser: userSponsorReducer,
  login: userLoginReducer,
  profile: userProfileReducer,
  updateProfile: updateProfileReducer,
  updateAvatar: updateProfilePicReducer,
  removeAvatar: removeProfilePic,
  updateUserKyc: updateUserKycReducer,
  getUserKyc: getUserKycReducer,
  directTeam: directTeamReducer,
  addFund: addFundReducer,
  depositHistory: getDepositHistoryReducer,
  allUsers: getAllUsersReducer, // ADMIN
  userDetails: getUserDetailsReducer, // ADMIN
  allFundAddRequests: getAllFundRequestsReducer, // ADMIN
  addFundDetails: getAddFundDetailsReducer, // ADMIN
  updateAddFundRequest: updateAddFundRequestReducer, // ADMIN
  myWallet: getMyWalletReducer,
  allWallets: getAllWalletsReducer, // ADMIN
  walletDetails: getWalletDetailsReducer, // ADMIN
  updateWallet: updateWalletReducer, // ADMIN
  allKycList: getAllKycListReducer, // ADMIN
  updateKyc: updateKycReducer, // ADMIN
  kycDetails: getKycDetailsReducer, // ADMIN
  getPlanList: getPlanListReducer,
  createPlan: createPlanReducer, // ADMIN
  allStats: allStatsReducer, // ADMIN
  updatePlan: updatePlanReducer, // ADMIN
  deletePlan: deletePlanReducer, // ADMIN
  planDetails: planDetailsReducer,
  purchasePlan: purchasePlanReducer,
  purchasedPlanList: purchasedPlanListReducer, // ADMIN
  myPurchasedPlan: myPurchasedPlanReducer,
  sendWithdrawReq: sendWithdrawReqReducer,
  getAllWithdrawReq: getAllWithdrawReqReducer, // ADMIN
  getWithdrawReqDetails: getWithdrawReqDetailsReducer, // ADMIN
  acceptWithdrawReq: acceptWithdrawReqReducer, // ADMIN
  getMyWithdrawReq: getMyWithdrawReqReducer,
  levelTeam: levelTeamReducer,
  updateBarcode: updateBarcodeReducer, // ADMIN
  getBarcode: getBarcodeReducer,
  forgotPasswordRequest: forgotPasswordRequestReducer,
  resetPasswordRequest: resetPasswordRequestReducer,
});

// INITIAL STATE
const initialState = {
  login: {
    userInfo: userFromLocalStorage,
  },
  register: {
    userInfo: userFromLocalStorage,
  },
};

// MIDDLEWARE // THUNK
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
