import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const NotFoundPage = () => {
  const [errors, setErrors] = useState();
  const [myData, setMyData] = useState();

  useEffect(() => {
    const options = {
      headers: {
        accept: "application/json",
        AccessKey: "85d4c05f-1879-4c3a-83cf676b8410-522d-4284",
      },
    };

    async function fetchData() {
      const data = await axios.get(
        `https://video.bunnycdn.com/library/165090/videos`,
        options
      );
      try {
        console.log("data", data?.data);
        setMyData(data?.data);
      } catch (error) {
        setErrors(error);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      {JSON.stringify(errors)}
      {JSON.stringify(myData)}

      {myData?.items?.map((item) => {
        return (
          <div
            style={{
              position: "relative",
              width: "300px",
              height: "300px",
            }}
            key={item?.guid}
          >
            <iframe
              title={item?.title}
              src={`https://iframe.mediadelivery.net/embed/165090/${item?.guid}?autoplay=false&loop=false&muted=false&preload=false`}
              loading="lazy"
              style={{
                border: 0,
                position: "absolute",
                top: "0",
                width: "300px",
                height: "300px",
              }}
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowFullScreen={true}
            ></iframe>
          </div>
        );
      })}

      <Link to="/">Home</Link>
    </div>
  );
};

export default NotFoundPage;
