import React from "react";
import "../assets/styles/aboutComponent.scss";
import { aboutImg } from "../assets/images/images";
import Bottom from "./Bottom";
import { useLocale } from "../context/locale";

const AboutComponent = () => {
  const [t, chnageLang] = useLocale();
  return (
    <div className="about_component_main">
      <div className="container">
        <div className="content">
          <div className="imgSec">
            <img src={aboutImg} alt="aboutImg" />
          </div>
          <div className="txtSec">
            <div className="title">
              <p>{t("welcomeText")}</p>
              <h2>
                {t("aboutConcept")}
                <span>{t("bestIBB")}</span>
              </h2>
              <Bottom color="#dcca87" />
            </div>
            <p>{t("aboutText")}</p>

            <ul>
              <li>{t("aboutText1")}</li>
              <li>{t("aboutText2")}</li>
              <li>{t("aboutText3")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
