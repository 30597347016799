import {
  ALL_USERS_FAIL,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_REGISTER_STATE,
  CLEAR_STATE,
  CLEAR_UPDATE_STATE,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  GET_KYC_FAIL,
  GET_KYC_REQUEST,
  GET_KYC_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  PROFILE_FAIL,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REMOVE_AVATAR_FAIL,
  REMOVE_AVATAR_REQUEST,
  REMOVE_AVATAR_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  SPONSOR_FAIL,
  SPONSOR_REQUEST,
  SPONSOR_SUCCESS,
  UPDATE_AVATAR_FAIL,
  UPDATE_AVATAR_REQUEST,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_KYC_FAIL,
  UPDATE_KYC_REQUEST,
  UPDATE_KYC_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
} from "../constant/userConstant";

// FOR USER REGISTRATION
export const userRegister = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        loading: true,
        userInfo: {},
      };
    case REGISTER_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case REGISTER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_REGISTER_STATE:
      return {
        loading: false,
        userInfo: {},
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// FOR USER REGISTRATION
export const userSponsorReducer = (state = {}, action) => {
  switch (action.type) {
    case SPONSOR_REQUEST:
      return {
        loading: true,
        sponsor: {},
      };
    case SPONSOR_SUCCESS:
      return {
        loading: false,
        sponsor: action.payload,
      };
    case SPONSOR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        sponsor: {},
      };

    default:
      return state;
  }
};

// USER LOGIN REDUCER
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };

    case LOGIN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };

    case LOGIN_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        isAuthenticated: false,
        error: null,
        userInfo: null,
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// USER PROFILE REDUCER
export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };

    case PROFILE_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };

    case PROFILE_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        isAuthenticated: false,
        error: null,
        userInfo: null,
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// UPDATE USER PROFILE ======
export const updateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case PROFILE_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        loading: false,
        userInfo: null,
      };

    default:
      return state;
  }
};

// UPDATE USER PROFILE ======
export const updateProfilePicReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AVATAR_REQUEST:
      return {
        loading: true,
        avatarInfo: {},
      };
    case UPDATE_AVATAR_SUCCESS:
      return {
        loading: false,
        avatarInfo: action.payload,
      };
    case UPDATE_AVATAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_STATE:
      return {
        avatarInfo: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// REMOVE PROFILE PICTURE
export const removeProfilePic = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_AVATAR_REQUEST:
      return {
        loading: true,
        avatarInfo: {},
      };

    case REMOVE_AVATAR_SUCCESS:
      return {
        loading: false,
        avatarInfo: action.payload,
      };

    case REMOVE_AVATAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        avatarInfo: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE USER KYC
export const updateUserKycReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_KYC_REQUEST:
      return {
        loading: true,
        kyc: {},
      };

    case UPDATE_KYC_SUCCESS:
      return {
        loading: false,
        kyc: action.payload,
      };

    case UPDATE_KYC_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        kyc: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET USER KYC DETAILS
export const getUserKycReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_KYC_REQUEST:
      return {
        loading: true,
        kyc: {},
      };

    case GET_KYC_SUCCESS:
      return {
        loading: false,
        kyc: action.payload,
      };

    case GET_KYC_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_STATE:
      return {
        kyc: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL USERS === ADMIN
export const getAllUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_USERS_REQUEST:
      return {
        loading: true,
        users: {},
      };

    case ALL_USERS_SUCCESS:
      return {
        loading: false,
        users: action.payload,
      };

    case ALL_USERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_STATE:
      return {
        users: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET USER DETAILS
export const getUserDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return {
        loading: true,
        user: {},
      };

    case USER_DETAILS_SUCCESS:
      return {
        loading: false,
        user: action.payload,
      };

    case USER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_STATE:
      return {
        user: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// FORGOT PASSWORD REQUEST
export const forgotPasswordRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return {
        loading: true,
        request: {},
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        loading: false,
        request: action.payload,
      };

    case FORGOT_PASSWORD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        request: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// RESET PASSWORD REQUEST
export const resetPasswordRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        loading: true,
        request: {},
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        loading: false,
        request: action.payload,
      };

    case RESET_PASSWORD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        request: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
