import React, { useState } from "react";
import "../assets/styles/header.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { logoMain } from "../assets/images/images";
import { useLocale } from "../context/locale";
import LangChangeModal from "./LangChangeModal";
import { BsGlobe } from "react-icons/bs";

const Header = () => {
  const [openToggler, setOpenToggler] = useState(false);

  const [langModal, setLangModal] = useState(false);
  const [t, changeLang] = useLocale();

  const localStorageLang = localStorage.getItem("lang");

  const handleLangChanger = (lang) => {
    changeLang(lang);
  };

  return (
    <div className="header_main">
      <div className="container">
        <div className="nav_bar_sec">
          <div className="logo">
            <Link to="/">
              <img
                className="desktop"
                src={logoMain}
                alt="Alpha Regiment desktop_logo"
              />
              <img
                className="mobile"
                src={logoMain}
                alt="Alpha Regiment mobile_logo"
              />
            </Link>
          </div>
          <div className="open_navbar_btn">
            <BsGlobe
              className="changLangIcon"
              style={{ marginRight: "25px" }}
              onClick={() => setLangModal(true)}
            />
            <GiHamburgerMenu
              className="openMenuIcon"
              onClick={() => setOpenToggler(!openToggler)}
            />
          </div>
          <div className={`nav_links ${openToggler ? "open_navbar" : ""}`}>
            <div className="close_menu_btn">
              <AiOutlineClose onClick={() => setOpenToggler(!openToggler)} />
            </div>

            {/* {auth?.user?._id && (
              <div className="user_details_nav_link_mobile">
                <div className="userDetails">
                  <div className="profileImg">
                    <img
                      src={
                        auth?.user?.profile?.url
                          ? auth?.user?.profile?.url
                          : defaultUser
                      }
                      alt={auth?.user?.first_name}
                    />
                  </div>
                  <div className="userName">{auth?.user?.first_name}</div>
                </div>
                <div className="auth_user_links">
                  <Link
                    to={
                      auth?.user?.role === "15"
                        ? "/affiliate/profile"
                        : auth?.user?.role === "16"
                        ? "/officer/profile"
                        : "/student/profile"
                    }
                  >
                    Profile
                  </Link>
                  <CustomButton title="Log Out" onClick={handleLogout} />
                </div>
              </div>
            )} */}

            <div className="nav_link">
              <NavLink to="/">Home</NavLink>
            </div>
            <div className="nav_link">
              <NavLink to="/about-us">About</NavLink>
            </div>
            <div className="nav_link">
              <NavLink to="/service">Service</NavLink>
            </div>
            <div className="nav_link">
              {/* <NavLink to="/social-activities">Social Activities</NavLink> */}
              <Link to="#!">Social Activities</Link>
            </div>
            <div className="nav_link">
              <NavLink to="/plans">Plan</NavLink>
            </div>
            <div className="nav_link">
              {/* <NavLink to="/benefits">Benefits</NavLink> */}
              <Link to="#!">Benefits</Link>
            </div>
            <div className="nav_link">
              <NavLink to="/contact-us">Contact</NavLink>
            </div>
            <div className="nav_link langChange">
              <BsGlobe
                title="Change your default language"
                className="changLangIcon"
                onClick={() => setLangModal(true)}
              />
            </div>
            {/* <div className="nav_link">
              <button className="change_lang">
                <CiGlobe className="icon" />
                Lang
              </button>
            </div> */}
            {/* <div className="nav_link dropdown_nav">
              {user ? (
                <div className="auth_profile_dropdown">
                  <button
                    className={`sign_up_btn dashboard_btn ${
                      "" ? "profile_header_btn" : ""
                    }`}
                    onClick={() => setUserDropdown(!userDropdown)}
                  >
                    <img src={""} alt={""} />
                    Pankaj Yadav
                    {userDropdown ? (
                      <BsFillCaretUpFill />
                    ) : (
                      <BsFillCaretDownFill />
                    )}
                  </button>
                  <div
                    className={`nav_dropdown_links ${
                      userDropdown ? "show_nav_dropdown" : ""
                    }`}
                  >
                    <Link to={""}>Profile</Link>
                    <button className="logout_btn" onClick={handleLogout}>
                      logout
                    </button>
                  </div>
                </div>
              ) : (
                <button className="sign_up_btn" onClick={handleSignUpPage}>
                  Sign In
                </button>
              )}
            </div> */}
          </div>
        </div>
      </div>

      {/* LANGUAGE CHANGE MODAL START */}
      {langModal && (
        <LangChangeModal
          setLangModalOpener={setLangModal}
          langChanger={handleLangChanger}
          localStorageLang={localStorageLang}
        />
      )}

      {/* LANGUAGE CHANGE MODAL ENDS */}
    </div>
  );
};

export default Header;
