import React from "react";
import styled from "styled-components";
import CustomButton from "../../../components/CustomButton";

const Card = styled.div`
  width: -webkit-fill-available;
  border-radius: 14px;
  padding: 30px 15px;
  color: #2b74c1;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  margin: 30px 0;
  background: rgb(220 202 135 / 51%);

  @media screen and (max-width: 575px) {
    font-size: 18px;
  }

  > div.text {
    text-shadow: 2px 1px 0px BLACK;
    letter-spacing: 1px;
    color: #fff;
  }

  > .actionBtn {
    margin: 20px auto 0;
    width: fit-content;
    padding: 6px 15px;
  }
`;

const NotFoundCard = ({
  text,
  showAction,
  actionTitle,
  onClick,
  actionIcon,
  txtColor,
}) => {
  return (
    <Card>
      <div className="text" style={{ color: txtColor && txtColor }}>
        {text}
      </div>
      {showAction && (
        <CustomButton
          className="actionBtn"
          title={actionTitle}
          onClick={onClick}
          preIcon={actionIcon}
          tooltip={actionTitle}
        />
      )}
    </Card>
  );
};

export default NotFoundCard;
