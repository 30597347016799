import React from "react";
import "../../../assets/styles/sidebar.scss";
import { logoMain } from "../../../assets/images/images";
import { Link, useNavigate } from "react-router-dom";
import CustomDropdown from "./CustomDropdown";
import { RiProfileFill } from "react-icons/ri";
import { BiNetworkChart, BiSolidUserPlus } from "react-icons/bi";
import { BsListStars, BsCurrencyRupee } from "react-icons/bs";
import {
  AiFillDashboard,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/action/userAction";

const Sidebar = ({ openSidebar, setOpenSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // HANDLE LOG_OUT FUNCTION
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div
      className="sidebar_main custonScroll"
      style={{ left: !openSidebar && "-100%" }}
    >
      <div
        className="closeBtn_sidebar mobileView"
        onClick={() => setOpenSidebar(!openSidebar)}
        title={`${openSidebar ? "Close" : "Open"} list of links`}
      >
        {openSidebar ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />}
      </div>
      <div className="sidebarContent">
        <div className="sidebarIcon">
          <Link to="/" title="Go to homepage">
            <img src={logoMain} alt="logoMain" width={200} />
          </Link>
        </div>
        <div className="allLinks">
          <Link to="/user-dashboard">
            <AiFillDashboard /> Dashboard
          </Link>

          <CustomDropdown
            title="Profile"
            icon={<RiProfileFill />}
            links={[
              { link: "/user-dashboard/profile", name: "View/Edit" },
              { link: "/user-dashboard/kyc", name: "KYC Verification" },
            ]}
          />
          <CustomDropdown
            title="Network"
            icon={<BiNetworkChart />}
            links={[
              { link: "/user-dashboard/direct-team", name: "Direct Team" },
              { link: "/user-dashboard/team-list", name: "Team List" },
              { link: "/user-dashboard/team-tree", name: "My Tree" },
            ]}
          />
          <CustomDropdown
            title="Activation & Purchase"
            icon={<BiSolidUserPlus />}
            links={[
              // {
              //   link: "/register",
              //   state: { sponsorId: auth?.user?.username },
              //   name: "Add New Registration",
              // },
              {
                link: "/user-dashboard/activate-my-id",
                name: "Activate My ID",
              },
              // {
              //   link: "/user-dashboard/activate-downline-id",
              //   name: "Activate Downline ID",
              // },
            ]}
          />
          <CustomDropdown
            title="Transactions"
            icon={<BsListStars />}
            links={[
              { link: "/user-dashboard/add-fund", name: "Add Funds" },
              {
                link: "/user-dashboard/withdrawal-request",
                name: "Withdrawal Request",
              },
              {
                link: "/user-dashboard/deposite-history",
                name: "Deposit History",
              },
              {
                link: "/user-dashboard/withdrawal-history",
                name: "Withdrawal History",
              },
              {
                link: "/user-dashboard/transaction-history",
                name: "Transaction Summary",
              },
            ]}
          />
          <CustomDropdown
            title="My Income"
            icon={<BsCurrencyRupee />}
            links={[
              // {
              //   link: "/user-dashboard/auto-pool-income",
              //   name: "Auto Pool Income",
              // },
              {
                link: "/user-dashboard/direct-sponsor-income",
                name: "Direct Sponsor Income",
              },
              // { link: "/user-dashboard/level-income", name: "level Income" },
              {
                link: "/user-dashboard/turnover-income",
                name: "Company Turnover Income",
              },
              { link: "/user-dashboard/reward-income", name: "Reward Income" },
            ]}
          />

          <Link to="/" onClick={handleLogout}>
            <FiLogOut /> Logout
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
