import {
  BUY_PLAN_FAIL,
  BUY_PLAN_REQUEST,
  BUY_PLAN_SUCCESS,
  CLEAR_CREATE_UPDATE_STATE,
  CLEAR_ERRORS,
  CREATE_PLAN_ADMIN_FAIL,
  CREATE_PLAN_ADMIN_REQUEST,
  CREATE_PLAN_ADMIN_SUCCESS,
  DELETE_PLAN_ADMIN_FAIL,
  DELETE_PLAN_ADMIN_REQUEST,
  DELETE_PLAN_ADMIN_SUCCESS,
  MY_PURCHASED_PLAN_FAIL,
  MY_PURCHASED_PLAN_REQUEST,
  MY_PURCHASED_PLAN_SUCCESS,
  PLAN_DETAILS_FAIL,
  PLAN_DETAILS_REQUEST,
  PLAN_DETAILS_SUCCESS,
  PLAN_LIST_FAIL,
  PLAN_LIST_REQUEST,
  PLAN_LIST_SUCCESS,
  PURCHAGE_LIST_FAIL,
  PURCHAGE_LIST_REQUEST,
  PURCHAGE_LIST_SUCCESS,
  UPDATE_PLAN_ADMIN_FAIL,
  UPDATE_PLAN_ADMIN_REQUEST,
  UPDATE_PLAN_ADMIN_SUCCESS,
} from "../constant/planConstant";

// GET PLAN LIST REDUCER
export const getPlanListReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAN_LIST_REQUEST:
      return {
        loading: true,
        plans: {},
      };
    case PLAN_LIST_SUCCESS:
      return {
        loading: false,
        plans: action.payload,
      };
    case PLAN_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// CREATE PLAN REDUCER -- ADMIN
export const createPlanReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PLAN_ADMIN_REQUEST:
      return {
        loading: true,
        plan: {},
      };
    case CREATE_PLAN_ADMIN_SUCCESS:
      return {
        loading: false,
        plan: action.payload,
      };
    case CREATE_PLAN_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_CREATE_UPDATE_STATE:
      return {
        loading: false,
        error: null,
        plan: {},
      };

    default:
      return state;
  }
};

//  PLAN DETAILS REDUCER
export const planDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAN_DETAILS_REQUEST:
      return {
        loading: true,
        plan: {},
      };
    case PLAN_DETAILS_SUCCESS:
      return {
        loading: false,
        plan: action.payload,
      };
    case PLAN_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE PLAN REDUCER -- ADMIN
export const updatePlanReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PLAN_ADMIN_REQUEST:
      return {
        loading: true,
        plan: {},
      };
    case UPDATE_PLAN_ADMIN_SUCCESS:
      return {
        loading: false,
        plan: action.payload,
      };
    case UPDATE_PLAN_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_CREATE_UPDATE_STATE:
      return {
        loading: false,
        error: null,
        plan: {},
      };

    default:
      return state;
  }
};

// DELETE PLAN REDUCER -- ADMIN
export const deletePlanReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PLAN_ADMIN_REQUEST:
      return {
        loading: true,
        plan: {},
      };
    case DELETE_PLAN_ADMIN_SUCCESS:
      return {
        loading: false,
        plan: action.payload,
      };
    case DELETE_PLAN_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_CREATE_UPDATE_STATE:
      return {
        loading: false,
        error: null,
        plan: {},
      };

    default:
      return state;
  }
};

// PURCHASE PLAN REDUCER
export const purchasePlanReducer = (state = {}, action) => {
  switch (action.type) {
    case BUY_PLAN_REQUEST:
      return {
        loading: true,
        plan: {},
      };
    case BUY_PLAN_SUCCESS:
      return {
        loading: false,
        plan: action.payload,
      };
    case BUY_PLAN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_CREATE_UPDATE_STATE:
      return {
        loading: false,
        error: null,
        plan: {},
      };

    default:
      return state;
  }
};

// PURCHASED PLAN LIST REDUCER
export const purchasedPlanListReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHAGE_LIST_REQUEST:
      return {
        loading: true,
        plan: {},
      };
    case PURCHAGE_LIST_SUCCESS:
      return {
        loading: false,
        plan: action.payload,
      };
    case PURCHAGE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// MY PURCHASED LIST REDUCER
export const myPurchasedPlanReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_PURCHASED_PLAN_REQUEST:
      return {
        loading: true,
        plan: {},
      };

    case MY_PURCHASED_PLAN_SUCCESS:
      return {
        loading: false,
        plan: action.payload,
      };
    case MY_PURCHASED_PLAN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
