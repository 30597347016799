import React, { useEffect, useState } from "react";
import "../assets/styles/forgotPassword.scss";
import PageTitle from "../components/PageTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import ButtonLoader from "../components/ButtonLoader";
import { MdPassword } from "react-icons/md";
import { toast } from "react-toastify";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearUpdateState,
  resetPasswordReq,
} from "../redux/action/userAction";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setshowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { token } = useParams();

  console.log("token", token);

  // CHANGE PASSWORD START
  // CHANGE PASSWORD START
  const { loading, request, error } = useSelector(
    (state) => state.resetPasswordRequest
  );

  const chnagePasswordHandler = () => {
    if (!password || !confirmPassword) {
      toast.warning("Please fill all details carefully.");
    } else if (password !== confirmPassword) {
      toast.warning("Password and confirm password must be same");
    } else {
      dispatch(resetPasswordReq(token, password));
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (request?.success) {
      toast.success(request?.message);
      dispatch(clearUpdateState());
      navigate("/login");
    }
  }, [error, dispatch, request?.success, request?.message, navigate]);
  // CHANGE PASSWORD ENDS
  // CHANGE PASSWORD ENDS

  return (
    <div className="forgotPass_main">
      <div className="container">
        <PageTitle title="Reset Password" />
        <div className="forgotPass_content">
          <div className="form">
            <CustomInput
              icon={<MdPassword />}
              label="New Password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="New Password"
              name="password"
              value={password}
              impStar="*"
              readOnly={false}
              borderColor="#f2f2f2"
              eyeIcon={showPassword ? <IoIosEye /> : <IoIosEyeOff />}
              setshowPassword={setshowPassword}
            />
            <CustomInput
              icon={<MdPassword />}
              label="Confirm New Password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm New Password"
              name="ConfirmPassword"
              value={confirmPassword}
              impStar="*"
              readOnly={false}
              borderColor="#f2f2f2"
              eyeIcon={showPassword ? <IoIosEye /> : <IoIosEyeOff />}
              setshowPassword={setshowPassword}
            />
            <CustomButton
              className={loading ? "disabled" : ""}
              title={loading ? <ButtonLoader /> : "Change Password"}
              onClick={chnagePasswordHandler}
              disabled={loading ? true : false}
              tooltip="Change Password"
            />
          </div>
          <p>
            Dont want to change password, please <Link to="/login">Login</Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
