import React from "react";
import "../assets/styles/whyus.scss";
// import { FaBriefcase } from "react-icons/fa";
import Bottom from "./Bottom";
import { useLocale } from "../context/locale";

const WhyUs = ({ content }) => {
  const [t, chnageLang] = useLocale();

  return (
    <div className="whyus_main">
      <div className="container">
        <div className="content">
          <div className="textSec">
            <div className="title">
              <p>{t("whatWeDo")}</p>
              <h2>{t("whyChoose")}</h2>
              <Bottom color="#dcca87" />
            </div>

            <p>{t("whyChoose1")}</p>
            <p>{t("whyChoose2")}</p>
          </div>
          <div className="cardSec">
            {content?.map((item, idx) => (
              <div className="card" key={idx}>
                <div className="overlay"></div>
                <div className="icon">{item?.icon}</div>
                <h2>{item?.heading}</h2>
                <p>{item?.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
