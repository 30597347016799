export const DIRECT_TEAM_REQUEST = "DIRECT_TEAM_REQUEST";
export const DIRECT_TEAM_SUCCESS = "DIRECT_TEAM_SUCCESS";
export const DIRECT_TEAM_FAIL = "DIRECT_TEAM_FAIL";

export const LEVEL_TEAM_REQUEST = "LEVEL_TEAM_REQUEST";
export const LEVEL_TEAM_SUCCESS = "LEVEL_TEAM_SUCCESS";
export const LEVEL_TEAM_FAIL = "LEVEL_TEAM_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_STATE = "CLEAR_STATE";
