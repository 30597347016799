import React, { useState } from "react";
import "../../../assets/styles/dashLayout.scss";
import Sidebar from "./Sidebar";
import {
  AiFillCaretDown,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../redux/action/userAction";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../context/userContext";

const DashLayout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const screenWidth = window.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth <= 991 ? false : true
  );

  const d = new Date();
  let thisYear = d.getFullYear();

  // HANDLE LOG_OUT FUNCTION
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const [auth] = useAuth();
  const user = auth?.user;

  return (
    <div className="dashboard_layout_main">
      <div className="sidebarSection">
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      </div>
      <div className="contentSection" style={{ width: !openSidebar && "100%" }}>
        <div className="headerSection">
          <div className="close_title">
            <span
              onClick={() => setOpenSidebar(!openSidebar)}
              title={`${openSidebar ? "Close" : "Open"} list of links`}
            >
              {openSidebar ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />}
            </span>
            <span>indian big bull</span>
          </div>
          <div className="userDropDown">
            <div className="openHandler">
              <span>
                <FaUserAlt />
              </span>{" "}
              <span>
                {user?.fullName?.length === 6
                  ? user?.fullName
                  : user?.fullName?.substr(0, 6) + "..."}
              </span>
              <span>
                <AiFillCaretDown />
              </span>
            </div>

            <div className="dropdownLinks">
              <Link className="dropLink" to="/user-dashboard/profile">
                Profile
              </Link>
              <div className="dropLink" onClick={handleLogout}>
                Logout
              </div>
            </div>
          </div>
        </div>
        <div className="childrenContent">
          {children}

          <div className="powerBy">
            <p>
              &copy; {thisYear} , Powered By <b>Indian Big Bull</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashLayout;
