import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

const Card = styled.div`
  background: #394b71;
  border-radius: 16px;
  padding: 25px;
  box-shadow: inset 6px -6px 10px 0px #dcca87;

  .title {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    &:hover {
      text-decoration: underline;
    }

    > h2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      text-transform: uppercase;
    }

    > span {
      font-size: 26px;
    }
  }

  .sub_title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    > span {
      &:first-child {
        font-weight: 500;
        font-size: 27px;
        line-height: 120%;
        letter-spacing: 0.1em;
        margin-right: 7px;
      }
      &:last-child {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #a9b5bb;
      }
    }
  }

  button {
    font-weight: 400;
    font-size: 11px;
    line-height: 120%;
    background: transparent;
    color: #f2f2f2;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    transition: 0.4s;
    margin: 0 auto;
    display: block;
    cursor: pointer;

    &:hover {
      background: #f2f2f2;
      color: #081336;
    }
  }
`;

const ServiceCard = ({
  link,
  title,
  icon,
  numbers,
  subtitle,
  buttonAction,
  buttonTitle,
}) => {
  const navigate = useNavigate();
  return (
    <Card className="dashboard_card">
      <Link to={link} className="title">
        <h2 className="monserrat_font">{title}</h2>
        {icon && <span>{icon}</span>}
      </Link>
      <div
        className="sub_title monserrat_font"
        style={{ justifyContent: !numbers ? "center" : "" }}
      >
        {numbers && <span>{numbers}</span>}
        <span style={{ textAlign: !numbers ? "center" : "" }}>{subtitle}</span>
      </div>
      <button onClick={() => navigate(buttonAction)}>{buttonTitle}</button>
    </Card>
  );
};

export default ServiceCard;
