import React, { useEffect, useState } from "react";
import "../assets/styles/forgotPassword.scss";
import PageTitle from "../components/PageTitle";
import { Link } from "react-router-dom";
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import ButtonLoader from "../components/ButtonLoader";
import { MdEmail } from "react-icons/md";
import { toast } from "react-toastify";
import {
  clearErrors,
  clearUpdateState,
  forgotPasswordReq,
} from "../redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  // SEND CHNAGE PASSWORD REQUEST START
  // SEND CHNAGE PASSWORD REQUEST START
  const { loading, request, error } = useSelector(
    (state) => state.forgotPasswordRequest
  );

  const sendRequestHandler = () => {
    if (email) {
      dispatch(forgotPasswordReq(email));
    } else {
      toast.warning("Please enter your registered email");
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (request?.success) {
      toast.success(request?.message);
      dispatch(clearUpdateState());
    }
  }, [error, dispatch, request?.success, request?.message]);

  // SEND CHNAGE PASSWORD REQUEST ENDS
  // SEND CHNAGE PASSWORD REQUEST ENDS

  return (
    <div className="forgotPass_main">
      <div className="container">
        <PageTitle title="Forgot Password" />
        <div className="forgotPass_content">
          <div className="form">
            <CustomInput
              icon={<MdEmail />}
              label="Registered mail ID"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="enter your registered mail id"
              name="email"
              value={email}
              impStar="*"
              readOnly={false}
              borderColor="#f2f2f2"
            />
            <CustomButton
              className={loading ? "disabled" : ""}
              title={loading ? <ButtonLoader /> : "Submit Request"}
              onClick={sendRequestHandler}
              disabled={loading ? true : false}
              tooltip="Submit Request"
            />
          </div>
          <p>
            Dont want to change password, please <Link to="/login">Login</Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
