import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/withdrawRequestDetails.scss";
import Loader from "../../components/Loader";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  acceptWithdrawRequestDetails,
  clearWithdrawErrors,
  clearWithdrawState,
  getWithdrawRequestDetails,
} from "../../redux/action/withdrawalReqAction";
import { toast } from "react-toastify";
import CustomButton from "../../components/CustomButton";
import { FcAcceptDatabase, FcPaid } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import CustomInput from "../../components/CustomInput";
import { HiCurrencyRupee } from "react-icons/hi";
import { useAuth } from "../../context/userContext";
import { MdBlock } from "react-icons/md";
import CustomSelect from "../../components/CustomSelect";
import ButtonLoader from "../../components/ButtonLoader";
import PopupPrompt from "../../components/PopupPrompt";

const WithdrawRequestDetails = () => {
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const [acceptanceForm, setAcceptanceForm] = useState(false);
  const [unauthText, setUnauthText] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [txnId, setTxnId] = useState("");
  const { id: requestId } = useParams();

  if (acceptanceForm) {
    document.body.style.overflowY = "hidden";
  } else {
    document.body.style.overflowY = "auto";
  }

  // GET REQUEST DETAILS START
  // GET REQUEST DETAILS START
  const { loading, request, error } = useSelector(
    (state) => state.getWithdrawReqDetails
  );

  const kycDetails = request?.kyc;
  const walletDetails = request?.walletDetails;
  const withdrawReq = request?.withdrawReq;

  useEffect(() => {
    if (requestId) {
      dispatch(getWithdrawRequestDetails(requestId));
    }
  }, [dispatch, requestId]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearWithdrawErrors());
    }
  }, [error, dispatch]);
  // GET REQUEST DETAILS ENDS
  // GET REQUEST DETAILS ENDS

  // TDS & ADMIN CHARGES CALCULATE START
  // TDS & ADMIN CHARGES CALCULATE START
  let calculatedTds = 0;
  let calculatedAdminCharges = 0;

  if (withdrawReq?.amount) {
    calculatedTds = (withdrawReq?.amount * 3) / 100;
  } else {
    calculatedTds = 0;
  }

  if (withdrawReq?.amount) {
    calculatedAdminCharges = (withdrawReq?.amount * 2) / 100;
  } else {
    calculatedAdminCharges = 0;
  }

  const totalPayingAmount =
    withdrawReq?.amount - calculatedTds - calculatedAdminCharges;
  // TDS & ADMIN CHARGES CALCULATE ENDS
  // TDS & ADMIN CHARGES CALCULATE ENDS

  // ACCEPT WITHDRAW REQUEST START
  // ACCEPT WITHDRAW REQUEST START
  const {
    loading: acceptRequestLoading,
    request: acceptRequest,
    error: acceptRequestError,
  } = useSelector((state) => state.acceptWithdrawReq);

  // accept payment handler
  const handleAcceptPayment = () => {
    if (
      !paymentStatus ||
      !txnId ||
      !requestId ||
      !calculatedTds ||
      !calculatedAdminCharges ||
      !totalPayingAmount
    ) {
      toast.error("Please fill all the required fields.");
    } else {
      dispatch(
        acceptWithdrawRequestDetails(
          requestId,
          withdrawReq?.amount,
          paymentStatus,
          calculatedTds,
          calculatedAdminCharges,
          totalPayingAmount,
          txnId
        )
      );
    }
  };

  // accept payment handler
  const handleDeclinePayRequest = () => {
    if (requestId) {
      dispatch(
        acceptWithdrawRequestDetails(
          requestId,
          0,
          "Unauthorized",
          0,
          0,
          0,
          null
        )
      );
    }
  };

  useEffect(() => {
    if (acceptRequestError) {
      toast.error(acceptRequestError);
      dispatch(clearWithdrawErrors());
    }
    if (acceptRequest?.success) {
      toast.success(acceptRequest?.message);
      dispatch(clearWithdrawState());
      setAcceptanceForm(false);
      setUnauthText(false);
      dispatch(getWithdrawRequestDetails(requestId));
    }
  }, [
    acceptRequestError,
    dispatch,
    requestId,
    acceptRequest?.message,
    acceptRequest?.success,
  ]);
  // ACCEPT WITHDRAW REQUEST ENDS
  // ACCEPT WITHDRAW REQUEST ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="withdrawReq_details_wrapper">
            <div className="container">
              <PageTitle goBack={true} title="withdraw Request Details" />

              <div className="withdrawReq_details_content">
                <div className="myCard">
                  <h2>Wallet Details:</h2>
                  <div className="content">
                    <p>
                      <span>Wallet Balance:</span>{" "}
                      <span>₹{walletDetails?.walletBalance}</span>
                    </p>
                    <p>
                      <span>Withdrawable Balance:</span>{" "}
                      <span>₹{walletDetails?.withdrawBalance}</span>
                    </p>
                    <p>
                      <span>Total Earnings:</span>{" "}
                      <span>₹{walletDetails?.totalEarning}</span>
                    </p>
                    <p>
                      <span>Total Withdrawal (till now):</span>{" "}
                      <span>₹{walletDetails?.totalWithdrawal}</span>
                    </p>
                  </div>
                </div>
                <div className="myCard">
                  <h2>Withdraw Request Details:</h2>
                  <div className="content">
                    <p>
                      <span>Requested By:</span>{" "}
                      <span>{withdrawReq?.forUser?.fullName}</span>
                    </p>
                    <p>
                      <span>Requested Amount:</span>{" "}
                      <span>₹{withdrawReq?.amount}</span>
                    </p>
                    <p>
                      <span>User Email:</span>{" "}
                      <span>{withdrawReq?.forUser?.email}</span>
                    </p>
                    <p>
                      <span>User Phone:</span>{" "}
                      <span>
                        {" "}
                        <Link
                          style={{ color: "#fff" }}
                          href={`tel:+91${withdrawReq?.forUser?.phone}`}
                        >
                          {withdrawReq?.forUser?.phone}
                        </Link>{" "}
                      </span>
                    </p>
                    <p>
                      <span
                        style={{
                          backgroundColor:
                            withdrawReq?.status === "Completed"
                              ? "green"
                              : withdrawReq?.status === "Pending"
                              ? "yellow"
                              : "red",
                          color:
                            withdrawReq?.status === "Completed"
                              ? "#fff"
                              : withdrawReq?.status === "Pending"
                              ? "#000"
                              : "#fff",
                        }}
                      >
                        Request Status:
                      </span>{" "}
                      <span>{withdrawReq?.status}</span>
                    </p>
                    {/* tds adminCharge txnId creditedAmount */}
                    <p>
                      <span>TDS:</span>{" "}
                      <span>{withdrawReq?.tds ? withdrawReq?.tds : "---"}</span>
                    </p>
                    <p>
                      <span>Admin Charges:</span>{" "}
                      <span>
                        {withdrawReq?.adminCharge
                          ? withdrawReq?.adminCharge
                          : "---"}
                      </span>
                    </p>
                    <p>
                      <span>Transaction ID:</span>{" "}
                      <span>
                        {withdrawReq?.txnId ? withdrawReq?.txnId : "---"}
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ backgroundColor: "#dcca87", color: "#000" }}
                      >
                        Final/Paid Amount:
                      </span>{" "}
                      <span>
                        {withdrawReq?.creditedAmount
                          ? withdrawReq?.creditedAmount
                          : "---"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="myCard">
                  <h2>KYC Details:</h2>
                  <div className="content">
                    <p>
                      <span
                        style={{
                          backgroundColor:
                            kycDetails?.status === "Approved" ? "green" : "red",
                        }}
                      >
                        KYC Status:
                      </span>{" "}
                      <span>{kycDetails?.status}</span>
                    </p>
                    <p>
                      <span>PAN Card:</span> <span>{kycDetails?.panCard}</span>
                    </p>
                  </div>
                </div>
                <div className="myCard">
                  <h2>Bank Account Details:</h2>
                  <div className="content">
                    <p>
                      <span>Acc Holder:</span>{" "}
                      <span>{kycDetails?.bankPassbook?.accountHolder}</span>
                    </p>
                    <p>
                      <span>Acc No:</span>{" "}
                      <span>{kycDetails?.bankPassbook?.accountNo}</span>
                    </p>
                    <p>
                      <span>Bank Name:</span>{" "}
                      <span>{kycDetails?.bankPassbook?.bankName}</span>
                    </p>
                    <p>
                      <span>Branch:</span>{" "}
                      <span>{kycDetails?.bankPassbook?.bankBranch}</span>
                    </p>
                    <p>
                      <span>Bank IFSC:</span>{" "}
                      <span>{kycDetails?.bankPassbook?.bankIfsc}</span>
                    </p>
                  </div>
                </div>
                <div className="myCard">
                  <h2>Payment Action:</h2>
                  <CustomButton
                    className="acceptRequestBtn"
                    title="Accept Withdraw Request"
                    onClick={() => setAcceptanceForm(true)}
                    preIcon={<FcAcceptDatabase />}
                    tooltip="Accept Withdraw Request"
                  />
                  <CustomButton
                    className="unAuthRequestBtn"
                    title="Unauthorized Request"
                    onClick={() => setUnauthText(true)}
                    preIcon={<MdBlock />}
                    tooltip="Unauthorized Request"
                  />
                </div>
              </div>

              {/* ACCEPT PAYMENT MODEL START */}
              {acceptanceForm && (
                <div className="acceptanceForm">
                  <div className="acceptFormContent">
                    <h2 className="title">
                      <span>Acceptance form</span>
                      <span onClick={() => setAcceptanceForm(false)}>
                        <IoMdClose />
                      </span>
                    </h2>

                    <div className="formContainer custonScroll">
                      <CustomInput
                        icon={<HiCurrencyRupee />}
                        label="Available Wallet Balance"
                        type="text"
                        placeholder="Wallet Balance"
                        value={walletDetails?.walletBalance}
                        readOnly={true}
                        bacgroundColor="grey"
                      />
                      <CustomInput
                        icon={<HiCurrencyRupee />}
                        label="Requested Amount"
                        type="text"
                        placeholder="Requested Amount"
                        value={withdrawReq?.amount}
                        readOnly={true}
                        bacgroundColor="grey"
                      />
                      {/* {walletDetails?.walletBalance >= withdrawReq?.amount} */}
                      <p className="accDetail" style={{ textAlign: "center" }}>
                        <span>TDS Calculated:</span>
                        <span>{calculatedTds} rupees</span>
                      </p>
                      <p className="accDetail" style={{ textAlign: "center" }}>
                        <span>Admin Charges:</span>
                        <span>{calculatedAdminCharges} rupees</span>
                      </p>
                      <h2 className="paytinAmt">
                        Dear {auth?.user?.fullName}, Please pay{" "}
                        <span>{totalPayingAmount} rupees</span> to rquested
                        account.
                      </h2>
                      <p className="accDetail">
                        <span>Requested Acc:</span>
                        <span>{kycDetails?.bankPassbook?.accountNo}</span>
                      </p>
                      <p className="accDetail">
                        <span>Acc Holder:</span>
                        <span>{kycDetails?.bankPassbook?.accountHolder}</span>
                      </p>
                      <p className="accDetail">
                        <span>Bank Branch:</span>
                        <span>{kycDetails?.bankPassbook?.bankBranch}</span>
                      </p>
                      <p className="accDetail">
                        <span>IFSC Code:</span>
                        <span>{kycDetails?.bankPassbook?.bankIfsc}</span>
                      </p>
                      <p className="accDetail">
                        <span>Bank Name:</span>
                        <span>{kycDetails?.bankPassbook?.bankName}</span>
                      </p>
                      <CustomInput
                        icon={``}
                        label="Transaction ID"
                        type="text"
                        placeholder="Transaction ID"
                        value={txnId}
                        readOnly={false}
                        onChange={(e) => setTxnId(e.target.value)}
                        borderColor="#f2f2f2"
                        impStar="*"
                      />
                      <CustomSelect
                        label="Payment Status *"
                        name="status"
                        onChange={(e) => setPaymentStatus(e.target.value)}
                        optionArray={[{ option: "Completed" }]}
                        impStar=""
                        value={paymentStatus}
                        readOnly={false}
                        borderColor="#f2f2f2"
                      />

                      <div className="btnGrp">
                        <CustomButton
                          className="amountPaid"
                          title={
                            acceptRequestLoading ? (
                              <ButtonLoader />
                            ) : (
                              "Amount Paid"
                            )
                          }
                          onClick={handleAcceptPayment}
                          preIcon={acceptRequestLoading ? "" : <FcPaid />}
                          disabled={acceptRequestLoading ? true : false}
                          tooltip="If amount is paid, click here"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* ACCEPT PAYMENT MODEL ENDS */}

              {/* DECLINE PAYMENT MODEL START */}
              {unauthText && (
                <PopupPrompt
                  confirmBtnTxt="Confirm"
                  cancelBtnTxt="Cancel"
                  confirmOnclick={handleDeclinePayRequest}
                  cancelOnclick={() => setUnauthText(false)}
                  title="If this payment is unauthorized then click on confirm button, otherwise cancel this popup!"
                  lightTitle={true}
                  loading={acceptRequestLoading}
                />
              )}
              {/* DECLINE PAYMENT MODEL ENDS */}
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default WithdrawRequestDetails;
