import React, { Fragment, useEffect } from "react";
import "../../assets/styles/allUsersList.scss";
import "../../assets/styles/paginationHook.scss";
import "../../assets/styles/tableDataStyle.scss";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import usePaginate from "../../assets/hooks/usePaginate";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { clearErrors, getAllUsers } from "../../redux/action/userAction";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AllUsersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, users, error } = useSelector((state) => state.allUsers);
  const allUsers = users?.users;

  // USER EFFECT FOR ALL USERS START
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
    if (users?.success) {
      toast.success(users?.message);
    }
  }, [error, dispatch, users?.success, users?.message]);

  // USER EFFECT FOR ALL USERS ENDS

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(allUsers);
  // Pagination Hook Ends

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="allUsers_list_wrapper">
            <div className="container">
              <PageTitle goBack={true} title="All Users" />

              <div className="allusers_content">
                <div className="table_data custonScroll">
                  <table className="list_table" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <p>{item?.fullName}</p>
                            </td>
                            <td>
                              <p>{item?.email}</p>
                            </td>
                            <td>
                              <p>{item?.phone}</p>
                            </td>
                            <td
                              style={{
                                background: item?.isActive ? "green" : "red",
                              }}
                            >
                              <p>
                                <b>{item?.isActive ? "Active" : "In-Active"}</b>
                              </p>
                            </td>
                            <td>
                              <div>
                                <button
                                  onClick={() =>
                                    navigate(`/admin/user/${item?._id}`)
                                  }
                                >
                                  <FaEye />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagination_sec">
                  <p className="entriesCount">
                    Showing {itemOffset + 1} to{" "}
                    {endOffset > allUsers?.length
                      ? allUsers?.length
                      : endOffset}{" "}
                    of {allUsers?.length} entries
                  </p>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default AllUsersList;
