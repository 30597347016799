import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/AddFundReqDetails.scss";
import Loader from "../../components/Loader";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { FaDiagramSuccessor, FaIndianRupeeSign } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearFundErrors,
  getFundsAddReqDetails,
  updateFundsAddReq,
} from "../../redux/action/fundAction";
import moment from "moment";
import { clearErrors, getUserDetails } from "../../redux/action/userAction";
import ButtonLoader from "../../components/ButtonLoader";
import CustomSelect from "../../components/CustomSelect";
import { MdOutlineCancelScheduleSend } from "react-icons/md";

const AddFundReqDetails = () => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState();
  const [status, setStatus] = useState("");

  const { id: fundId } = useParams();

  // FET ADD FUND DETAILS START
  // FET ADD FUND DETAILS START
  const { loading, fund, error } = useSelector((state) => state.addFundDetails);

  const fundDetails = fund?.fund;

  const fundRequestDate = moment(fundDetails?.createdAt).format(
    "DD-MM-YYYY [at] hh:mm A"
  );

  useEffect(() => {
    dispatch(getFundsAddReqDetails(fundId));
  }, [dispatch, fundId]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearFundErrors());
    }

    if (fund?.success) {
      //   toast.success(fund?.message);
    }
  }, [dispatch, error, fund?.success, fund?.message]);

  // FET ADD FUND DETAILS ENDS
  // FET ADD FUND DETAILS ENDS

  // GET USER DETAILS START
  // GET USER DETAILS START
  const {
    loading: userLoading,
    user,
    error: userError,
  } = useSelector((state) => state.userDetails);

  const userDetails = user?.userDetails;

  useEffect(() => {
    if (fundDetails?.forUser) {
      dispatch(getUserDetails(fundDetails?.forUser));
    }
  }, [dispatch, fundDetails?.forUser]);

  useEffect(() => {
    if (userError) {
      toast.error(userError);
      dispatch(clearErrors());
    }
  }, [userError, dispatch]);
  // GET USER DETAILS ENDS
  // GET USER DETAILS ENDS

  // UPDATE ADD FUND REQUEST START
  // UPDATE ADD FUND REQUEST START
  const {
    loading: updatedFundLoading,
    updatedFund,
    error: updatedFundError,
  } = useSelector((state) => state.updateAddFundRequest);

  const updateFundHandler = (isActive, isVerified, amount, status) => {
    dispatch(updateFundsAddReq(fundId, isActive, isVerified, amount, status));
  };

  useEffect(() => {
    if (updatedFundError) {
      toast.error(updatedFundError);
      dispatch(clearFundErrors());
    }

    if (updatedFund?.success) {
      toast.success(updatedFund?.message);
      dispatch(getFundsAddReqDetails(fundId));
    }
  }, [
    updatedFundError,
    dispatch,
    updatedFund?.success,
    updatedFund?.message,
    fundId,
  ]);
  // UPDATE ADD FUND REQUEST ENDS
  // UPDATE ADD FUND REQUEST ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="fundRequest_details_wrapper">
            <div className="container">
              <PageTitle goBack={true} title="Adding Fund Details" />

              <div className="fundRequest_content">
                <div className="payment_screenshot">
                  <Link target="_blank" to={fundDetails?.paymentSnap?.url}>
                    <img
                      src={fundDetails?.paymentSnap?.url}
                      alt={fundDetails?.paymentSnap?.url}
                    />
                  </Link>
                </div>
                <h2>
                  <span>Request By:</span>
                  <span>
                    {userLoading ? <ButtonLoader /> : userDetails?.fullName}
                  </span>
                </h2>
                <div className="fundDetails">
                  <p>
                    <span>Requested Amount</span>
                    <span>{fundDetails?.amount}</span>
                  </p>
                  <p>
                    <span>Requested Date</span>
                    <span>{fundRequestDate}</span>
                  </p>
                  <p>
                    <span>Transaction Id</span>
                    <span>{fundDetails?.transactionId}</span>
                  </p>
                  <p>
                    <span>Remarks</span>
                    <span>{fundDetails?.remarks}</span>
                  </p>
                  <p>
                    <span>Payment Status</span>
                    <span>{fundDetails?.status}</span>
                  </p>
                  <p>
                    <span>Verification</span>
                    <span className={fundDetails?.isVerified ? "green" : "red"}>
                      {fundDetails?.isVerified ? "Verified" : "Pending"}
                    </span>
                  </p>
                </div>

                <div className="fundAction">
                  <CustomInput
                    icon={<FaIndianRupeeSign />}
                    label="Verified/Received Amount"
                    type="number"
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Type Verified Amount"
                    name="amount"
                    value={amount}
                    impStar="*"
                    borderColor="#f2f2f2"
                  />
                  <CustomSelect
                    label="Select Status of the payment"
                    name="status"
                    onChange={(e) => setStatus(e.target.value)}
                    optionArray={[
                      { option: "Payment Success" },
                      { option: "Unauthorised Payment" },
                      { option: "Fake Payment" },
                    ]}
                    impStar="*"
                    value={status}
                    readOnly={false}
                    borderColor="#f2f2f2"
                  />
                  <div className="btnGrp">
                    <CustomButton
                      className={`verifyBtn ${
                        status !== "Payment Success" ? "disabled" : ""
                      }`}
                      title={
                        updatedFundLoading ? <ButtonLoader /> : "Verify Payment"
                      }
                      onClick={() =>
                        updateFundHandler(false, true, amount, status)
                      }
                      icon=""
                      disabled={updatedFundLoading ? true : false}
                      preIcon={<FaDiagramSuccessor />}
                      tooltip="Verify, If Payment received in your account."
                    />
                    <CustomButton
                      className={`declineBtn ${
                        status === "Payment Success" ? "disabled" : ""
                      }`}
                      title={
                        updatedFundLoading ? (
                          <ButtonLoader />
                        ) : (
                          "Decline Payment"
                        )
                      }
                      onClick={() => updateFundHandler(false, true, 0, status)}
                      disabled={updatedFundLoading ? true : false}
                      preIcon={<MdOutlineCancelScheduleSend />}
                      tooltip="Unauthorised/Fake Request."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default AddFundReqDetails;
