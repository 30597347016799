export const ADD_FUND_REQUEST = "ADD_FUND_REQUEST";
export const ADD_FUND_SUCCESS = "ADD_FUND_SUCCESS";
export const ADD_FUND_FAIL = "ADD_FUND_FAIL";

export const DEPOSIT_HISTORY_REQUEST = "DEPOSIT_HISTORY_REQUEST";
export const DEPOSIT_HISTORY_SUCCESS = "DEPOSIT_HISTORY_SUCCESS";
export const DEPOSIT_HISTORY_FAIL = "DEPOSIT_HISTORY_FAIL";

// FOR ADMIN
export const FUND_ADD_LIST_REQUEST = "FUND_ADD_LIST_REQUEST";
export const FUND_ADD_LIST_SUCCESS = "FUND_ADD_LIST_SUCCESS";
export const FUND_ADD_LIST_FAIL = "FUND_ADD_LIST_FAIL";

export const FUND_ADD_DETAIL_REQUEST = "FUND_ADD_DETAIL_REQUEST";
export const FUND_ADD_DETAIL_SUCCESS = "FUND_ADD_DETAIL_SUCCESS";
export const FUND_ADD_DETAIL_FAIL = "FUND_ADD_DETAIL_FAIL";

export const UPDATE_FUND_ADD_REQUEST = "UPDATE_FUND_ADD_REQUEST";
export const UPDATE_FUND_ADD_SUCCESS = "UPDATE_FUND_ADD_SUCCESS";
export const UPDATE_FUND_ADD_FAIL = "UPDATE_FUND_ADD_FAIL";

export const CLEAR_FUND_ERRORS = "CLEAR_FUND_ERRORS";
export const CLEAR_FUND_STATE = "CLEAR_FUND_STATE";
