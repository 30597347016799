import React, { Fragment, useEffect } from "react";
import "../../assets/styles/tableDataStyle.scss";
import "../../assets/styles/transactionHistory.scss";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import ReactPaginate from "react-paginate";
import usePaginate from "../../assets/hooks/usePaginate";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotFoundCard from "../admin/components/NotFoundCard";
import { clearTeamErrors, getDirectTeam } from "../../redux/action/teamAction";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import moment from "moment";

const TransactionHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // USER DETAILS START
  const { userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;
  // USER DETAILS ENDS

  const { loading, team, error } = useSelector((state) => state.directTeam);

  const directTeam = team?.team;

  // GET  DIRECT TEAM ON PAGE LOAD
  useEffect(() => {
    if (user?.paidUser) {
      dispatch(getDirectTeam());
    }
  }, [dispatch, user?.paidUser]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearTeamErrors());
    }
  }, [error, dispatch, team?.success, team?.message]);

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(directTeam);
  // Pagination Hook Ends
  return (
    <Fragment>
      <DashLayout>
        {user?.paidUser ? (
          <div className="transaction_history_wrapper">
            {loading ? (
              <Loader />
            ) : (
              <DashCard
                className="transaction_history"
                title={"Transaction Summary Report"}
              >
                <CustomInput
                  icon={<BsSearch />}
                  type="search"
                  //   onChange=""
                  placeholder="Search By Name..."
                  name="searchString"
                  //   value=""
                  //   impStar=""
                  //   readOnly=""
                  borderColor="#f2f2f2"
                />
                <div className="table_data custonScroll">
                  <table className="list_table" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Wallet Type</th>
                        <th>Description</th>
                        <th>Debit Amount</th>
                        <th>Credit Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((item, idx) => {
                         const createdDate = moment(item?.createdAt).format(
                          "DD/MM/YYYY [at] hh:mm A"
                        );

                        let refferalIncome = 0;
                        if (item?.buyAmount) {
                          refferalIncome =  (item?.buyAmount * 30) / 100;
                        } 
                        return (
                         item && <tr key={idx}>
                            <td>
                              <p>{createdDate}</p>
                            </td>
                            <td>
                              <p>Income Wallet</p>
                            </td>
                            <td>
                              <p>Direct Income from {item?.forUser?.username}</p>
                            </td>
                            <td>
                              <p>₹0.00</p>
                            </td>
                            <td>
                              <p>₹{refferalIncome ? refferalIncome : 0.00}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagination_sec">
                  <p className="entriesCount">
                    Showing {itemOffset + 1} to{" "}
                    {endOffset > directTeam?.length
                      ? directTeam?.length
                      : endOffset}{" "}
                    of {directTeam?.length} entries
                  </p>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </DashCard>
            )}
          </div>
        ) : (
          <NotFoundCard
            text="You have not any purchased plan yet, please purchage any plan to see your Transaction summary."
            showAction={true}
            actionTitle="Purchage Plan Now"
            onClick={() => navigate("/user-dashboard/activate-my-id")}
            actionIcon=""
          />
        )}
      </DashLayout>
    </Fragment>
  );
};

export default TransactionHistory;
