import { axiosInstance } from "../../utils/axiosConfig";
import {
  ALL_STATS_FAIL,
  ALL_STATS_REQUEST,
  ALL_STATS_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} from "../constant/statsConstant";

// GET ALL STATS
export const getAllStats = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_STATS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/stats`, config);

    dispatch({
      type: ALL_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_STATS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearStatsErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearStatsState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};
