import React from "react";
import { Fragment } from "react";
import styled from "styled-components";
import ButtonLoader from "./ButtonLoader";

const PromptSection = styled.section`
  height: 100vh;
  width: 100%;
  background-color: #394b71;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 15px;
  box-sizing: border-box;

  > .promt_mess_sec {
    background-color: #f2f2f2;
    padding: 50px 30px;
    box-sizing: border-box;
    border-radius: 16px;
    position: relative;
    width: 500px;

    @media screen and (max-width: 575px) {
      width: 100%;
      padding: 25px 15px;
    }

    > .prompt-title {
      text-align: center;
      color: #081336;
      font-size: 20px;
      margin-bottom: 30px;
    }

    > .btn_grps {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      > button {
        width: 100px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #081336;
        cursor: pointer;
        font-size: 18px;

        &:first-child {
          color: #f2f2f2;
          background-color: green;
        }
        &:last-child {
          color: #f2f2f2;
          background-color: red;
        }

        &:hover {
          background-color: transparent;
          color: green;
        }
      }
    }
  }
`;

const PopupPrompt = ({
  confirmBtnTxt,
  cancelBtnTxt,
  confirmOnclick,
  cancelOnclick,
  title,
  lightTitle,
  loading,
  id
}) => {
  return (
    <Fragment>
      {title && (
        <PromptSection>
          <div className="promt_mess_sec">
            {!lightTitle ? (
              <h2 className="prompt-title">{title}</h2>
            ) : (
              <p className="prompt-title">{title}</p>
            )}
            <div className="btn_grps">
              {confirmOnclick && (
                <button onClick={confirmOnclick}>
                  {loading ? <ButtonLoader /> :  confirmBtnTxt}
                </button>
              )}

              {cancelOnclick && (
                <button onClick={cancelOnclick}>{cancelBtnTxt}</button>
              )}
            </div>
          </div>
        </PromptSection>
      )}
    </Fragment>
  );
};

export default PopupPrompt;
