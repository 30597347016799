import React, { Fragment, useEffect } from "react";
import "../../assets/styles/userDetails.scss";
import Loader from "../../components/Loader";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import { defaultPic } from "../../assets/images/images";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearErrors, getUserDetails } from "../../redux/action/userAction";
import { useParams } from "react-router-dom";

const UserDetails = () => {
  const dispatch = useDispatch();
  const { loading, user, error } = useSelector((state) => state.userDetails);

  const { id: userId } = useParams();

  const userDetails = user?.userDetails;

  useEffect(() => {
    dispatch(getUserDetails(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (user?.success) {
      toast.success(user?.message);
    }
  }, [error, dispatch, user?.success, user?.message]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="userDetail_wrapper">
            <div className="container">
              <PageTitle
                goBack={true}
                title={`${userDetails?.fullName}'s Details`}
              />

              <div className="actionContainer">
                <h3>
                  User status:{" "}
                  <span className={userDetails?.isActive ? "green" : "red"}>
                    {userDetails?.isActive ? "active" : "inactive"} User
                  </span>
                </h3>
                <CustomButton
                  className="actionButton"
                  title={userDetails?.isActive ? "Inactive" : "Active"}
                  // onClick=""
                  // icon=""
                  disabled={false}
                  // preIcon=""
                  tooltip={`Click here to ${
                    userDetails?.isActive ? "Inactive" : "Active"
                  } this user`}
                />
              </div>

              <div className="userdetails_content">
                <div className="userImg">
                  <img
                    src={
                      userDetails?.avatar?.url
                        ? userDetails?.avatar?.url
                        : defaultPic
                    }
                    alt={userDetails?.fullName}
                  />
                </div>
                <div className="userdata">
                  <CustomInput
                    label="Full Name"
                    type="text"
                    // onChange=""
                    name="fullName"
                    value={userDetails?.fullName}
                    readOnly={true}
                    borderColor="#f2f2f2"
                  />
                  <CustomInput
                    label="Unique Username"
                    type="text"
                    // onChange=""
                    name="username"
                    value={userDetails?.username}
                    readOnly={true}
                    borderColor="#f2f2f2"
                  />
                  <CustomInput
                    label="Email"
                    type="email"
                    // onChange=""
                    name="email"
                    value={userDetails?.email}
                    readOnly={true}
                    borderColor="#f2f2f2"
                  />
                  <CustomInput
                    label="Phone Number"
                    type="number"
                    // onChange=""
                    name="phone"
                    value={userDetails?.phone}
                    readOnly={true}
                    borderColor="#f2f2f2"
                  />
                  <CustomSelect
                    label="Gender"
                    name="gender"
                    optionArray={[
                      {
                        id: 1,
                        option: "Male",
                      },
                      {
                        id: 2,
                        option: "Female",
                      },
                      {
                        id: 3,
                        option: "Other",
                      },
                    ]}
                    readOnly={true}
                    borderColor="#f2f2f2"
                    value={userDetails?.gender}
                  />
                </div>
                <div className="sponsor_details">
                  <div className="sponsorLabel">Sponsor Details</div>
                  <div className="inputGrp">
                    <CustomInput
                      label="Sponsor Id"
                      type="text"
                      // onChange=""
                      name="sponsorId"
                      value={
                        userDetails?.sponsor?.userName
                          ? userDetails?.sponsor?.userName
                          : "Not sponsored"
                      }
                      readOnly={true}
                      borderColor="#f2f2f2"
                    />
                    <CustomInput
                      label="Sponsor Name"
                      type="text"
                      // onChange=""
                      name="sponsorName"
                      value={
                        userDetails?.sponsor?.fullName
                          ? userDetails?.sponsor?.fullName
                          : "Not sponsored"
                      }
                      readOnly={true}
                      borderColor="#f2f2f2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default UserDetails;
