import React from "react";
import styled from "styled-components";

const Card = styled.div`
  background-color: rgba(4, 6, 67, 0.5);
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;

  > .title {
    color: #dcca87;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid #dcca87;
    margin-bottom: 10px;
    font-size: 18px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
      font-size: 16px;
    }

    > .subTitle {
      > button {
        background: transparent;
        border: 1px solid #dcca87;
        padding: 2px 15px;
        font-size: 17px;
        text-transform: uppercase;
        color: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        outline: none;

        &:hover {
          background: #dcca87;
          color: #000;
        }

        .icon {
          margin-right: 5px;
        }
      }
    }
  }
`;

const DashCard = ({
  className,
  children,
  title,
  subTitle,
  subTitleLink,
  subTitleLinkClick,
  subTitleLinkIcon,
}) => {
  return (
    <Card className={`${className} myCard`}>
      <div className="title">
        <div> {title}</div>{" "}
        {subTitleLink && (
          <div className="subTitle">
            <button onClick={subTitleLinkClick} title={subTitleLink}>
              {subTitleLinkIcon && (
                <span className="icon">{subTitleLinkIcon}</span>
              )}
              <span>{subTitleLink}</span>
            </button>
          </div>
        )}
        {subTitle && <div className="subTitle">{subTitle}</div>}
      </div>
      <div className="cardContent">{children}</div>
    </Card>
  );
};

export default DashCard;
