import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/teamList.scss";
import "../../assets/styles/tableDataStyle.scss";
import usePaginate from "../../assets/hooks/usePaginate";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import { SiLevelsdotfyi } from "react-icons/si";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import NotFoundCard from "../admin/components/NotFoundCard";
import { useNavigate } from "react-router-dom";
import { clearTeamErrors, getLevelTeam } from "../../redux/action/teamAction";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const TeamList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [level, setLevel] = useState(1);

  // USER DETAILS START
  const { userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;
  const userId = user?._id;
  // USER DETAILS ENDS

  // GET TEAM LIST BY LEVEL START
  // GET TEAM LIST BY LEVEL START
  const { loading, team, error } = useSelector((state) => state.levelTeam);

  console.log("team", team);
  const myTeam = team?.chilData;

  useEffect(() => {
    if(user?.paidUser){
      dispatch(getLevelTeam(userId, level));
    }
  }, [dispatch, userId, level,user?.paidUser]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearTeamErrors());
    }
  }, [error, dispatch]);

  // GET TEAM LIST BY LEVEL ENDS
  // GET TEAM LIST BY LEVEL ENDS

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(myTeam);
  // Pagination Hook Ends

  return (
    <Fragment>
      <DashLayout>
        <div className="teamList_wrapper">
          <DashCard className="team_list" title={"Team List"}>
            <CustomInput
              label="Team Level (Ex: 1,2,3,4)"
              icon={<SiLevelsdotfyi />}
              type="number"
              onChange={(e) => setLevel(e.target.value)}
              placeholder="Level Number (Ex: 1,2,3,4)"
              name="level"
              value={level}
              impStar="*"
              readOnly={false}
              borderColor="#f2f2f2"
            />

            {user?.paidUser ? (
              <>
                {!loading ? (
                  <>
                    {myTeam?.length > 0 ? (
                      <>
                        <div className="table_data custonScroll">
                          <table className="list_table" cellSpacing="0">
                            <thead>
                              <tr>
                                <th>Level {level ? level : 1}.</th>
                                <th>ID</th>
                                <th>Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.map((item, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>
                                      <p>Level-1</p>
                                    </td>
                                    <td>
                                      <p> {item?.username}</p>
                                    </td>
                                    <td>
                                      <p> {item?.fullName}</p>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="pagination_sec">
                          <p className="entriesCount">
                            Showing {itemOffset + 1} to{" "}
                            {endOffset > myTeam?.length
                              ? myTeam?.length
                              : endOffset}{" "}
                            of {myTeam?.length} entries
                          </p>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel={<RxDoubleArrowRight />}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            previousLabel={<RxDoubleArrowLeft />}
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </>
                    ) : (
                      <NotFoundCard
                        text="Team not found, you did not added anyone by your reference."
                        showAction={true}
                        actionTitle="Dashboard"
                        onClick={() => navigate("/user-dashboard")}
                        actionIcon=""
                      />
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </>
            ) : (
              <NotFoundCard
                text="You have not any purchased plan yet, please purchage any plan to see you Direct team list."
                showAction={true}
                actionTitle="Purchage Plan Now"
                onClick={() => navigate("/user-dashboard/activate-my-id")}
                actionIcon=""
              />
            )}
          </DashCard>
        </div>
      </DashLayout>
    </Fragment>
  );
};

export default TeamList;
