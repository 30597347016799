import React, { Fragment, useEffect, useState } from "react";
import "../assets/styles/register.scss";
import PageTitle from "../components/PageTitle";
import CustomInput from "../components/CustomInput";
import Bottom from "../components/Bottom";
import CustomSelect from "../components/CustomSelect";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import CustomButton from "../components/CustomButton";
import { logoMain } from "../assets/images/images";
import { BiRename } from "react-icons/bi";
import { MdEmail, MdMobileFriendly, MdPassword } from "react-icons/md";
import { BsFillEmojiSmileFill } from "react-icons/bs";
import { GoSponsorTiers } from "react-icons/go";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  // clearRegisterState,
  userRegisterAction,
  userSponsorAction,
} from "../redux/action/userAction";
import { toast } from "react-toastify";
import ButtonLoader from "../components/ButtonLoader";
import { useAuth } from "../context/userContext";
import CustomHelmet from "../components/Helmet";

const Register = () => {
  const [showPassword, setshowPassword] = useState(false);
  const location = useLocation();
  const redirectSponsorId = location.search && location.search.split("=")[1];
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // REDIRECT TO PROFILE PAGE IF LOGIN START
  useEffect(() => {
    if (auth?.user?._id) {
      navigate("/user-dashboard");
    }
  }, [auth?.user?._id, navigate]);
  // REDIRECT TO PROFILE PAGE IF LOGIN ENDS

  // USER REGISTRATION STATE
  const [registerData, setRegisterData] = useState({
    fullName: "",
    email: "",
    phone: "",
    gender: "",
    password: "",
    confirmPassword: "",
    sponsorId: redirectSponsorId ? redirectSponsorId : "",
  });

  const [sponsorName, setSponsorName] = useState(""); // SPONSOR PERSON NAME STATE

  // DESTRUCTURE
  const {
    fullName,
    email,
    phone,
    gender,
    password,
    confirmPassword,
    sponsorId,
  } = registerData;

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };

  // USER REGISTRATION DATA GET BY API
  const { userInfo, error, loading } = useSelector((state) => state.register);

  // REGISTER HANDLER
  const handleRegister = () => {
    if (!fullName || !email || !phone || !gender || !password) {
      toast.warning("Fill all the fields(*) carefully!");
    } else if (password !== confirmPassword) {
      toast.warning("Password and confirm password must be same!");
    } else {
      dispatch(
        userRegisterAction(
          fullName,
          email,
          phone,
          gender,
          password,
          sponsorId,
          sponsorName
        )
      );
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
    if (userInfo?.success) {
      // toast.success(userInfo?.message);
      // navigate("/user-dashboard");
      // dispatch(clearRegisterState());
    }
  }, [dispatch, error, userInfo?.message, userInfo?.success, navigate]);

  // SPONSOR FILL UP DETAILS
  // SPONSOR FILL UP DETAILS
  const {
    loading: sponsorLoading,
    sponsor,
    error: sponsorError,
  } = useSelector((state) => state.sponsorUser);

  const spnsorUserParam = sponsor?.sponsorUser;

  useEffect(() => {
    if (sponsorId) {
      dispatch(userSponsorAction(sponsorId));
    }
  }, [dispatch, sponsorId]);

  useEffect(() => {
    if (sponsor?.success) {
      setSponsorName(spnsorUserParam?.fullName);
    }
    if (sponsorError) {
      dispatch(clearErrors());
      setSponsorName("");
    }
  }, [
    sponsor?.success,
    sponsor?.message,
    sponsorError,
    dispatch,
    spnsorUserParam?.fullName,
  ]);

  return (
    <Fragment>
      <CustomHelmet
        title={`Register - Indian Big Bull`}
        canonical="https://indianbigbull.in/"
      />
    <div className="registerPage_main">
      <div className="container">
        <PageTitle title="Register" />
        <div className="titleMain">
          Register with Indian big bull
          <Bottom center={true} color="#dcca87" />
        </div>
        <div className="registerContent">
          <div className="subTitle">Personal Detail</div>
          <div className="inputGrp">
            <CustomInput
              label="Full Name"
              icon={<BiRename />}
              impStar="*"
              type="text"
              placeholder="Your Full Name"
              name="fullName"
              borderColor="#aaa"
              onChange={handleInputChange}
              value={registerData.fullName}
            />

            <CustomSelect
              label="Gender"
              impStar="*"
              name="gender"
              optionArray={[
                {
                  id: 1,
                  option: "Male",
                },
                {
                  id: 2,
                  option: "Female",
                },
                {
                  id: 3,
                  option: "Other",
                },
              ]}
              borderColor="#aaa"
              value={registerData.gender}
              onChange={handleInputChange}
            />

            <CustomInput
              label="Email"
              icon={<MdEmail />}
              impStar="*"
              type="email"
              placeholder="Your Email Id"
              name="email"
              borderColor="#aaa"
              onChange={handleInputChange}
              value={registerData.email}
            />

            <CustomInput
              label="Mobile Number"
              icon={<MdMobileFriendly />}
              impStar="*"
              type="number"
              placeholder="Mobile Number"
              name="phone"
              borderColor="#aaa"
              onChange={handleInputChange}
              value={registerData.phone}
            />

            <CustomInput
              label="Password"
              impStar="*"
              icon={<MdPassword />}
              type={showPassword ? "text" : "password"}
              placeholder="password"
              name="password"
              eyeIcon={showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              setshowPassword={setshowPassword}
              borderColor="#aaa"
              value={registerData.password}
              onChange={handleInputChange}
            />

            <CustomInput
              label="Confirm Password"
              impStar="*"
              icon={<MdPassword />}
              type={showPassword ? "text" : "password"}
              placeholder="Confirm Password"
              name="confirmPassword"
              eyeIcon={showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              setshowPassword={setshowPassword}
              borderColor="#aaa"
              value={registerData.confirmPassword}
              onChange={handleInputChange}
            />
          </div>

          <div className="subTitle">Sponsor Details</div>
          <div className="inputGrp">
            <CustomInput
              className={`${sponsorLoading ? "loadingInput" : ""}`}
              label="Sponsor ID"
              icon={
                sponsorLoading ? (
                  <AiOutlineLoading3Quarters />
                ) : (
                  <GoSponsorTiers />
                )
              }
              type="text"
              placeholder="Sponsor Id"
              name="sponsorId"
              borderColor="#aaa"
              onChange={handleInputChange}
              value={registerData.sponsorId}
            />

            <CustomInput
              className={`sponsorNameInput ${
                sponsorLoading ? "loadingInput" : ""
              }`}
              label="Sponsor Name"
              icon={
                sponsorLoading ? (
                  <AiOutlineLoading3Quarters />
                ) : (
                  <BsFillEmojiSmileFill />
                )
              }
              type="text"
              placeholder="Sponsor Name"
              name="sponsorName"
              borderColor="#aaa"
              readOnly={true}
              onChange={(e) => setSponsorName(e.target.value)}
              value={sponsorName}
            />
          </div>

          <CustomButton
            className={`register_btn ${loading ? "disabled" : ""}`}
            title={loading ? <ButtonLoader /> : "Register"}
            onClick={handleRegister}
            disabled={loading ? true : false}
            tooltip="Click here to register"
          />
        </div>

        <div className="logoImg">
          <Link to="/" title="Click here to go to Homepage">
            <img src={logoMain} alt="logoMain" />
          </Link>
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default Register;
