import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/adminLogin.scss";
import AdminLayout from "./components/AdminLayout";
import CustomInput from "../../components/CustomInput";
import ButtonLoader from "../../components/ButtonLoader";
import CustomButton from "../../components/CustomButton";
import { FiLogIn } from "react-icons/fi";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import { clearErrors, userLogin } from "../../redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Bottom from "../../components/Bottom";
import { useAuth } from "../../context/userContext";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setshowPassword] = useState(false);
  const [auth] = useAuth();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  // DESTRUCTURE DATA
  const { email, password } = loginData;

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  // LOGIN PARAMS
  const { loading, userInfo, error } = useSelector((state) => state.login);

  // LOGIN HANDLER FUNCTION
  const handleLogin = () => {
    if (!email || !password) {
      toast.warning("Please fill Email & Password carefully!");
    } else {
      dispatch(userLogin(email, password));
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (userInfo?.success) {
      toast.success(userInfo?.message);
      if (auth?.user?.role === 10) {
        navigate("/admin");
      } else if (auth?.user?.role === 11) {
        navigate("/user-dashboard");
      }
    }
  }, [
    error,
    dispatch,
    userInfo?.success,
    userInfo?.message,
    navigate,
    auth?.user?.role,
  ]);

  return (
    <Fragment>
      <AdminLayout>
        <div className="adminLogin_wrapper">
          <div className="container">
            <div className="title">
              Admin Login
              <Bottom center={true} color="#dcca87" />
            </div>

            <div className="login_form">
              <CustomInput
                icon=""
                type="email"
                placeholder="Enter Mail Id or Uniqie Id"
                name="email"
                borderColor="#aaa"
                value={email}
                onChange={handleInputChange}
              />
              <CustomInput
                icon=""
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                eyeIcon={showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                setshowPassword={setshowPassword}
                borderColor="#aaa"
                value={password}
                onChange={handleInputChange}
              />
              <CustomButton
                // className={`loginBtn ${loading ? "disabled" : ""}`}
                title={loading ? <ButtonLoader /> : "Login"}
                onClick={handleLogin}
                icon={<FiLogIn />}
                // disabled={loading ? true : false}
                tooltip="Click here to login"
              />
            </div>
          </div>
        </div>
      </AdminLayout>
    </Fragment>
  );
};

export default AdminLogin;
