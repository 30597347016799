import React, { Fragment } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact.js";
import Service from "./pages/Service.js";
import SocialActivities from "./pages/SocialActivities.js";
import Plans from "./pages/Plans.js";
import Benefits from "./pages/Benefits.js";
import NotFoundPage from "./pages/NotFoundPage.js";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Profile from "./pages/dashboard/Profile";
import RedirectSpinner from "./components/RedirectSpinner";
import ScrollToTop from "./components/ScrollToTop.js";
import Dashboard from "./pages/dashboard/Dashboard";
import Kyc from "./pages/dashboard/Kyc";
import DirectTeam from "./pages/dashboard/DirectTeam";
import TeamList from "./pages/dashboard/TeamList";
import ActivateId from "./pages/dashboard/ActivateId";
import ActivateDownlineId from "./pages/dashboard/ActivateDownlineId";
import AddFund from "./pages/dashboard/AddFund";
import WithdrawReq from "./pages/dashboard/WithdrawReq";
import DepositHistory from "./pages/dashboard/DepositHistory";
import WithdrawHistory from "./pages/dashboard/WithdrawHistory";
import TransactionHistory from "./pages/dashboard/TransactionHistory";
import AutoPoolIncome from "./pages/dashboard/AutoPoolIncome";
import DirectSponsorIncome from "./pages/dashboard/DirectSponsorIncome";
import LevelIncome from "./pages/dashboard/LevelIncome";
import CompanyTurnover from "./pages/dashboard/CompanyTurnover";
import RewardIncome from "./pages/dashboard/RewardIncome";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader.js";
import AdminDashboard from "./pages/admin/AdminDashboard.js";
import AdminLogin from "./pages/admin/AdminLogin.js";
import AllUsersList from "./pages/admin/AllUsersList.js";
import UserDetails from "./pages/admin/UserDetails.js";
import AddFundReqList from "./pages/admin/AddFundReqList.js";
import AddFundReqDetails from "./pages/admin/AddFundReqDetails.js";
import WalletsList from "./pages/admin/WalletsList.js";
import WalletDetails from "./pages/admin/WalletDetails.js";
import KycReqList from "./pages/admin/KycReqList.js";
import KycReqDetails from "./pages/admin/KycReqDetails.js";
import AllPlanList from "./pages/admin/AllPlanList.js";
import CreatePlan from "./pages/admin/CreatePlan.js";
import UpdatePlan from "./pages/admin/UpdatePlan.js";
import AllWithdrawRequests from "./pages/admin/AllWithdrawRequests.js";
import WithdrawRequestDetails from "./pages/admin/WithdrawRequestDetails.js";
import UpdateBarcode from "./pages/admin/UpdateBarcode.js";
import TeamTree from "./pages/dashboard/TeamTree.js";
import PlanDetails from "./pages/PlanDetails.js";
import ForgotPassword from "./pages/ForgotPassword.js";
import ResetPassword from "./pages/ResetPassword.js";
// import moment from "moment";

function App() {
  const { loading, isAuthenticated, userInfo } = useSelector(
    (state) => state.profile
  );

  const userId = userInfo?.user?._id;
  const userRole = userInfo?.user?.role;

  // const expirationTime = Math.floor(Date.now());
  // const date = moment(expirationTime).format("MMMM Do YYYY, hh:mm:ss A");
  // console.log("expirationTime", expirationTime);
  // console.log("expirationTime", date);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="main_wrapper">
          <ToastContainer />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/service" element={<Service />} />
            {/* <Route path="/social-activities" element={<SocialActivities />} /> */}
            <Route path="/plans" element={<Plans />} />
            <Route path="/plan/:id" element={<PlanDetails />} />
            {/* <Route path="/benefits" element={<Benefits />} /> */}
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />

            {/* AUTHENTICATED ROUTES START */}
            <Route
              path="/user-dashboard"
              element={
                isAuthenticated && userId ? <Outlet /> : <RedirectSpinner />
              }
            >
              <Route path="" element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
              <Route path="kyc" element={<Kyc />} />
              <Route path="direct-team" element={<DirectTeam />} />
              <Route path="team-list" element={<TeamList />} />
              <Route path="team-tree" element={<TeamTree />} />
              <Route path="activate-my-id" element={<ActivateId />} />
              <Route
                path="activate-downline-id"
                element={<ActivateDownlineId />}
              />
              <Route path="add-fund" element={<AddFund />} />
              <Route path="withdrawal-request" element={<WithdrawReq />} />
              <Route path="deposite-history" element={<DepositHistory />} />
              <Route path="withdrawal-history" element={<WithdrawHistory />} />
              <Route
                path="transaction-history"
                element={<TransactionHistory />}
              />
              <Route path="auto-pool-income" element={<AutoPoolIncome />} />
              <Route
                path="direct-sponsor-income"
                element={<DirectSponsorIncome />}
              />
              <Route path="level-income" element={<LevelIncome />} />
              <Route path="turnover-income" element={<CompanyTurnover />} />
              <Route path="reward-income" element={<RewardIncome />} />
            </Route>
            {/* AUTHENTICATED ROUTES ENDS */}

            {/* ADMIN ROUTES START */}
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route
              path="/admin"
              element={
                isAuthenticated && userRole === 10 ? (
                  <Outlet />
                ) : (
                  <RedirectSpinner />
                )
              }
            >
              <Route path="" element={<AdminDashboard />} />
              <Route path="all-users" element={<AllUsersList />} />
              <Route path="user/:id" element={<UserDetails />} />
              <Route path="fund-add-requests" element={<AddFundReqList />} />
              <Route
                path="fund-add-request/:id"
                element={<AddFundReqDetails />}
              />
              <Route path="wallets-list" element={<WalletsList />} />
              <Route path="wallet/:id" element={<WalletDetails />} />
              <Route path="kyc-list" element={<KycReqList />} />
              <Route path="kyc/:id" element={<KycReqDetails />} />
              <Route path="all-plans" element={<AllPlanList />} />
              <Route path="create-plan" element={<CreatePlan />} />
              <Route path="plan/:id" element={<UpdatePlan />} />
              <Route path="withdraw-requests" element={<AllWithdrawRequests />} />
              <Route path="withdrawReq/:id" element={<WithdrawRequestDetails />} />
              <Route path="update-barcode" element={<UpdateBarcode />} />
            </Route>
            {/* ADMIN ROUTES ENDS */}

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      )}
    </Fragment>
  );
}

export default App;
