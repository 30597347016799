export const PLAN_LIST_REQUEST = "PLAN_LIST_REQUEST";
export const PLAN_LIST_SUCCESS = "PLAN_LIST_SUCCESS";
export const PLAN_LIST_FAIL = "PLAN_LIST_FAIL";

export const BUY_PLAN_REQUEST = "BUY_PLAN_REQUEST";
export const BUY_PLAN_SUCCESS = "BUY_PLAN_SUCCESS";
export const BUY_PLAN_FAIL = "BUY_PLAN_FAIL";

export const PURCHAGE_LIST_REQUEST = "PURCHAGE_LIST_REQUEST";
export const PURCHAGE_LIST_SUCCESS = "PURCHAGE_LIST_SUCCESS";
export const PURCHAGE_LIST_FAIL = "PURCHAGE_LIST_FAIL";

export const CREATE_PLAN_ADMIN_REQUEST = "CREATE_PLAN_ADMIN_REQUEST";
export const CREATE_PLAN_ADMIN_SUCCESS = "CREATE_PLAN_ADMIN_SUCCESS";
export const CREATE_PLAN_ADMIN_FAIL = "CREATE_PLAN_ADMIN_FAIL";

export const MY_PURCHASED_PLAN_REQUEST = "MY_PURCHASED_PLAN_REQUEST";
export const MY_PURCHASED_PLAN_SUCCESS = "MY_PURCHASED_PLAN_SUCCESS";
export const MY_PURCHASED_PLAN_FAIL = "MY_PURCHASED_PLAN_FAIL";

export const PLAN_DETAILS_REQUEST = "PLAN_DETAILS_REQUEST";
export const PLAN_DETAILS_SUCCESS = "PLAN_DETAILS_SUCCESS";
export const PLAN_DETAILS_FAIL = "PLAN_DETAILS_FAIL";

export const UPDATE_PLAN_ADMIN_REQUEST = "UPDATE_PLAN_ADMIN_REQUEST";
export const UPDATE_PLAN_ADMIN_SUCCESS = "UPDATE_PLAN_ADMIN_SUCCESS";
export const UPDATE_PLAN_ADMIN_FAIL = "UPDATE_PLAN_ADMIN_FAIL";

export const DELETE_PLAN_ADMIN_REQUEST = "DELETE_PLAN_ADMIN_REQUEST";
export const DELETE_PLAN_ADMIN_SUCCESS = "DELETE_PLAN_ADMIN_SUCCESS";
export const DELETE_PLAN_ADMIN_FAIL = "DELETE_PLAN_ADMIN_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_STATE = "CLEAR_STATE";
export const CLEAR_CREATE_UPDATE_STATE = "CLEAR_CREATE_UPDATE_STATE";
