import React, { Fragment, useEffect } from "react";
import "../../assets/styles/depositeHistory.scss";
import "../../assets/styles/tableDataStyle.scss";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import { Link, useNavigate } from "react-router-dom";
import { BsPlus, BsSearch } from "react-icons/bs";
import usePaginate from "../../assets/hooks/usePaginate";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import CustomInput from "../../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import {
  clearFundErrors,
  getDepositHistory,
} from "../../redux/action/fundAction";
import moment from "moment";
import NotFoundCard from "../admin/components/NotFoundCard";

const DepositHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // GET DEPOSTE HISTORY START
  // GET DEPOSTE HISTORY START
  const { loading, fund, error } = useSelector((state) => state.depositHistory);

  const depositeHistory = fund?.fundAddReq;

  useEffect(() => {
    dispatch(getDepositHistory());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearFundErrors());
    }

    if (fund?.success) {
      toast.success(fund?.message);
    }
  }, [dispatch, error, fund?.success, fund?.message]);

  // GET DEPOSTE HISTORY ENDS
  // GET DEPOSTE HISTORY ENDS

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(depositeHistory);
  // Pagination Hook Ends
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <DashLayout>
          <div className="directTeam_wrapper">
            <div className="addFund">
              <Link to="/user-dashboard/add-fund">
                <BsPlus /> ADD NEW REQUEST
              </Link>
            </div>

            {!depositeHistory?.length ? (
              <NotFoundCard
                text="Fund not added previously, please create a transaction or recharge your wallet."
                showAction={true}
                actionTitle="Recharge your walllet"
                onClick={() => navigate("/user-dashboard/add-fund")}
                actionIcon=""
                txtColor="red"
              />
            ) : (
              <DashCard className="direct_team" title={"Fund Deposit History"}>
                <CustomInput
                  icon={<BsSearch />}
                  type="search"
                  //   onChange=""
                  placeholder="Search By Transaction Id or Amount..."
                  name="searchString"
                  //   value=""
                  //   impStar=""
                  //   readOnly=""
                  borderColor="#f2f2f2"
                />
                <div className="table_data custonScroll">
                  <table className="list_table" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Transaction ID</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.reverse()?.map((item, idx) => {
                        const requestedDate = moment(item?.createdAt).format(
                          "MMMM DD YYYY[, at] hh:mm A"
                        );
                        return (
                          <tr key={idx}>
                            <td>
                              <p>{requestedDate}</p>
                            </td>
                            <td>
                              <p>{item?.transactionId}</p>
                            </td>
                            <td>
                              <p>₹ {item?.amount}</p>
                            </td>
                            <td
                              style={{
                                background:
                                  item?.status === "Payment Success"
                                    ? "green"
                                    : item?.status === "Pending"
                                    ? "yellow"
                                    : "red",

                                color:
                                  item?.status === "Payment Success"
                                    ? "#fff"
                                    : item?.status === "Pending"
                                    ? "#000"
                                    : "#fff",
                              }}
                            >
                              <p>
                                <b>{item?.status}</b>
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagination_sec">
                  <p className="entriesCount">
                    Showing {itemOffset + 1} to{" "}
                    {endOffset > depositeHistory?.length
                      ? depositeHistory?.length
                      : endOffset}{" "}
                    of {depositeHistory?.length} entries
                  </p>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </DashCard>
            )}
          </div>
        </DashLayout>
      )}
    </Fragment>
  );
};

export default DepositHistory;
