import React from "react";
import Helmet from "react-helmet";

const CustomHelmet = ({
  keywords,
  description,
  author,
  title,
  redirectLink,
  canonical,
}) => {
  return (
    <Helmet>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta name="author" content={author} />
      <link rel="canonical" href={canonical} />
      <meta http-equiv="refresh" content={redirectLink} />
      <title>{title}</title>
    </Helmet>
  );
};

CustomHelmet.defaultProps = {
  title: "Welcome to Indian Big Bull || Indian Big Bull",
  keywords: "Digital Agency, Agency, Digital Marketing, Startup, Marketing,  income tax, quickbooks, account, cpa, tax, accountant, cma, audit, tax return, payroll, cga, bookkeeping, chartered accountant, auditor, accountant salary, accounts payable, accounting software, financial accounting, corporation tax, cpas, the acountant, what is accounting, management accounting, cost accounting, tax preparation",
  description: "Baseline - Digital Agency HTML Template",
  author: "Team Indian Big Bull",
};

export default CustomHelmet;
