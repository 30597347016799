import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { defaultPic } from "../../../assets/images/images";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearState,
  updateAvatarAction,
  userProfile,
} from "../../../redux/action/userAction";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import ButtonLoader from "../../../components/ButtonLoader";

const MainDiv = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  > .close_modal {
    position: fixed;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid #f2f2f2;
    color: #f2f2f2;
    background-color: transparent;

    &:hover {
      border: 1px solid #f2f2f2;
      color: #121d40;
      background-color: #f2f2f2;
    }
  }

  > form {
    background-color: #121d40;
    border-radius: 16px;
    padding: 30px;
    box-sizing: border-box;
    min-width: 290px;

    @media screen and (max-width: 575px) {
      padding: 20px;
    }

    > img {
      width: 200px;
      height: 200px;
      border-left: 1px solid #f2f2f2;
      border-right: 1px solid #f2f2f2;
      border-radius: 100%;
      background: #081336;
      display: block;
      margin: 0 auto 20px;
      object-fit: contain;

      @media screen and (max-width: 575px) {
        width: 150px;
        height: 150px;
      }
    }

    > button {
      font-size: 16px;
      margin: 0 auto;
      width: fit-content;
      padding-right: 20px;
      padding-left: 20px;
    }

    > button.remove_pic_btn {
      background: transparent;
      color: #f2f2f2;
      font-size: 16px;
      border: none;
      display: block;
      margin: 20px auto 0;
      cursor: pointer;
      padding: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const UploadProfilePicForm = ({ setUploadProfileModal }) => {
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(defaultPic);

  const { loading, avatarInfo, error } = useSelector(
    (state) => state.updateAvatar
  );

  const handleInputChange = (e) => {
    if (e.target.name === "avatar") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const profileUploadSubmit = (e) => {
    e.preventDefault();
    if (avatar !== "") {
      dispatch(updateAvatarAction(avatar));
    } else {
      toast.error("Please Select a file...");
    }
  };

  useEffect(() => {
    if (avatarInfo?.success) {
      toast.success(avatarInfo?.message);

      setTimeout(() => {
        dispatch(clearState()); // CLEAR STATE AFTER SUCCESS MESSAGE
        setUploadProfileModal(false); // CLOSE UPDATE PROFILE PIC MODAL
        dispatch(userProfile()); // GET UPDATED USER DETAILS
      }, 1000);
    }
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [
    avatarInfo?.message,
    avatarInfo?.success,
    dispatch,
    error,
    setUploadProfileModal,
  ]);

  return (
    <MainDiv className="upload_profile_pic">
      {!loading && (
        <button
          className="close_modal"
          onClick={() => setUploadProfileModal(false)}
        >
          <IoClose />
        </button>
      )}
      <form encType="multipart/form-data">
        <img src={avatarPreview} alt="Alpha Regiment Avatar Preview" />
        <CustomInput
          type="file"
          name="avatar"
          accept="image/*"
          onChange={handleInputChange}
        />
        <CustomButton
          onClick={profileUploadSubmit}
          disabled={loading ? true : false}
          title={loading ? <ButtonLoader /> : "Upload"}
          tooltip="Upload profile picture"
        />
        {/* <button className="remove_pic_btn">Remove profile picture</button> */}
      </form>
    </MainDiv>
  );
};

export default UploadProfilePicForm;
