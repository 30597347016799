import React from "react";
import styled from "styled-components";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const Div = styled.div`
  padding: 20px 0;
  margin: 0 15px;
  display: flex;
  align-items: center;

  > .backArrow {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f2f2f2;
    font-size: 30px;
    transition: 0.4s;
    cursor: pointer;

    &:hover {
      background-color: #394b71;
      color: #dcca87;
    }
  }

  > .title {
    color: #f2f2f2;
    font-size: 25px;
    font-weight: 700;
    margin-left: 10px;
  }
`;

const PageTitle = ({ title }) => {
  const navigate = useNavigate();

  return (
    <Div className="goBack">
      <div className="backArrow" onClick={() => navigate(-1)}>
        <BiArrowBack />
      </div>
      <div className="title">{title}</div>
    </Div>
  );
};

export default PageTitle;
