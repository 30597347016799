import React from "react";
import "../../../assets/styles/adminLayout.scss";
import { logoMain } from "../../../assets/images/images";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../redux/action/userAction";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../context/userContext";

const AdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();

  // LOG OUT HANDLER
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="adminLayout_wrapper">
      <div className="headerM">
        <div className="container">
          <div className="header">
            <div className="logo">
              <Link to="/admin">
                <img src={logoMain} alt="main logo" />
              </Link>
            </div>
            <div className="links">
              {auth?.user?._id && (
                <div className="navlink">
                  <button title="Logout" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="childContainer">{children}</div>
    </div>
  );
};

export default AdminLayout;
