import React, { Fragment, useEffect } from "react";
import "../../assets/styles/KycReqList.scss";
import Loader from "../../components/Loader";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import usePaginate from "../../assets/hooks/usePaginate";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  clearKycErrors,
  clearKycMessage,
  getKycList,
} from "../../redux/action/kycAction";
import { toast } from "react-toastify";

const KycReqList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, kyc, error, message } = useSelector(
    (state) => state.allKycList
  );

  const kycList = kyc?.kyc;

  useEffect(() => {
    dispatch(getKycList());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearKycErrors());
    }

    if (kyc?.success && message) {
      toast.success(message);
      dispatch(clearKycMessage());
    }
  }, [error, dispatch, kyc?.success, message]);

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(kycList);
  // Pagination Hook Ends

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="kycs_list_wrapper">
            <div className="container">
              <PageTitle goBack={true} title="KYCs List" />

              <div className="kycs_content">
                <div className="table_data custonScroll">
                  <table className="list_table" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Aadhar No.</th>
                        <th>PAN No.</th>
                        <th>Bank Name</th>
                        <th>Acc Holder Name</th>
                        <th>Bank Acc No.</th>
                        <th>Bank IFSC</th>
                        <th>Bank Branch</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.reverse()?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <p>{item?.aadharCard}</p>
                            </td>
                            <td>
                              <p style={{ textTransform: "uppercase" }}>
                                {item?.panCard}
                              </p>
                            </td>
                            <td>
                              <p>{item?.bankPassbook?.bankName}</p>
                            </td>
                            <td>
                              <p>{item?.bankPassbook?.accountHolder}</p>
                            </td>
                            <td>
                              <p>{item?.bankPassbook?.accountNo}</p>
                            </td>
                            <td>
                              <p>{item?.bankPassbook?.bankIfsc}</p>
                            </td>
                            <td>
                              <p>{item?.bankPassbook?.bankBranch}</p>
                            </td>
                            <td
                              style={{
                                background: item?.isActive ? "green" : "red",
                              }}
                            >
                              <p>
                                <b>{item?.status}</b>
                              </p>
                            </td>
                            <td>
                              <div>
                                <button
                                  onClick={() =>
                                    navigate(`/admin/kyc/${item?._id}`)
                                  }
                                >
                                  <FaEye />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagination_sec">
                  <p className="entriesCount">
                    Showing {itemOffset + 1} to{" "}
                    {endOffset > kycList?.length ? kycList?.length : endOffset}{" "}
                    of {kycList?.length} entries
                  </p>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default KycReqList;
