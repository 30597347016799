import React, { Fragment } from "react";
import "../assets/styles/service.scss";
import Layout from "../components/Layout";
import { slider1 } from "../assets/images/images";
import BreadCrumb from "../components/BreadCrumb";
import Bottom from "../components/Bottom";
import { BsPersonCheckFill, BsUpcScan, BsFillHousesFill } from "react-icons/bs";
import { RiFocus2Line } from "react-icons/ri";
import { GiDreamCatcher, GiHealthCapsule } from "react-icons/gi";
import { useLocale } from "../context/locale";
import CustomHelmet from "../components/Helmet";

const Service = () => {
  const [t, chnageLang] = useLocale();

  return (
    <Fragment>
      <CustomHelmet
        title={`Our Services - Indian Big Bull`}
        canonical="https://indianbigbull.in/"
      />
      <Layout>
        <BreadCrumb img={slider1} title="Services" />

        <section className="service_page">
          <div className="container">
            <div className="titleMain">
              <p>{t("service")}</p>
              <h2>{t("recentService")}</h2>
              <Bottom center />
            </div>

            <div className="serviceContent">
              <div className="serviceCard">
                <div className="overlap"></div>
                <div className="icon">
                  <BsPersonCheckFill />
                </div>
                <div className="title">BELIEVE IN YOURSELF</div>
                <div className="text">BELIEVE IN YOURSELF.</div>
              </div>
              <div className="serviceCard">
                <div className="overlap"></div>
                <div className="icon">
                  <RiFocus2Line />
                </div>
                <div className="title">FOCUS</div>
                <div className="text">you'll land among the stars.</div>
              </div>
              <div className="serviceCard">
                <div className="overlap"></div>
                <div className="icon">
                  <GiDreamCatcher />
                </div>
                <div className="title">DREAM ENORMOUSLY BIG</div>
                <div className="text">DREAM ENORMOUSLY BIG.</div>
              </div>
              <div className="serviceCard">
                <div className="overlap"></div>
                <div className="icon">
                  <BsUpcScan />
                </div>
                <div className="title">ACHIEVE</div>
                <div className="text">ACHIEVE what they think they can do.</div>
              </div>
              <div className="serviceCard">
                <div className="overlap"></div>
                <div className="icon">
                  <GiHealthCapsule />
                </div>
                <div className="title">HEALTH PRODUCTS</div>
                <div className="text">HEALTH PRODUCTS</div>
              </div>
              <div className="serviceCard">
                <div className="overlap"></div>
                <div className="icon">
                  <BsFillHousesFill />
                </div>
                <div className="title">HOUSE HOLD</div>
                <div className="text">HOUSE HOLD ITEMS.</div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};

export default Service;
