import { axiosInstance } from "../../utils/axiosConfig";
import {
  BUY_PLAN_FAIL,
  BUY_PLAN_REQUEST,
  BUY_PLAN_SUCCESS,
  CLEAR_CREATE_UPDATE_STATE,
  CLEAR_ERRORS,
  CLEAR_STATE,
  CREATE_PLAN_ADMIN_FAIL,
  CREATE_PLAN_ADMIN_REQUEST,
  CREATE_PLAN_ADMIN_SUCCESS,
  DELETE_PLAN_ADMIN_FAIL,
  DELETE_PLAN_ADMIN_REQUEST,
  DELETE_PLAN_ADMIN_SUCCESS,
  MY_PURCHASED_PLAN_FAIL,
  MY_PURCHASED_PLAN_REQUEST,
  MY_PURCHASED_PLAN_SUCCESS,
  PLAN_DETAILS_FAIL,
  PLAN_DETAILS_REQUEST,
  PLAN_DETAILS_SUCCESS,
  PLAN_LIST_FAIL,
  PLAN_LIST_REQUEST,
  PLAN_LIST_SUCCESS,
  PURCHAGE_LIST_FAIL,
  PURCHAGE_LIST_REQUEST,
  PURCHAGE_LIST_SUCCESS,
  UPDATE_PLAN_ADMIN_FAIL,
  UPDATE_PLAN_ADMIN_REQUEST,
  UPDATE_PLAN_ADMIN_SUCCESS,
} from "../constant/planConstant";

// GET PLAN LIST - GET REQUEST
export const getPlanList = () => async (dispatch) => {
  try {
    dispatch({
      type: PLAN_LIST_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/package-list`, config);

    dispatch({
      type: PLAN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PLAN_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET PLAN DETAILS - GET REQUEST
export const getPlanDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PLAN_DETAILS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/plan/${id}`, config);

    dispatch({
      type: PLAN_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PLAN_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CREATE PLAN  - POST REQUEST
export const createPlan =
  (title, description, price, discount, features, thumbnail) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_PLAN_ADMIN_REQUEST,
      });

      const localStorageToken = localStorage.getItem("jwtToken")
        ? JSON.parse(localStorage.getItem("jwtToken"))
        : "";

      const config = {
        headers: {
          "content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const stringFeatures = JSON.stringify(features);

      const { data } = await axiosInstance.post(
        `/create-package`,
        {
          title,
          description,
          price,
          discount,
          features: stringFeatures,
          thumbnail,
        },
        config
      );

      dispatch({
        type: CREATE_PLAN_ADMIN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_PLAN_ADMIN_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE PLAN  - PUT REQUEST
export const updatePlan =
  (id, title, description, price, discount, features, thumbnail) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_PLAN_ADMIN_REQUEST,
      });

      const localStorageToken = localStorage.getItem("jwtToken")
        ? JSON.parse(localStorage.getItem("jwtToken"))
        : "";

      const config = {
        headers: {
          "content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const stringFeatures = JSON.stringify(features);

      const { data } = await axiosInstance.put(
        `/plan/${id}`,
        {
          title,
          description,
          price,
          discount,
          features: stringFeatures,
          thumbnail,
        },
        config
      );

      dispatch({
        type: UPDATE_PLAN_ADMIN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PLAN_ADMIN_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// DELETE PLAN  - DELETE REQUEST
export const deletePlan = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PLAN_ADMIN_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/plan/${id}`, config);

    dispatch({
      type: DELETE_PLAN_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PLAN_ADMIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// PURCHASE PLAN  - POST REQUEST
export const purchasePlan = (packageId, buyAmount) => async (dispatch) => {
  try {
    dispatch({
      type: BUY_PLAN_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      `/create-buyPackage`,
      { packageId, buyAmount },
      config
    );

    dispatch({
      type: BUY_PLAN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BUY_PLAN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// ALL PURCHASED PLAN LIST  - GET REQUEST -- ADMIN
export const purchaseList = () => async (dispatch) => {
  try {
    dispatch({
      type: PURCHAGE_LIST_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/buyPackage-list`, config);

    dispatch({
      type: PURCHAGE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHAGE_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET MY PURCHASED LIST  - GET REQUEST
export const myPurchaseList = () => async (dispatch) => {
  try {
    dispatch({
      type: MY_PURCHASED_PLAN_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/my-buyPackage`, config);

    dispatch({
      type: MY_PURCHASED_PLAN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MY_PURCHASED_PLAN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};

// CLEAR CREATE AND UPDATE STATE ACTION FUNCTION
export const clearCreateUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_CREATE_UPDATE_STATE });
};
