import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/withdrawalReq.scss";
import CustomButton from "../../components/CustomButton";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import { BsCurrencyRupee } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotFoundCard from "../admin/components/NotFoundCard";
import Loader from "../../components/Loader";
import {
  clearWalletErrors,
  clearWalletMessage,
  getMyWallet,
} from "../../redux/action/walletAction";
import { toast } from "react-toastify";
import ButtonLoader from "../../components/ButtonLoader";
import {
  clearWithdrawErrors,
  clearWithdrawState,
  sendWithdrawRequest,
} from "../../redux/action/withdrawalReqAction";
import { BiMailSend } from "react-icons/bi";
import PopupPrompt from "../../components/PopupPrompt";
import { getKyc } from "../../redux/action/userAction";
import { clearKycErrors } from "../../redux/action/kycAction";

const WithdrawReq = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPrompt, setshowPrompt] = useState(false);
  const [amount, setAmount] = useState();

  // USER DETAILS START
  const { userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;
  // USER DETAILS ENDS

  // GET MY WALLET START
  // GET MY WALLET START
  const {
    loading: walletLoading,
    wallet,
    message: walletMessage,
    error: walletError,
  } = useSelector((state) => state.myWallet);

  const myWallet = wallet?.wallet;

  useEffect(() => {
    dispatch(getMyWallet());
  }, [dispatch]);

  useEffect(() => {
    if (walletError) {
      toast.error(walletError);
      dispatch(clearWalletErrors());
    }

    if (wallet?.success && walletMessage) {
      toast.success(walletMessage);
      dispatch(clearWalletMessage());
    }
  }, [walletError, dispatch, wallet?.success, walletMessage]);

  // GET MY WALLET ENDS
  // GET MY WALLET ENDS

  // SEND WITHDRAWAL REQUEST START
  // SEND WITHDRAWAL REQUEST START
  const { loading, request, error } = useSelector(
    (state) => state.sendWithdrawReq
  );

  const handleSendWithdrawRequest = () => {
    if (!amount) {
      toast.error("Please fill a valid amount!");
    } else if (amount < 600) {
      toast.error("You can deduct minimum 600 rupees!");
      setAmount(0);
    } else {
      dispatch(sendWithdrawRequest(amount));
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearWithdrawErrors());
    }

    if (request?.success) {
      toast.success(request?.message);
      dispatch(clearWithdrawState());
      setAmount("");
      setshowPrompt(true);
    }
  }, [error, dispatch, request?.success, request?.message]);

  // SEND WITHDRAWAL REQUEST ENDS
  // SEND WITHDRAWAL REQUEST ENDS

  // Remaining wallet balance after deduction
  let remainingBalance = 0;
  if (amount > myWallet?.walletBalance) {
    toast.warning("You can not deduct more than your wallet balance.");
    setAmount(0);
  } else {
    remainingBalance = myWallet?.walletBalance - amount;
  }

  // GET KYC DETAILS START
  // GET KYC DETAILS START
  const {
    loading: kycLoading,
    kyc: myKyc,
    error: kycError,
  } = useSelector((state) => state.getUserKyc);

  const userKyc = myKyc?.userKyc;

  console.log("userKyc?.isActive", userKyc?.isActive);

  useEffect(() => {
    dispatch(getKyc());
  }, [dispatch]);

  useEffect(() => {
    if (kycError) {
      dispatch(clearKycErrors());
      toast.error(kycError);
    }

    // if (myKyc?.success) {
    //   toast.success(myKyc?.message);
    // }
  }, [kycError, dispatch]);

  // GET KYC DETAILS ENDS
  // GET KYC DETAILS ENDS

  return (
    <Fragment>
      <DashLayout>
        {user?.paidUser ? (
          <>
            {walletLoading || kycLoading ? (
              <Loader />
            ) : (
              <>
                {!userKyc?.isActive ? (
                  <NotFoundCard
                    text="Your Kyc is not updated or status still pending."
                    showAction={true}
                    actionTitle="Update KYC/Check KYC Status"
                    onClick={() => navigate("/user-dashboard/kyc")}
                    actionIcon=""
                  />
                ) : (
                  <div className="activateId_wrapper">
                    <DashCard
                      className="withdraw_req"
                      title={"Withdrawal Request"}
                    >
                      <CustomInput
                        label="Your Wallet Balance"
                        icon={<BsCurrencyRupee />}
                        placeholder="Wallet balance"
                        name="walletBalance"
                        value={myWallet?.walletBalance}
                        readOnly={true}
                        borderColor=""
                        bacgroundColor={`grey`}
                      />
                      <CustomInput
                        label="Amount (Min Rs. 1000): "
                        type="number"
                        icon={<BsCurrencyRupee />}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Amount (Min Rs. 1000): "
                        name="withdrawamount"
                        value={amount}
                        impStar="*"
                        readOnly={false}
                        borderColor="#f2f2f2"
                      />

                      {amount > 0 && (
                        <div className="deductionMessage">
                          After deduction your wallet amount will remain only{" "}
                          {remainingBalance} rupees.
                        </div>
                      )}

                      <CustomButton
                        className={`purchagePack_btn ${
                          loading ? "disabled" : ""
                        }`}
                        title="Send Request"
                        preIcon={loading ? <ButtonLoader /> : <BiMailSend />}
                        onClick={handleSendWithdrawRequest}
                        disabled={loading ? true : false}
                        tooltip="Click here to send withdraw request..."
                      />
                    </DashCard>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <NotFoundCard
            text="You have not any purchased plan yet, please purchage any plan to withdraw your earning."
            showAction={true}
            actionTitle="Purchage Plan Now"
            onClick={() => navigate("/user-dashboard/activate-my-id")}
            actionIcon=""
          />
        )}
      </DashLayout>

      {/* Popup Prompt Start */}
      {showPrompt && (
        <PopupPrompt
          cancelBtnTxt="Okay"
          cancelOnclick={() => setshowPrompt(false)}
          title="Withdrawal request sent successfully. Be patient, your money is absolutely safe with us and we are checking your withdrawal request. We'll process your money in your account shortly."
          lightTitle={true}
        />
      )}
      {/* Popup Prompt Ends */}
    </Fragment>
  );
};

export default WithdrawReq;
