import React from "react";
import "../assets/styles/subHeader.scss";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaYoutube,
  FaLinkedinIn,
  FaInstagram,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";
import { useAuth } from "../context/userContext";
import { useLocale } from "../context/locale";

const SubHeader = () => {
  const [auth] = useAuth();
  const user = auth?.user;
  const [t, chnageLang] = useLocale();

  return (
    <div className="subheader_main">
      <div className="container">
        <div className="subheader_content">
          <div className="contact_details">
            <Link to={`tel:${process.env.REACT_APP_PHONE}`}>
              <FaPhoneAlt /> {process.env.REACT_APP_PHONE}
            </Link>
            <Link to={`mailto:${process.env.REACT_APP_EMAIL}`}>
              <FaEnvelope /> {process.env.REACT_APP_EMAIL}
            </Link>
          </div>
          <div className="social_details">
            <div className="social_icons">
              {/* <Link to={process.env.REACT_APP_FACEBOOK} target="_blank">
                <FaFacebookF />
              </Link>
              <Link to={process.env.REACT_APP_YOUTUBE} target="_blank">
                <FaYoutube />
              </Link>
              <Link to={process.env.REACT_APP_LINKEDIN} target="_blank">
                <FaLinkedinIn />
              </Link> */}
              <Link to={process.env.REACT_APP_INSTA} target="_blank">
                <FaInstagram />
              </Link>
            </div>
            <div className="loginBtn">
              {user?._id ? (
                <Link to="/user-dashboard">{t("dashboard")}</Link>
              ) : (
                <Link to="/login">Login</Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
