import React from "react";
import "../assets/styles/clientSays.scss";
import {
  client1,
  client2,
  client3,
  client4,
  clientBanner,
} from "../assets/images/images";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import Bottom from "./Bottom";
import { useLocale } from "../context/locale";

const ClientSays = () => {
  const [t, chnageLang] = useLocale();

  const clientVoice = [
    {
      img: client1,
      heading: "SHIRLEY SMITH",
      txt: "You must activate your id within 72 hours or else your id will be blocked.",
    },
    {
      img: client2,
      heading: "MIKE SMITH",
      txt: "Indian Big Bull Plan is world best plan. Hight income, instant payout 24×7 hour.",
    },
    {
      img: client3,
      heading: "MANOJ YADAV",
      txt: "Indian Big Bull is a very big foundation. It's gives donation to poor family at marriage time.",
    },
    {
      img: client4,
      heading: "MIKE HARDSON",
      txt: "Welcome to Indian Big Bull If we talk about the company’s profile, we have been very good.",
    },
  ];

  return (
    <div className="clientsays_main">
      <div className="container">
        <div className="title">
          <p>{t("clientFeedback")}</p>
          <h2>{t("whatClientSay")}</h2>
          <Bottom center />
        </div>
        <div className="content">
          <div className="imgSec">
            <img src={clientBanner} alt="clientBanner" />
          </div>
          <div className="sliderSec">
            <Swiper
              className="myXSwiper"
              navigation={false}
              modules={[Autoplay]}
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
            >
              {clientVoice?.map((item, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <div className="clientVoice">
                      <div className="content">
                        <img src={item?.img} alt="testimonial avatar" />
                        <p>{item?.txt}</p>
                        <h1>{item?.heading}</h1>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientSays;
