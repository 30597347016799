import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/kycReqDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import ButtonLoader from "../../components/ButtonLoader";
import { clearErrors, getUserDetails } from "../../redux/action/userAction";
import { toast } from "react-toastify";
import { defaultPic } from "../../assets/images/images";
import CustomButton from "../../components/CustomButton";
import { useParams } from "react-router-dom";
import {
  clearKycErrors,
  clearKycMessage,
  getKycDetails,
  updateKycAdmin,
} from "../../redux/action/kycAction";
import CustomSelect from "../../components/CustomSelect";

const KycReqDetails = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");

  const { id: kycId } = useParams();

  // GET KYC DETAILS START
  // GET KYC DETAILS START
  const { loading, kyc, error, message } = useSelector(
    (state) => state.kycDetails
  );

  const kycDetails = kyc?.kyc;


  useEffect(() => {
    if (kycId) {
      dispatch(getKycDetails(kycId));
    }
  }, [dispatch, kycId]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearKycErrors());
    }

    if (kyc?.success && message) {
      toast.success(message);
      dispatch(clearKycMessage());
    }
  }, [error, dispatch, kyc?.success, message]);

  // GET KYC DETAILS ENDS
  // GET KYC DETAILS ENDS

  // GET USER DETAILS START
  // GET USER DETAILS START
  const {
    loading: userLoading,
    user,
    error: userError,
  } = useSelector((state) => state.userDetails);

  const userDetails = user?.userDetails;

  useEffect(() => {
    if (kycDetails?.forUser) {
      dispatch(getUserDetails(kycDetails?.forUser));
    }
  }, [dispatch, kycDetails?.forUser]);

  useEffect(() => {
    if (userError) {
      toast.error(userError);
      dispatch(clearErrors());
    }
  }, [userError, dispatch]);
  // GET USER DETAILS ENDS
  // GET USER DETAILS ENDS

  // UPDATE KYC START
  // UPDATE KYC START
  const {
    loading: updatedKycLoading,
    kyc: updatedKyc,
    message: updatedKycMessage,
    error: updatedKycError,
  } = useSelector((state) => state.updateKyc);


  const updateKycHandler = (isActive, status) => {
    dispatch(updateKycAdmin(kycId, isActive, status));
  };

  useEffect(() => {
    if (updatedKycError) {
      toast.error(updatedKycError);
      dispatch(clearKycErrors());
    }

    if (updatedKyc?.success && updatedKycMessage) {
      toast.success(updatedKycMessage);
      setStatus("");
      dispatch(clearKycMessage());
      dispatch(getKycDetails(kycId));
    }
  }, [
    dispatch,
    updatedKycError,
    updatedKyc?.success,
    updatedKycMessage,
    kycId,
  ]);

  // UPDATE KYC ENDS
  // UPDATE KYC ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="kyc_details_wrapper">
            <div className="container">
              <PageTitle
                goBack={true}
                title={`${userDetails?.fullName}'s KYC`}
              />

              <div className="userKyc_content">
                <div className="walllet_onr_pic">
                  <img
                    src={
                      userDetails?.avatar?.url
                        ? userDetails?.avatar?.url
                        : defaultPic
                    }
                    alt={userDetails?.fullName}
                  />
                </div>
                <h2>
                  <span>Wallet Owner:</span>
                  <span>
                    {userLoading ? <ButtonLoader /> : userDetails?.fullName}
                  </span>
                </h2>
                <div className="walletDetails">
                  <p>
                    <span>Aadhar No</span>
                    <span>{kycDetails?.aadharCard}</span>
                  </p>
                  <p>
                    <span>PAN No</span>
                    <span style={{ textTransform: "uppercase" }}>
                      {kycDetails?.panCard}
                    </span>
                  </p>
                  <p>
                    <span>Acc Holder Name</span>
                    <span style={{ textTransform: "capitalize" }}>
                      {kycDetails?.bankPassbook?.accountHolder}
                    </span>
                  </p>
                  <p>
                    <span>Account No</span>
                    <span>{kycDetails?.bankPassbook?.accountNo}</span>
                  </p>
                  <p>
                    <span>Bank Name</span>
                    <span>{kycDetails?.bankPassbook?.bankName}</span>
                  </p>
                  <p>
                    <span>Bank Branch</span>
                    <span>{kycDetails?.bankPassbook?.bankBranch}</span>
                  </p>
                  <p>
                    <span>Bank IFSC Code</span>
                    <span style={{ textTransform: "uppercase" }}>
                      {kycDetails?.bankPassbook?.bankIfsc}
                    </span>
                  </p>
                </div>

                <div className="walletAction">
                  <h2>
                    KYC Status:{" "}
                    <span
                      style={{
                        background: kycDetails?.isActive ? "green" : "red",
                        color: kycDetails?.isActive ? "#f2f2f2" : "#f2f2f2",
                        borderRadius: "5px",
                        padding: "4px 10px",
                      }}
                    >
                      {kycDetails?.isActive ? "Active" : `Inactive`}
                    </span>
                  </h2>
                  <CustomSelect
                    label="Select Status of the payment"
                    name="status"
                    onChange={(e) => setStatus(e.target.value)}
                    optionArray={[
                      { option: "Approved" },
                      {
                        option:
                          "Unauthorised Details, Please provide original details.",
                      },
                    ]}
                    impStar="*"
                    value={status}
                    readOnly={false}
                    borderColor="#f2f2f2"
                  />
                  <div className="btnGrp">
                    <CustomButton
                      className={`verifyBtn ${
                        status !== "Approved" ? "disabled" : ""
                      }`}
                      title={
                        updatedKycLoading ? (
                          <ButtonLoader />
                        ) : kycDetails?.isActive ? (
                          "Inactive KYC"
                        ) : (
                          "Activate KYC"
                        )
                      }
                      onClick={() => updateKycHandler(true, status)}
                      disabled={updatedKycLoading ? true : false}
                      tooltip="Activate/Inactive User Wallet."
                    />
                    <CustomButton
                      className={`wallet_inactive_Btn ${
                        !status
                          ? "disabled"
                          : status === "Approved"
                          ? "disabled"
                          : ""
                      }`}
                      title={
                        updatedKycLoading ? (
                          <ButtonLoader />
                        ) : kycDetails?.isActive ? (
                          "Inactive KYC"
                        ) : (
                          "Activate KYC"
                        )
                      }
                      onClick={() => updateKycHandler(false, status)}
                      disabled={updatedKycLoading ? true : false}
                      tooltip="Activate/Inactive User Wallet."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default KycReqDetails;
