import React, { Fragment, useEffect } from "react";
import "../../assets/styles/withdrawHistory.scss";
import "../../assets/styles/tableDataStyle.scss";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import usePaginate from "../../assets/hooks/usePaginate";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotFoundCard from "../admin/components/NotFoundCard";
import {
  clearWithdrawErrors,
  getMyWithdrawReqList,
} from "../../redux/action/withdrawalReqAction";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import moment from "moment";

const WithdrawHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // USER DETAILS START
  const { userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;
  // USER DETAILS ENDS

  // GET MY WITHDRAW REQUEST HISTORY START
  // GET MY WITHDRAW REQUEST HISTORY START
  const { loading, request, error } = useSelector(
    (state) => state.getMyWithdrawReq
  );

  const myHistory = request?.withdrawReqList?.reverse();

  useEffect(() => {
    dispatch(getMyWithdrawReqList());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearWithdrawErrors());
    }
  }, [dispatch, error]);
  // GET MY WITHDRAW REQUEST HISTORY ENDS
  // GET MY WITHDRAW REQUEST HISTORY ENDS

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(myHistory);
  // Pagination Hook Ends
  return (
    <Fragment>
      <DashLayout>
        {user?.paidUser ? (
          <>
            {loading ? (
              <Loader />
            ) : (
              <>
                {myHistory?.length > 0 ? (
                  <div className="withdraw_history_wrapper">
                    <DashCard
                      className="withdraw_history"
                      title={"Withdrawal History"}
                    >
                      <CustomInput
                        icon={<BsSearch />}
                        type="search"
                        //   onChange=""
                        placeholder="Search By Name..."
                        name="searchString"
                        //   value=""
                        //   impStar=""
                        //   readOnly=""
                        borderColor="#f2f2f2"
                      />
                      <div className="table_data custonScroll">
                        <table className="list_table" cellSpacing="0">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Transaction ID</th>
                              <th>Requested Amount</th>
                              <th>TDS</th>
                              <th>Admin Charges</th>
                              <th>Net Credited Amount</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems?.map((item, idx) => {
                              const txnDate = moment(item?.createdAt).format(
                                "MMMM Do YYYY, hh:mm:ss A"
                              );
                              return (
                                <tr key={item?._id}>
                                  <td>
                                    <p>{txnDate}</p>
                                  </td>
                                  <td>
                                    <p> {item?.txnId ? item?.txnId : "--"} </p>
                                  </td>
                                  <td>
                                    <p>₹{item?.amount}</p>
                                  </td>
                                  <td>
                                    <p>{item?.tds ? `₹${item?.tds}` : "--"}</p>
                                  </td>
                                  <td>
                                    <p>
                                      {item?.adminCharge
                                        ? `₹${item?.adminCharge}`
                                        : "--"}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      {item?.creditedAmount
                                        ? `₹${item?.creditedAmount}`
                                        : "--"}{" "}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className={
                                        item?.status === "Completed"
                                          ? "green"
                                          : item?.status === "Pending"
                                          ? "yellow"
                                          : "red"
                                      }
                                    >
                                      <b>{item?.status}</b>
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="pagination_sec">
                        <p className="entriesCount">
                          Showing {itemOffset + 1} to{" "}
                          {endOffset > myHistory?.length
                            ? myHistory?.length
                            : endOffset}{" "}
                          of {myHistory?.length} entries
                        </p>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<RxDoubleArrowRight />}
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={1}
                          marginPagesDisplayed={2}
                          pageCount={pageCount}
                          previousLabel={<RxDoubleArrowLeft />}
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </DashCard>
                  </div>
                ) : (
                  <NotFoundCard
                    text="You have not any withdrawal transaction."
                    showAction={true}
                    actionTitle="Withdraw Amount"
                    onClick={() => navigate("/user-dashboard/withdrawal-request")}
                    actionIcon=""
                  />
                )}
              </>
            )}
          </>
        ) : (
          <NotFoundCard
            text="You have not any purchased plan yet, please purchage any plan to see withdraw history."
            showAction={true}
            actionTitle="Purchage Plan Now"
            onClick={() => navigate("/user-dashboard/activate-my-id")}
            actionIcon=""
          />
        )}
      </DashLayout>
    </Fragment>
  );
};

export default WithdrawHistory;
