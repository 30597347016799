import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/addFund.scss";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import { BsCurrencyRupee } from "react-icons/bs";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  addFundRequest,
  clearFundErrors,
  clearFundState,
} from "../../redux/action/fundAction";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import {
  clearBarcodeErrors,
  getBarcodeRequest,
} from "../../redux/action/barcodeAction";

const AddFund = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [paymentSnap, setPaymentSnap] = useState("");
  const [previewSnap, setPreviewSnap] = useState("");
  const [fundData, setFundData] = useState({
    amount: "",
    transactionId: "",
    remarks: "",
  });

  const { amount, transactionId, remarks } = fundData;

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;

    if (name === "paymentSnap") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreviewSnap(reader.result);
          setPaymentSnap(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setFundData({
        ...fundData,
        [name]: value,
      });
    }
  };

  // ADD FUND START
  // ADD FUND START
  const { loading, fund, error } = useSelector((state) => state.addFund);

  const addFundHandler = () => {
    dispatch(addFundRequest(amount, transactionId, remarks, paymentSnap));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearFundErrors());
    }

    if (fund?.success) {
      toast.success(fund?.message);
      dispatch(clearFundState());
      navigate("/user-dashboard/deposite-history");
    }
  }, [error, dispatch, fund?.success, fund?.message, navigate]);
  // ADD FUND ENDS
  // ADD FUND ENDS

  // GET BARCODE START
  // GET BARCODE START
  const {
    loading: myBarcodeLoading,
    barcode: myBarcode,
    error: myBarcodeError,
  } = useSelector((state) => state.getBarcode);

  const barCodeImg = myBarcode?.barcode;

  useEffect(() => {
    dispatch(getBarcodeRequest());
  }, [dispatch]);

  useEffect(() => {
    if (myBarcodeError) {
      toast.error(myBarcodeError);
      dispatch(clearBarcodeErrors());
    }
  }, [myBarcodeError, dispatch]);

  // GEt BARCODE ENDS
  // GEt BARCODE ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <DashLayout>
          {showForm ? (
            <div className="activateId_wrapper">
              <DashCard className="addFunds" title={"Add Fund"}>
                <CustomInput
                  label="Amount"
                  type="number"
                  onChange={handleInputChange}
                  icon={<BsCurrencyRupee />}
                  placeholder="Amount"
                  name="amount"
                  value={amount}
                  impStar="*"
                  readOnly={false}
                  borderColor="#f2f2f2"
                />
                <CustomInput
                  label="Transaction ID"
                  type="text"
                  onChange={handleInputChange}
                  placeholder="Transaction ID"
                  name="transactionId"
                  value={transactionId}
                  impStar="*"
                  readOnly={false}
                  borderColor="#f2f2f2"
                />
                <CustomInput
                  label="Paid by (app name Ex: phonePe/GooglePay/Paytm)"
                  type="text"
                  onChange={handleInputChange}
                  placeholder="ex: Paid by phonePe/GooglePay/Paytm"
                  name="remarks"
                  value={remarks}
                  impStar="*"
                  readOnly={false}
                  borderColor="#f2f2f2"
                />

                <div className="select_file">
                  <CustomInput
                    label="Upload Payment Screenshot"
                    type="file"
                    name="paymentSnap"
                    onChange={handleInputChange}
                    impStar="*"
                    readOnly={false}
                    borderColor="#f2f2f2"
                    accept="image/*"
                  />
                </div>

                {previewSnap && (
                  <img src={previewSnap} alt={previewSnap} width={150} />
                )}

                <CustomButton
                  className="purchagePack_btn"
                  title="Submit"
                  onClick={addFundHandler}
                  disabled={false}
                  tooltip="Click here to add funds in your wallet"
                />
              </DashCard>
            </div>
          ) : (
            <div className="barcodeWrapper">
              {myBarcodeLoading ? (
                "Barcode Loading..."
              ) : barCodeImg?.barCode?.url ? (
                <img src={barCodeImg?.barCode?.url} alt="barcode" />
              ) : (
                "Barcode Not Found..."
              )}

              <div className="impText">
                After transferring funds to the above mentioned{" "}
                <span>BarCode</span>, click on the success button given below.
                <CustomButton
                  title="Success"
                  onClick={() => setShowForm(true)}
                  tooltip="Click here after succussfully transferred the fund on the above barcode"
                />
              </div>
            </div>
          )}
        </DashLayout>
      )}
    </Fragment>
  );
};

export default AddFund;
