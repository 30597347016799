import { axiosInstance } from "../../utils/axiosConfig";
import {
  ACCEPT_WITHDRAW_REQ_FAIL,
  ACCEPT_WITHDRAW_REQ_REQUEST,
  ACCEPT_WITHDRAW_REQ_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_WITHDRAW_STATE,
  MY_WITHDRAW_REQ_LIST_FAIL,
  MY_WITHDRAW_REQ_LIST_REQUEST,
  MY_WITHDRAW_REQ_LIST_SUCCESS,
  SEND_WITHDRAW_REQ_FAIL,
  SEND_WITHDRAW_REQ_REQUEST,
  SEND_WITHDRAW_REQ_SUCCESS,
  WITHDRAW_REQ_DETAILS_FAIL,
  WITHDRAW_REQ_DETAILS_REQUEST,
  WITHDRAW_REQ_DETAILS_SUCCESS,
  WITHDRAW_REQ_LIST_FAIL,
  WITHDRAW_REQ_LIST_REQUEST,
  WITHDRAW_REQ_LIST_SUCCESS,
} from "../constant/withdrawalReqConstant";

// SEND WITHDRAWAL REQUEST
export const sendWithdrawRequest = (amount) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_WITHDRAW_REQ_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      `/withdrawal-req`,
      { amount },
      config
    );

    dispatch({
      type: SEND_WITHDRAW_REQ_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEND_WITHDRAW_REQ_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL WITHDRAWAL REQUEST LIST --- ADMIN
export const getAllWithdrawReqList = () => async (dispatch) => {
  try {
    dispatch({
      type: WITHDRAW_REQ_LIST_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/withdraw-req-list`, config);

    dispatch({
      type: WITHDRAW_REQ_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WITHDRAW_REQ_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET MY WITHDRAWAL REQUEST LIST
export const getMyWithdrawReqList = () => async (dispatch) => {
  try {
    dispatch({
      type: MY_WITHDRAW_REQ_LIST_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/my-withdrawal-req`, config);

    dispatch({
      type: MY_WITHDRAW_REQ_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MY_WITHDRAW_REQ_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET WITHDRAWAL REQUEST DETAILS ---- ADMIN
export const getWithdrawRequestDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: WITHDRAW_REQ_DETAILS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/withdrawal-req/${id}`, config);

    dispatch({
      type: WITHDRAW_REQ_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WITHDRAW_REQ_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// ACCEPT WITHDRAWAL REQUEST --- ADMIN
export const acceptWithdrawRequestDetails =
  (id, requestedAmount, status, tds, adminCharge, creditedAmount, txnId) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACCEPT_WITHDRAW_REQ_REQUEST,
      });

      const localStorageToken = localStorage.getItem("jwtToken")
        ? JSON.parse(localStorage.getItem("jwtToken"))
        : "";

      const config = {
        headers: {
          "content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/accept-withdrawal/${id}`,
        { requestedAmount, status, tds, adminCharge, creditedAmount, txnId },
        config
      );

      dispatch({
        type: ACCEPT_WITHDRAW_REQ_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ACCEPT_WITHDRAW_REQ_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CLEAR ERROR ACTION FUNCTION
export const clearWithdrawErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearWithdrawState = () => async (dispatch) => {
  dispatch({ type: CLEAR_WITHDRAW_STATE });
};
