import React, { Fragment } from "react";
import "../../assets/styles/teamTree.scss";
import DashLayout from "./components/DashLayout";
import NotFoundCard from "../admin/components/NotFoundCard";
import { useNavigate } from "react-router-dom";

const TeamTree = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <DashLayout>
        <div className="teamTree_wrapper " id="mainContainer">
          <NotFoundCard
            text="Right now, You can not use this service, we're working on it."
            showAction={true}
            actionTitle="Go to Dashboard"
            onClick={() => navigate("/user-dashboard")}
            actionIcon=""
          />
        </div>
      </DashLayout>
    </Fragment>
  );
};

export default TeamTree;
