import React, { Fragment } from "react";
import "../../assets/styles/activateId.scss";
import CustomButton from "../../components/CustomButton";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import { BsCurrencyRupee } from "react-icons/bs";
import CustomSelect from "../../components/CustomSelect";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotFoundCard from "../admin/components/NotFoundCard";

const ActivateDownlineId = () => {
  const navigate = useNavigate();
  // USER DETAILS START
  const { userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;
  // USER DETAILS ENDS

  return (
    <Fragment>
      <DashLayout>
        {user?.paidUser ? (
          <div className="activateId_wrapper">
            <DashCard
              className="activateId"
              title={"Activate/Upgrade Downline ID"}
            >
              <CustomInput
                label="Activation Wallet Balance"
                type="number"
                //   onChange=""
                icon={<BsCurrencyRupee />}
                placeholder="Activation Wallet Balance"
                name="walletBalance"
                value="2470"
                impStar="*"
                readOnly={true}
                borderColor="#f2f2f2"
              />
              <CustomInput
                label="Member ID"
                type="text"
                //   onChange=""
                placeholder="Fill Member ID"
                name="memberId"
                value="IBB000789"
                impStar="*"
                readOnly={false}
                borderColor="#f2f2f2"
              />
              <CustomSelect
                label="Package"
                name="packageVal"
                impStar="*"
                readOnly={false}
                borderColor="#f2f2f2"
                // onChange=""
                // value=""
                optionArray={[{ option: "Package of ₹ 999" }]}
              />

              <CustomButton
                className="purchagePack_btn"
                title="ACTIVATE"
                //   onClick=""
                disabled={false}
                tooltip="Click here to activate your ID"
              />
            </DashCard>
          </div>
        ) : (
          <NotFoundCard
            text="You have not any purchased plan yet, please purchage any plan to activate your downline ID."
            showAction={true}
            actionTitle="Purchage Plan Now"
            onClick={() => navigate("/user-dashboard/activate-my-id")}
            actionIcon=""
          />
        )}
      </DashLayout>
    </Fragment>
  );
};

export default ActivateDownlineId;
