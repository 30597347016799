import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const RedirectLoader = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #394b71;

  span {
    color: red;
    font-weight: 700;
  }

  h3 {
    color: #f2f2f2;
  }

  a {
    display: block;
    margin: 20px auto;
    border: 1px solid #f2f2f2;
    width: fit-content;
    color: #f2f2f2;
    padding: 10px 20px;
    border-radius: 100px;
    transition: 0.4s;
    background: transparent;

    &:hover {
      background: #f2f2f2;
      color: #081336;
    }
  }
`;

const RedirectSpinner = ({ path = "/login" }) => {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => --prevCount);
    }, 1000);
    count === 0 &&
      navigate(`${path}`, {
        state: location.pathname,
      });

    return () => clearInterval(interval);
  }, [count, navigate, location, path]);

  return (
    <RedirectLoader className="txt_color">
      <div className="container">
        <h3 className="monserrat_font">
          You're not authenticated Please login before use this resource.
          Redirecting you in <span>{count}</span> second.
        </h3>
        <Link to="/">Homepage</Link>
      </div>
    </RedirectLoader>
  );
};

export default RedirectSpinner;
