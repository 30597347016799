import {
  CLEAR_BARCODE_ERRORS,
  CLEAR_BARCODE_STATE,
  GET_BARCODE_FAIL,
  GET_BARCODE_REQUEST,
  GET_BARCODE_SUCCESS,
  UPDATE_BARCODE_FAIL,
  UPDATE_BARCODE_REQUEST,
  UPDATE_BARCODE_SUCCESS,
} from "../constant/barcodeConstant";

// UPDATE BARCODE REDUCER
export const updateBarcodeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BARCODE_REQUEST:
      return {
        loading: true,
        barcode: {},
      };
    case UPDATE_BARCODE_SUCCESS:
      return {
        loading: false,
        barcode: action.payload,
      };
    case UPDATE_BARCODE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_BARCODE_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_BARCODE_STATE:
      return {
        loading: false,
        barcode: {},
      };

    default:
      return state;
  }
};

// GET BARCODE REDUCER
export const getBarcodeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BARCODE_REQUEST:
      return {
        loading: true,
        barcode: {},
      };
    case GET_BARCODE_SUCCESS:
      return {
        loading: false,
        barcode: action.payload,
      };
    case GET_BARCODE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_BARCODE_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
