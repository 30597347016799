import React, { Fragment } from "react";
import "../assets/styles/contact.scss";
import Layout from "../components/Layout";
import BreadCrumb from "../components/BreadCrumb";
import { slider2 } from "../assets/images/images";
import Bottom from "../components/Bottom";
import CustomInput from "../components/CustomInput";
import { BiRename, BiMailSend } from "react-icons/bi";
import { BsFillSendFill } from "react-icons/bs";
import {
  MdOutlineSubject,
  MdOutlineMessage,
  MdPhoneIphone,
  MdEmail,
  MdLocationPin,
} from "react-icons/md";
import CustomTextarea from "../components/CustomTextarea";
import CustomButton from "../components/CustomButton";
import { Link } from "react-router-dom";
import CustomHelmet from "../components/Helmet";

const Contact = () => {
  return (
    <Fragment>
      <CustomHelmet
        title="Contact Us - Indian Big Bull"
        canonical="https://indianbigbull.in/"
      />
    <Layout>
      <BreadCrumb img={slider2} title="Contact Us" />

      <section className="contact">
        <div className="container">
          <div className="title">
            <h2>
              Contact
              <span> Us </span>
            </h2>
            <Bottom center />
            <p>
              Have a question? Need Some Help? Or just want to say hello?
              <br />
              We would love to hear from you.
            </p>
          </div>

          <div className="contact_content">
            <div className="contactDetails">
              <h3>Contact Details</h3>
              <div className="phone">
                <p className="icon">
                  <MdPhoneIphone />
                </p>
                <p>
                  <span>Phone:</span>
                  <span>
                    <Link to={`tel:${process.env.REACT_APP_PHONE}`}>{process.env.REACT_APP_PHONE}</Link>
                  </span>
                </p>
              </div>
              <div className="email">
                <p className="icon">
                  <MdEmail />
                </p>
                <p>
                  <span>Email:</span>
                  <span>
                    <Link to={`mailto:${process.env.REACT_APP_EMAIL}`}>
                      {process.env.REACT_APP_EMAIL}
                    </Link>
                  </span>
                </p>
              </div>
              <div className="address">
                <p className="icon">
                  <MdLocationPin />
                </p>
                <p>
                  <span>Address:</span>
                  <span> New Delhi, India</span>
                </p>
              </div>
            </div>
            <div className="contactForm">
              <CustomInput
                icon={<BiRename />}
                label="Name"
                type="text"
                placeholder="Name"
                name="name"
                impStar="*"
                // onChange={}
                // value=""
              />
              <CustomInput
                icon={<BiMailSend />}
                label="Email"
                type="email"
                placeholder="Email"
                name="email"
                impStar="*"
                // onChange={}
                // value=""
              />
              <CustomInput
                icon={<MdOutlineSubject />}
                label="Subject"
                type="text"
                placeholder="Subject"
                name="subject"
                impStar="*"
                // onChange={}
                // value=""
                fullWidth
              />
              <CustomTextarea
                icon={<MdOutlineMessage />}
                label="Message *"
                type="text"
                placeholder="Message"
                name="message"
                impStar="*"
                // onChange={}
                // value=""
                fullWidth
              />
              <CustomButton
                className="sendMsg_btn"
                title="Send Now"
                preIcon={<BsFillSendFill />}
                // onClick=""
                // disabled=""
                tooltip="Send a message!"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
    </Fragment>
  );
};

export default Contact;
