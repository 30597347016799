import React, { Fragment, useEffect } from "react";
import "../assets/styles/planDetails.scss";
import NotFoundCard from "./admin/components/NotFoundCard";
import Bottom from "../components/Bottom";
import BreadCrumb from "../components/BreadCrumb";
import Loader from "../components/Loader";
import Layout from "../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearErrors, getPlanDetails } from "../redux/action/planAction";
import { toast } from "react-toastify";
import { FaHome } from "react-icons/fa";
import CustomButton from "../components/CustomButton";
import CustomHelmet from "../components/Helmet";

const PlanDetails = () => {
  const { id: planId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // GET PLAN DETAILS START
  // GET PLAN DETAILS START
  const { loading, plan, error } = useSelector((state) => state.planDetails);

  const planDetails = plan?.packageDetail;


  // GET PLAN DETAILS ON PAGE LOAD
  useEffect(() => {
    if (planId) {
      dispatch(getPlanDetails(planId));
    }
  }, [planId, dispatch]);

  // CLEAR ERROR
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [error, dispatch]);
  // GET PLAN DETAILS ENDS
  // GET PLAN DETAILS ENDS

  return (
    <Fragment>
       <CustomHelmet
        title={`${planDetails?.title} - Indian Big Bull`}
        canonical="https://indianbigbull.in/"
      />
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          {plan?.success ? (
            <>
              <BreadCrumb
                img={planDetails?.thumbnail?.url}
                title={planDetails?.title + `'s Details`}
              />
              <section className="plan_page planDetailsMain">
                <div className="container">
                  <div className="titleMain">
                    <p>Pricing & Details</p>
                    <h2>{planDetails?.title}</h2>
                    <Bottom center />
                  </div>

                  <div className="planDetail_wrapper">
                    <div className="planDetails_content">
                      <div className="bg_color">
                        <div className="leftSide">
                          <img
                            src={planDetails?.thumbnail?.url}
                            alt={planDetails?.title}
                          />
                        </div>
                        <div className="rightSide">
                          <h2>{planDetails?.title}</h2>
                          <p className="price">
                            <span>Price:</span>
                            <span>₹{planDetails?.price}</span>
                          </p>
                          {planDetails?.discount > 0 && (
                            <>
                              <p className="discountpercentage">
                                We give you {planDetails?.discount}% discount,
                                if you purchase this package now.
                              </p>
                              <p className="discountPrice">
                                <span>Discounted Price:</span>
                                <span>₹{planDetails?.discountPrice}</span>
                              </p>
                            </>
                          )}

                          <p className="description">
                            <span>description:</span>{" "}
                            <span>{planDetails?.description}</span>{" "}
                          </p>

                          <CustomButton
                            className="purchasePlanBtn"
                            title="Purchase plan"
                            onClick=""
                            icon=""
                            preIcon=""
                            tooltip="Click here to purchase this plan."
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mainFeatures">
                      <div className="title">Features:</div>
                      <div className="featuresContent">
                        <ol>
                          {planDetails?.features?.map((item, idx) => (
                            <li key={idx}>{item}</li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <NotFoundCard
              txtColor="#2b74c1"
              text="Plan details not found or something went wrong!"
              showAction={true}
              actionTitle="Go to HomePage"
              onClick={() => navigate("/")}
              actionIcon={<FaHome />}
            />
          )}
        </Layout>
      )}
    </Fragment>
  );
};

export default PlanDetails;
