import {
  ACCEPT_WITHDRAW_REQ_FAIL,
  ACCEPT_WITHDRAW_REQ_REQUEST,
  ACCEPT_WITHDRAW_REQ_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_WITHDRAW_STATE,
  MY_WITHDRAW_REQ_LIST_FAIL,
  MY_WITHDRAW_REQ_LIST_REQUEST,
  MY_WITHDRAW_REQ_LIST_SUCCESS,
  SEND_WITHDRAW_REQ_FAIL,
  SEND_WITHDRAW_REQ_REQUEST,
  SEND_WITHDRAW_REQ_SUCCESS,
  WITHDRAW_REQ_DETAILS_FAIL,
  WITHDRAW_REQ_DETAILS_REQUEST,
  WITHDRAW_REQ_DETAILS_SUCCESS,
  WITHDRAW_REQ_LIST_FAIL,
  WITHDRAW_REQ_LIST_REQUEST,
  WITHDRAW_REQ_LIST_SUCCESS,
} from "../constant/withdrawalReqConstant";

// SEND WITHDRAWAL REQUEST --
export const sendWithdrawReqReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_WITHDRAW_REQ_REQUEST:
      return {
        loading: true,
        request: {},
      };
    case SEND_WITHDRAW_REQ_SUCCESS:
      return {
        loading: false,
        request: action.payload,
      };
    case SEND_WITHDRAW_REQ_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_WITHDRAW_STATE:
      return {
        error: null,
        loading: false,
        request: {},
      };

    default:
      return state;
  }
};

// GET ALL WITHDRAWAL REQUEST LIST -- ADMIN
export const getAllWithdrawReqReducer = (state = {}, action) => {
  switch (action.type) {
    case WITHDRAW_REQ_LIST_REQUEST:
      return {
        loading: true,
        request: {},
      };
    case WITHDRAW_REQ_LIST_SUCCESS:
      return {
        loading: false,
        request: action.payload,
      };
    case WITHDRAW_REQ_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET MY WITHDRAWAL REQUEST LIST
export const getMyWithdrawReqReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_WITHDRAW_REQ_LIST_REQUEST:
      return {
        loading: true,
        request: {},
      };
    case MY_WITHDRAW_REQ_LIST_SUCCESS:
      return {
        loading: false,
        request: action.payload,
      };
    case MY_WITHDRAW_REQ_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET WITHDRAWAL REQUEST DETAILS -- ADMIN
export const getWithdrawReqDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case WITHDRAW_REQ_DETAILS_REQUEST:
      return {
        loading: true,
        request: {},
      };
    case WITHDRAW_REQ_DETAILS_SUCCESS:
      return {
        loading: false,
        request: action.payload,
      };
    case WITHDRAW_REQ_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// ACCEPT WITHDRAWAL REQUEST -- ADMIN
export const acceptWithdrawReqReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCEPT_WITHDRAW_REQ_REQUEST:
      return {
        loading: true,
        request: {},
      };
    case ACCEPT_WITHDRAW_REQ_SUCCESS:
      return {
        loading: false,
        request: action.payload,
      };
    case ACCEPT_WITHDRAW_REQ_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_WITHDRAW_STATE:
      return {
        loading: false,
        error: null,
        request: {},
      };

    default:
      return state;
  }
};
