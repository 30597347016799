import React, { Fragment, useEffect } from "react";
import usePaginate from "../../assets/hooks/usePaginate";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearFundErrors,
  getAllFundsAddReqByAdmin,
} from "../../redux/action/fundAction";
import Loader from "../../components/Loader";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import { FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import moment from "moment";

const AddFundReqList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // GET ALL FUNDS ADD REQUESTS START
  // GET ALL FUNDS ADD REQUESTS START
  const { loading, funds, error } = useSelector(
    (state) => state.allFundAddRequests
  );

  const addFundReq = funds?.allFunds;

  useEffect(() => {
    dispatch(getAllFundsAddReqByAdmin());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearFundErrors());
    }

    if (funds?.success) {
      toast.success(funds?.message);
    }
  }, [error, dispatch, funds?.success, funds?.message]);

  // GET ALL FUNDS ADD REQUESTS ENDS
  // GET ALL FUNDS ADD REQUESTS ENDS

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(addFundReq);
  // Pagination Hook Ends
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="allRequests_list_wrapper">
            <div className="container">
              <PageTitle goBack={true} title="Fund Adding Requests" />

              <div className="allRequests_content">
                <div className="table_data custonScroll">
                  <table className="list_table" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Payment Snap</th>
                        <th>Request Date</th>
                        <th>Requested Amount</th>
                        <th>Transaction ID</th>
                        <th>Status</th>
                        <th>Is Verified</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.reverse()?.map((item, idx) => {
                        const requestDate = moment(item?.createdAt).format(
                          "DD-MM-YYYY [at] hh:mm A"
                        );
                        return (
                          <tr key={idx}>
                            <td>
                              <Link target="_blank" to={item?.paymentSnap?.url}>
                                <img
                                  src={item?.paymentSnap?.url}
                                  alt={item?.paymentSnap?.url}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "5px",
                                    objectFit: "cover",
                                  }}
                                />
                              </Link>
                            </td>
                            <td>
                              <p>{requestDate}</p>
                            </td>
                            <td>
                              <p>₹ {item?.amount}</p>
                            </td>
                            <td>
                              <p>{item?.transactionId}</p>
                            </td>
                            <td
                              style={{
                                background:
                                  item?.status === "Payment Success"
                                    ? "green"
                                    : "",
                                color:
                                  item?.status === "Payment Success"
                                    ? "#fff"
                                    : "#c40000",
                              }}
                            >
                              <p>
                                <b>{item?.status}</b>
                              </p>
                            </td>
                            <td
                              style={{
                                background: item?.isVerified
                                  ? "green"
                                  : "yellow",
                                color: item?.isVerified ? "#fff" : "#000",
                              }}
                            >
                              <p>
                                <b>
                                  {item?.isVerified
                                    ? "Verified"
                                    : "Verification Pending"}
                                </b>
                              </p>
                            </td>
                            <td>
                              <div>
                                {!item?.isVerified ? (
                                  <button
                                    title="See Details"
                                    onClick={() =>
                                      navigate(
                                        `/admin/fund-add-request/${item?._id}`
                                      )
                                    }
                                  >
                                    <FaEye />
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagination_sec">
                  <p className="entriesCount">
                    Showing {itemOffset + 1} to{" "}
                    {endOffset > addFundReq?.length
                      ? addFundReq?.length
                      : endOffset}{" "}
                    of {addFundReq?.length} entries
                  </p>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default AddFundReqList;
