import React from "react";
import "../assets/styles/breadcrumb.scss";
import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";

const BreadCrumb = ({ img, title }) => {
  return (
    <div className="breadcrumb_main" style={{ backgroundImage: `url(${img})` }}>
      <div className="container">
        <div className="content">
          <div className="pageTitle">{title}</div>
          <div className="location">
            <Link to="/">
              <AiFillHome /> Home
            </Link>{" "}
            > <p>{title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
