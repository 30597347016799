import React, { Fragment } from "react";
import "../../assets/styles/rewardIncome.scss";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import usePaginate from "../../assets/hooks/usePaginate";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NotFoundCard from "../admin/components/NotFoundCard";

const RewardIncome = () => {
  const navigate = useNavigate();
  // USER DETAILS START
  const { userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;
  // USER DETAILS ENDS

  const dummyTeam = [
    {
      rewardName: "Induction Cooker / 1100",
      teamCount: 25,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "JMG / 2100",
      teamCount: 50,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "R.O. / 5000",
      teamCount: 100,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "LED 40 Inch /10000",
      teamCount: 250,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "Phone / 20000",
      teamCount: 500,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "Laptop / 35000",
      teamCount: 1000,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "Bike / 1 Lakh",
      teamCount: 2500,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "Wagner Down Payment / 2 Lakhs",
      teamCount: 5000,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "Tata Tiogo Car / 5 Lakhs",
      teamCount: 10000,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "Kia Car / 10 Lakhs",
      teamCount: 25000,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "2 BHK Flat / 20 Lakhs",
      teamCount: 50000,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "Audi Q3 / 50 Lakhs",
      teamCount: 100000,
      status: false,
      dateOfAchievment: "",
    },
    {
      rewardName: "Banglow / 1 Crore",
      teamCount: 200000,
      status: false,
      dateOfAchievment: "",
    },
  ];

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick, itemOffset, endOffset } =
    usePaginate(dummyTeam);
  // Pagination Hook Ends
  return (
    <Fragment>
      <DashLayout>
        {user?.paidUser ? (
          <div className="reward_income_wrapper">
            <DashCard className="reward_income" title={"Reward Income"}>
              <CustomInput
                icon={<BsSearch />}
                type="search"
                //   onChange=""
                placeholder="Search..."
                name="searchString"
                //   value=""
                //   impStar=""
                //   readOnly=""
                borderColor="#f2f2f2"
              />
              <div className="table_data custonScroll">
                <table className="list_table" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>Reward Name</th>
                      <th>Team Count</th>
                      <th>Status</th>
                      <th>Date of Achievement</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <p>{item?.rewardName}</p>
                          </td>
                          <td>
                            <p>{item?.teamCount}</p>
                          </td>
                          <td>
                            <p className={item?.status ? "green" : "yellow"}>
                              <b>{item?.status ? "Completed" : "Pending"}</b>
                            </p>
                          </td>
                          <td>
                            <p>---</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="pagination_sec">
                <p className="entriesCount">
                  Showing {itemOffset + 1} to{" "}
                  {endOffset > dummyTeam?.length
                    ? dummyTeam?.length
                    : endOffset}{" "}
                  of {dummyTeam?.length} entries
                </p>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<RxDoubleArrowRight />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={<RxDoubleArrowLeft />}
                  renderOnZeroPageCount={null}
                />
              </div>
            </DashCard>
          </div>
        ) : (
          <NotFoundCard
            text="You have not any purchased plan yet, please purchage any plan to see your reward income."
            showAction={true}
            actionTitle="Purchage Plan Now"
            onClick={() => navigate("/user-dashboard/activate-my-id")}
            actionIcon=""
          />
        )}
      </DashLayout>
    </Fragment>
  );
};

export default RewardIncome;
