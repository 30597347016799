import React, { Fragment, useEffect } from "react";
import "../../assets/styles/walletDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getUserDetails } from "../../redux/action/userAction";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import AdminLayout from "./components/AdminLayout";
import PageTitle from "./components/PageTitle";
import { useParams } from "react-router-dom";
import {
  clearWalletErrors,
  clearWalletMessage,
  getWalletDetails,
  updateWalletDetails,
} from "../../redux/action/walletAction";
import ButtonLoader from "../../components/ButtonLoader";
import CustomButton from "../../components/CustomButton";
import { defaultPic } from "../../assets/images/images";

const WalletDetails = () => {
  const dispatch = useDispatch();

  const { id: walletId } = useParams();

  // GET WALLET DETAILS START
  // GET WALLET DETAILS START
  const { loading, wallet, error, message } = useSelector(
    (state) => state.walletDetails
  );

  const walletDetails = wallet?.wallet;

  useEffect(() => {
    if (walletId) {
      dispatch(getWalletDetails(walletId));
    }
  }, [walletId, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearWalletErrors());
    }

    if (wallet?.success && message) {
      toast.success(message);
      dispatch(clearWalletMessage());
    }
  }, [error, dispatch, wallet?.success, message]);

  // GET WALLET DETAILS ENDS
  // GET WALLET DETAILS ENDS

  // UPDATE USER WALLET START
  // UPDATE USER WALLET START
  const {
    loading: updatedWalletLoading,
    wallet: updateWallet,
    error: updatedWalletError,
    message: updatedWalletMessage,
  } = useSelector((state) => state.updateWallet);


  const updatedWallet = updateWallet?.wallet;
  console.log("updatedWallet", updatedWallet);

  const handleUpdateWallet = (isActive) => {
    if (walletId) {
      dispatch(updateWalletDetails(walletId, isActive));
    }
  };

  useEffect(() => {
    if (updatedWalletError) {
      toast.error(updatedWalletError);
      dispatch(clearWalletErrors());
    }

    if (updateWallet?.success && updatedWalletMessage) {
      toast.success(updatedWalletMessage);
      dispatch(clearWalletMessage());
      dispatch(getWalletDetails(walletId));
    }
  }, [
    updatedWalletError,
    dispatch,
    updateWallet?.success,
    updatedWalletMessage,
    walletId,
  ]);
  // UPDATE USER WALLET ENDS
  // UPDATE USER WALLET ENDS

  // GET USER DETAILS START
  // GET USER DETAILS START
  const {
    loading: userLoading,
    user,
    error: userError,
  } = useSelector((state) => state.userDetails);

  const userDetails = user?.userDetails;

  useEffect(() => {
    if (walletDetails?.forUser) {
      dispatch(getUserDetails(walletDetails?.forUser));
    }
  }, [dispatch, walletDetails?.forUser]);

  useEffect(() => {
    if (userError) {
      toast.error(userError);
      dispatch(clearErrors());
    }
  }, [userError, dispatch]);
  // GET USER DETAILS ENDS
  // GET USER DETAILS ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="wallet_details_wrapper">
            <div className="container">
              <PageTitle
                goBack={true}
                title={`${userDetails?.fullName}'s Wallet`}
              />

              <div className="walletDetails_content">
                <div className="walllet_onr_pic">
                  <img
                    src={
                      userDetails?.avatar?.url
                        ? userDetails?.avatar?.url
                        : defaultPic
                    }
                    alt={userDetails?.fullName}
                  />
                </div>
                <h2>
                  <span>Wallet Owner:</span>
                  <span>
                    {userLoading ? <ButtonLoader /> : userDetails?.fullName}
                  </span>
                </h2>
                <div className="walletDetails">
                  <p>
                    <span>Total Earnings</span>
                    <span>₹ {walletDetails?.totalEarning}</span>
                  </p>
                  <p>
                    <span>Total Withdrawal (Till Now)</span>
                    <span>₹ {walletDetails?.totalWithdrawal}</span>
                  </p>
                  <p>
                    <span>Wallet Balance</span>
                    <span>₹ {walletDetails?.walletBalance}</span>
                  </p>
                  <p>
                    <span>Withdrawable Balance</span>
                    <span>₹ {walletDetails?.withdrawBalance}</span>
                  </p>
                  <p>
                    <span>Activation Balance</span>
                    <span>₹ {walletDetails?.activationBalance}</span>
                  </p>
                </div>

                <div className="walletAction">
                  <h2>
                    Wallet Status:{" "}
                    <span
                      style={{
                        background: walletDetails?.isActive ? "green" : "red",
                        color: walletDetails?.isActive ? "#f2f2f2" : "#f2f2f2",
                        borderRadius: "5px",
                        padding: "4px 10px",
                      }}
                    >
                      {walletDetails?.isActive ? "Active" : `Inactive`}
                    </span>
                  </h2>
                  <div className="btnGrp">
                    <CustomButton
                      className={`wallet_inactive_Btn `}
                      title={
                        updatedWalletLoading ? (
                          <ButtonLoader />
                        ) : walletDetails?.isActive ? (
                          "Inactive Wallet"
                        ) : (
                          "Activate Wallet"
                        )
                      }
                      onClick={() =>
                        handleUpdateWallet(!walletDetails?.isActive)
                      }
                      disabled={updatedWalletLoading ? true : false}
                      tooltip="Activate/Inactive User Wallet."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default WalletDetails;
