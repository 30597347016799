import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BsChevronRight } from "react-icons/bs";

const DropdownMain = styled.div`
  > .dropdownHandler {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    margin: 5px 0;
    color: #394b71;
    font-size: 16px;
    font-weight: 500;
    transition: 0.4s;
    cursor: pointer;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 20px;
        margin-right: 7px;
      }

      &.iconDrop {
        > svg {
          transform: rotate(90deg);
          transition: 0.4s;
        }
      }
    }

    &:hover {
      color: #2b74c1;
    }
  }

  > .dropdownLinks {
    background: #2b74c1;
    height: 0;
    overflow: hidden;
    transition: 0.4s;
    transform: translate(-200%);

    &.openLinks {
      height: auto;
      transform: translate(0%);
    }

    > a {
      padding: 5px 0;
      margin: 5px 0;
      color: #f2f2f2;
      font-size: 16px;
      font-weight: 500;
      transition: 0.4s;
      width: fit-content;
      cursor: pointer;
      display: block;
      margin-left: 25px;

      &:hover {
        color: #aaa;
      }
    }
  }
`;

const CustomDropdown = ({ className, title, icon, links }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  return (
    <DropdownMain className={`custom_dropdown ${className}`}>
      <div
        className="dropdownHandler"
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        <span>
          {icon} {title}
        </span>
        {title && (
          <span className={openDropdown ? "iconDrop" : ""}>
            <BsChevronRight />
          </span>
        )}
      </div>

      <div className={`dropdownLinks ${openDropdown ? "openLinks" : ""}`}>
        {links?.map((item, idx) => (
          <Link to={item?.link} key={idx} state={item?.state}>
            {item?.name}
          </Link>
        ))}
      </div>
    </DropdownMain>
  );
};

export default CustomDropdown;
