import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_BARCODE_ERRORS,
  CLEAR_BARCODE_STATE,
  GET_BARCODE_FAIL,
  GET_BARCODE_REQUEST,
  GET_BARCODE_SUCCESS,
  UPDATE_BARCODE_FAIL,
  UPDATE_BARCODE_REQUEST,
  UPDATE_BARCODE_SUCCESS,
} from "../constant/barcodeConstant";

// UPDATE BARCODE REQUEST ACTION ---- ADMIN
export const updateBarcodeRequest = (barCode) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_BARCODE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      `/update-barCode`,
      { barCode },
      config
    );

    dispatch({
      type: UPDATE_BARCODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_BARCODE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GEt BARCODE REQUEST ACTION
export const getBarcodeRequest = (barCode) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BARCODE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-barCode`, config);

    dispatch({
      type: GET_BARCODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BARCODE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearBarcodeErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_BARCODE_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearBarcodeState = () => async (dispatch) => {
  dispatch({ type: CLEAR_BARCODE_STATE });
};
