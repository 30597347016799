import {
  ALL_STATS_FAIL,
  ALL_STATS_REQUEST,
  ALL_STATS_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} from "../constant/statsConstant";

// GET ALL STATS
export const allStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_STATS_REQUEST:
      return {
        loading: true,
        stats: {},
      };
    case ALL_STATS_SUCCESS:
      return {
        loading: false,
        stats: action.payload,
      };
    case ALL_STATS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_STATE:
      return {
        loading: false,
        stats: {},
      };

    default:
      return state;
  }
};
