import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/dashboard.scss";
import DashLayout from "./components/DashLayout";
import DashCard from "./components/DashCard";
import { logoMain } from "../../assets/images/images";
import { BiTime } from "react-icons/bi";
import {
  FaHandPointRight,
  FaRupeeSign,
  FaShareSquare,
  FaUsers,
  FaWhatsapp,
} from "react-icons/fa";
import { BsFillCartFill } from "react-icons/bs";
import { GiWantedReward } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import {
  clearWalletErrors,
  clearWalletMessage,
  getMyWallet,
} from "../../redux/action/walletAction";
import ButtonLoader from "../../components/ButtonLoader";
import NotFoundCard from "../admin/components/NotFoundCard";
import {
  clearCreateUpdateState,
  myPurchaseList,
} from "../../redux/action/planAction";
import { IoMdClose } from "react-icons/io";
import { clearTeamErrors, getDirectTeam } from "../../redux/action/teamAction";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sharePopup, setsharePopup] = useState(false);
  const { loading, userInfo } = useSelector((state) => state.profile);
  const user = userInfo?.user;

  if (sharePopup) {
    document.body.style.overflowY = "hidden";
  } else if (!sharePopup) {
    document.body.style.overflowY = "auto";
  }

  const registrationDate = moment(user?.createdAt).format(
    "MMMM Do YYYY, hh:mm:ss A"
  );

  // GET MY WALLET START
  // GET MY WALLET START
  const {
    loading: walletLoading,
    wallet,
    message: walletMessage,
    error,
  } = useSelector((state) => state.myWallet);

  const myWallet = wallet?.wallet;

  useEffect(() => {
    dispatch(getMyWallet());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearWalletErrors());
    }

    if (wallet?.success && walletMessage) {
      toast.success(walletMessage);
      dispatch(clearWalletMessage());
    }
  }, [error, dispatch, wallet?.success, walletMessage]);

  // GET MY WALLET ENDS
  // GET MY WALLET ENDS

  // GET MY PURCHASED LIST START
  // GET MY PURCHASED LIST START
  const {
    loading: purchasedPlanLoading,
    plan: purchasedPlan,
    error: purchasedPlanError,
  } = useSelector((state) => state.myPurchasedPlan);

  const myPackage = purchasedPlan?.myPackage;

  const purchaseDate = moment(myPackage?.createdAt).format(
    "DD/MM/YYYY, hh:mm A"
  );

  useEffect(() => {
    if (user?.paidUser) {
      dispatch(myPurchaseList()); // GET PURCHASED PLAN
    }
  }, [user?.paidUser, dispatch]);

  useEffect(() => {
    if (purchasedPlanError) {
      toast.error(purchasedPlanError);
      dispatch(clearCreateUpdateState());
    }
  }, [purchasedPlanError, dispatch]);
  // GET MY PURCHASED LIST ENDS
  // GET MY PURCHASED LIST ENDS

  // LINK COPY TO CLIPBOARD START
  const handleLinkCopy = (link) => {
    if (link) {
      const copiedLink = navigator.clipboard.writeText(link);
      if (copiedLink !== "") {
        toast.success("Link Copied!");
      } else {
        toast.error("Something Went Wrong!");
      }
    } else {
      toast.error("Link not found!");
    }
  };
  // LINK COPY TO CLIPBOARD ENDS

  // LINK SHARE ON WHATSAPP START
  const shareOnWhatsapp = () => {
    const shareTextEng =
      "Hey! I came across this INDIAN BIG BULL excellent earning offer today and instantly thought of sharing it with you. If you want to get a new earning source through this special link behalf of INDIAN BIG BULL, So register now through the link given below. Do not Miss This Offer.";
    const shareUrl = `${process.env.REACT_APP_URL}/register?reffCode=${user?.username}`;
    const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(
      shareTextEng
    )}%20${encodeURIComponent(shareUrl)}`;
    window.location.href = whatsappUrl;
  };
  // LINK SHARE ON WHATSAPP ENDS

  // DIRECT TEAM AND INCOME START
  // DIRECT TEAM AND INCOME START
  const {
    loading: teamLoading,
    team,
    error: teamError,
  } = useSelector((state) => state.directTeam);

  const directTeam = team?.team;
  const myDirectIncome = team?.totalDirectIncome;

  let totalActiveDirects = 0;
  if (directTeam?.length) {
    for (let i = 0; i < directTeam?.length; i++) {
      totalActiveDirects += directTeam[i]?.forUser?.paidUser ? 1 : 0;
    }
  }

  // GET  DIRECT TEAM ON PAGE LOAD
  useEffect(() => {
    if (user?.paidUser) {
      dispatch(getDirectTeam());
    }
  }, [dispatch, user?.paidUser]);

  useEffect(() => {
    if (teamError) {
      toast.error(teamError);
      dispatch(clearTeamErrors());
    }
  }, [teamError, dispatch, team?.success, team?.message]);
  // DIRECT TEAM AND INCOME ENDS
  // DIRECT TEAM AND INCOME ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <DashLayout>
          <div className="profile_wallet_main">
            <div className="col">
              <DashCard className="walletCard" title="My Wallet">
                {/* {walletLoading ? <ButtonLoader /> : ""} */}
                <div className="circleContent">
                  <div className="withdrawable">
                    <div className="circle">
                      <p>
                        {walletLoading ? (
                          <ButtonLoader />
                        ) : (
                          `₹ ${myWallet?.withdrawBalance}`
                        )}
                      </p>
                    </div>
                    <p>Withdrawable</p>
                  </div>
                  <div className="totalWithdrawal">
                    <div className="circle">
                      <p>
                        {walletLoading ? (
                          <ButtonLoader />
                        ) : (
                          `₹ ${myWallet?.totalWithdrawal}`
                        )}
                      </p>
                    </div>
                    <p>Total Withdraw till Now</p>
                  </div>
                  <div className="activation">
                    <div className="circle">
                      <p>
                        {walletLoading ? (
                          <ButtonLoader />
                        ) : (
                          `₹ ${myWallet?.walletBalance}`
                        )}
                      </p>
                    </div>
                    <p>Wallet Balance</p>
                  </div>
                  <div className="totalEarning">
                    <div className="circle">
                      <p>
                        {walletLoading ? (
                          <ButtonLoader />
                        ) : (
                          `₹ ${myWallet?.totalEarning}`
                        )}
                      </p>
                    </div>
                    <p>Total Earning</p>
                  </div>
                </div>
                <CustomButton
                  className="addFundBtn"
                  title="Recharge Wallet"
                  onClick={() => navigate("/user-dashboard/add-fund")}
                  preIcon={<FaRupeeSign />}
                  tooltip="Click here to add fund in your wallet."
                />
              </DashCard>
            </div>
            <div className="col">
              <DashCard
                className="profileCard"
                title="My Profile"
                subTitleLink="Share"
                subTitleLinkClick={() => setsharePopup(true)}
                subTitleLinkIcon={<FaShareSquare />}
              >
                <div className="profileContent">
                  <div className="logoSec">
                    <img src={logoMain} width={150} alt="logoMain" />
                  </div>
                  <div className="profileData">
                    <h3>{user?.fullName}</h3>
                    <p>
                      <span>ID:</span> <span>{user?.username}</span>
                    </p>
                    {user?.sponsor?.userName && (
                      <p>
                        <span>Referral:</span>{" "}
                        <span>
                          {" "}
                          {user?.sponsor?.fullName} ({user?.sponsor?.userName})
                        </span>
                      </p>
                    )}

                    <p>
                      <span>Date of Registration:</span>{" "}
                      <span>{registrationDate}</span>
                    </p>

                    <p>
                      <span>Package: </span>
                      {user?.paidUser ? (
                        <span>
                          {purchasedPlanLoading
                            ? "Loading..."
                            : `Package of ₹ ${myPackage?.buyAmount}`}
                        </span>
                      ) : (
                        <span style={{ color: "red" }}>Not purchaged yet</span>
                      )}
                    </p>
                  </div>
                </div>
              </DashCard>
            </div>
          </div>
          <div className="cardGrid main_dash_data">
            <div className="col col_grp">
              <DashCard className="earningCard" title="My Earning">
                <div className="earningContent">
                  <p>
                    <span>Direct Sponsor Income </span>
                    <span>
                      {teamLoading
                        ? "Loading..."
                        : myDirectIncome
                        ? `₹ ${myDirectIncome}`
                        : "₹ 0.00"}
                    </span>
                  </p>
                  {/* <p>
                    <span>Level Income </span>
                    <span>₹ 1350.00</span>
                  </p> */}
                  <p>
                    <span>Company Turnover Income </span>
                    <span>₹ 0.00</span>
                  </p>
                </div>
              </DashCard>
              {myPackage && (
                <DashCard className="purchaseCard" title="My Purchase History">
                  <div className="purchaseContent">
                    <div className="data">
                      <p>
                        <span>#{myPackage?.packageId.title}</span>
                        <span>
                          <BiTime /> {purchaseDate}
                        </span>
                      </p>
                      <p>₹ {myPackage?.buyAmount}</p>
                    </div>
                  </div>
                </DashCard>
              )}
            </div>
            <div className="col">
              <DashCard className="businessCard" title="My Business Detail">
                <div className="businessContent">
                  <p>
                    <span>
                      <BsFillCartFill /> My Package{" "}
                    </span>
                    {user?.paidUser ? (
                      <span>
                        {purchasedPlanLoading ? (
                          <ButtonLoader />
                        ) : (
                          `Package of ₹ ${myPackage?.buyAmount}`
                        )}
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>Not purchaged yet!</span>
                    )}
                  </p>
                  <p>
                    <span>
                      <FaUsers /> Total Directs{" "}
                    </span>
                    <span>
                      {teamLoading ? "Loading..." : directTeam?.length}
                    </span>
                  </p>
                  <p>
                    <span>
                      <FaUsers /> Active Directs{" "}
                    </span>
                    <span>
                      {teamLoading ? "Loading..." : totalActiveDirects}
                    </span>
                  </p>
                  {/* <p>
                    <span>
                      <BsFillPersonVcardFill /> Active Team{" "}
                    </span>
                    <span>71</span>
                  </p> 
                   <p>
                    <span>
                      <BiSolidNetworkChart /> Power Leg Business{" "}
                    </span>
                    <span>63.00</span>
                  </p>
                  <p>
                    <span>
                      <BiNetworkChart /> Weaker Leg Business{" "}
                    </span>
                    <span>8.00</span>
                  </p> */}
                  <p>
                    <span>
                      <GiWantedReward /> Reward Achievement{" "}
                    </span>
                    <span>N/A</span>
                  </p>
                </div>
              </DashCard>
            </div>
          </div>

          <div className="latestNews">
            <DashCard className="latestNews" title="Latest News">
              <div className="newsContent">
                {[].map((item, idx) => (
                  <p key={idx}>
                    <span>
                      <FaHandPointRight />
                    </span>
                    <span>
                      Latest news Latest news Latest news Latest news Latest
                      news Latest news Latest news{" "}
                    </span>
                  </p>
                ))}

                <NotFoundCard text="There is no notification/message for you!" />
              </div>
            </DashCard>
          </div>

          {/* SHARE POPUP START */}
          {sharePopup && (
            <div className="profileSharePopup_main">
              <div className="shareCard">
                <h2>
                  Share your refferal link, for more earnings...{" "}
                  <span
                    className="closeBtn"
                    onClick={() => setsharePopup(false)}
                  >
                    <IoMdClose />
                  </span>
                </h2>
                <div className="reffLink">
                  <span
                    onClick={() =>
                      handleLinkCopy(
                        `${process.env.REACT_APP_URL}/register?reffCode=${user?.username}`
                      )
                    }
                  >
                    {process.env.REACT_APP_URL}/register?reffCode=
                    {user?.username}
                  </span>
                  <span
                    className="copyBtn"
                    onClick={() =>
                      handleLinkCopy(
                        `${process.env.REACT_APP_URL}/register?reffCode=${user?.username}`
                      )
                    }
                  >
                    Copy Link
                  </span>
                </div>
                <button className="shareWaBtn" onClick={shareOnWhatsapp}>
                  <span className="icon">
                    <FaWhatsapp />
                  </span>{" "}
                  <span>Share On WhatsApp</span>
                </button>
              </div>
            </div>
          )}
          {/* SHARE POPUP ENDS */}
        </DashLayout>
      )}
    </Fragment>
  );
};

export default Dashboard;
