import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import SubHeader from "./SubHeader";
import WhatsappFloating from "./WhatsappFloating";

const Layout = ({ children }) => {
  return (
    <div className="layout_main">
      <SubHeader />
      <WhatsappFloating /> {/* WHATSAPP FLOATING ICON */}
      <Header />
      <div className="childComponent" style={{ minHeight: "70vh" }}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
