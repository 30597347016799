import { axiosInstance } from "../../utils/axiosConfig";
import {
  ADD_FUND_FAIL,
  ADD_FUND_REQUEST,
  ADD_FUND_SUCCESS,
  CLEAR_FUND_ERRORS,
  CLEAR_FUND_STATE,
  DEPOSIT_HISTORY_FAIL,
  DEPOSIT_HISTORY_REQUEST,
  DEPOSIT_HISTORY_SUCCESS,
  FUND_ADD_DETAIL_FAIL,
  FUND_ADD_DETAIL_REQUEST,
  FUND_ADD_DETAIL_SUCCESS,
  FUND_ADD_LIST_FAIL,
  FUND_ADD_LIST_REQUEST,
  FUND_ADD_LIST_SUCCESS,
  UPDATE_FUND_ADD_FAIL,
  UPDATE_FUND_ADD_REQUEST,
  UPDATE_FUND_ADD_SUCCESS,
} from "../constant/fundConstant";

// ADD FUND REQUEST ACTION
export const addFundRequest =
  (amount, transactionId, remarks, paymentSnap) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_FUND_REQUEST,
      });

      const localStorageToken = localStorage.getItem("jwtToken")
        ? JSON.parse(localStorage.getItem("jwtToken"))
        : "";

      const config = {
        headers: {
          "content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/add-fund`,
        { amount, transactionId, remarks, paymentSnap },
        config
      );

      dispatch({
        type: ADD_FUND_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_FUND_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET DEPOSIT HISTORY ACTION
export const getDepositHistory = () => async (dispatch) => {
  try {
    dispatch({
      type: DEPOSIT_HISTORY_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-funds`, config);

    dispatch({
      type: DEPOSIT_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DEPOSIT_HISTORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL FUNDS ADD REQUESTES REDUCER - BY ADMIN
export const getAllFundsAddReqByAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: FUND_ADD_LIST_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-all-funds`, config);

    dispatch({
      type: FUND_ADD_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FUND_ADD_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET FUND DETAILS REQUESTES REDUCER - BY ADMIN
export const getFundsAddReqDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FUND_ADD_DETAIL_REQUEST,
    });

    const localStorageToken = localStorage.getItem("jwtToken")
      ? JSON.parse(localStorage.getItem("jwtToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/fund-details/${id}`, config);

    dispatch({
      type: FUND_ADD_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FUND_ADD_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET FUND DETAILS REQUESTES REDUCER - BY ADMIN
export const updateFundsAddReq =
  (id, isActive, isVerified, amount, status) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_FUND_ADD_REQUEST,
      });

      const localStorageToken = localStorage.getItem("jwtToken")
        ? JSON.parse(localStorage.getItem("jwtToken"))
        : "";

      const config = {
        headers: {
          "content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/update-fund/${id}`,
        { isActive, isVerified, amount, status },
        config
      );

      dispatch({
        type: UPDATE_FUND_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_FUND_ADD_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CLEAR ERROR ACTION FUNCTION
export const clearFundErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_FUND_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearFundState = () => async (dispatch) => {
  dispatch({ type: CLEAR_FUND_STATE });
};
