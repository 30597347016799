import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/updateBarcode.scss";
import CustomButton from "../../components/CustomButton";
import PageTitle from "./components/PageTitle";
import AdminLayout from "./components/AdminLayout";
import Loader from "../../components/Loader";
import NotFoundCard from "./components/NotFoundCard";
import CustomInput from "../../components/CustomInput";
import { BiBarcodeReader } from "react-icons/bi";
import {
  clearBarcodeErrors,
  clearBarcodeState,
  getBarcodeRequest,
  updateBarcodeRequest,
} from "../../redux/action/barcodeAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonLoader from "../../components/ButtonLoader";

const UpdateBarcode = () => {
  const dispatch = useDispatch();
  const [barcodeForm, setBarcodeForm] = useState(false);
  const [barCode, setBarCode] = useState("");
  const [barcodePreview, setBarcodePreview] = useState("");

  const handleInputChange = (e) => {
    if (e.target.name === "barCode") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setBarcodePreview(reader.result);
          setBarCode(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // UPDATE BARCODE START
  // UPDATE BARCODE START
  const {
    loading: barCodeLoading,
    barcode,
    error,
  } = useSelector((state) => state.updateBarcode);

  const handleUpdateBarcode = () => {
    if (barCode) {
      dispatch(updateBarcodeRequest(barCode));
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearBarcodeErrors());
    }

    if (barcode?.success) {
      toast.success(barcode?.message);
      dispatch(clearBarcodeState());
      dispatch(getBarcodeRequest());
      setBarcodeForm(false);
      setBarcodePreview("");
    }
  }, [error, dispatch, barcode?.success, barcode?.message]);

  // UPDATE BARCODE ENDS
  // UPDATE BARCODE ENDS

  // GET BARCODE START
  // GET BARCODE START
  const {
    loading,
    barcode: myBarcode,
    error: myBarcodeError,
  } = useSelector((state) => state.getBarcode);

  console.log("myBarcode", myBarcode?.barcode);
  const barCodeImg = myBarcode?.barcode


  useEffect(() => {
    dispatch(getBarcodeRequest());
  }, [dispatch]);

  useEffect(() => {
    if (myBarcodeError) {
      toast.error(myBarcodeError);
      dispatch(clearBarcodeErrors());
    }
  }, [myBarcodeError, dispatch, barcode?.success, barcode?.message]);

  // GEt BARCODE ENDS
  // GEt BARCODE ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AdminLayout>
          <div className="allPlans_wrapper updateBarcode_Main">
            <div className="container">
              <div className="buttonFunction">
                <PageTitle goBack={true} title={`Payment Barcode`} />
                <CustomButton
                  title="Update Barcode"
                  onClick={() => setBarcodeForm(!barcodeForm)}
                  preIcon={<BiBarcodeReader />}
                  tooltip="Update Barcode"
                />
              </div>

              <div className="barcode_content">
                {barcodeForm ? (
                  <div className="createBarcode">
                    <CustomInput
                      icon={<BiBarcodeReader />}
                      label="Select Barcode"
                      type="file"
                      onChange={handleInputChange}
                      placeholder="Select a barcode for payment"
                      name="barCode"
                      impStar="*"
                      borderColor="#f2f2f2"
                      accept="image/*"
                    />
                    <CustomButton
                      className="updateBarcodeBtn"
                      title={barCodeLoading ? <ButtonLoader /> : "Update"}
                      onClick={handleUpdateBarcode}
                      disabled={barCodeLoading ? true : false}
                      tooltip="Click here to Update Barcode"
                    />

                    {barcodePreview && (
                      <img
                        src={barcodePreview}
                        alt={barcodePreview}
                        className="previewImg"
                      />
                    )}
                  </div>
                ) : (
                  <NotFoundCard
                    text="If you want to update payment barcode click the below button!"
                    showAction={true}
                    actionTitle="Update Barcode"
                    onClick={() => setBarcodeForm(true)}
                    actionIcon={<BiBarcodeReader />}
                  />
                )}

                <img src={barCodeImg?.barCode?.url} alt="barcode" className="available_barcode" />
              </div>

              {/* <NotFoundCard
                  text="Plan list not found!"
                  showAction={true}
                  actionTitle="Create New Plan"
                  onClick={() => navigate("/admin/create-plan")}
                  actionIcon={<PiPlantBold />}
                /> */}
            </div>
          </div>
        </AdminLayout>
      )}
    </Fragment>
  );
};

export default UpdateBarcode;
